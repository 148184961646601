import React from "react";
import { image_url } from "../../store";

export const getImageUrl = (src, width) => {
  return image_url + "/" + width + "_" + src;
};

export const Image = (props) => {
  const { src, width, alt, fixedWidth, ...rest } = props;
  return (
    <img src={image_url + "/" + width + "_" + src} width={fixedWidth ? fixedWidth : "100%"} alt={alt !== undefined ? alt : "generated image"} {...rest} />
  );
};

export const ImageUrl = (props) => {
  return getImageUrl(props.src, props.width);
};

export default ImageUrl;
