import React from 'react';
import PropTypes from 'prop-types';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { withStyles, List, ListItem, ListItemText, Collapse } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '100%'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  category: {
    backgroundColor: theme.palette.secondary.light
  }
});

class ArticlesListLeft extends React.Component {
  state = {
    open: [true]
  };

  handleClick = (category, i) => {
    if (category.sub.length > 0) { // click was on a parent category, so toggle
      //console.log("i", i);
      //console.log("handleClick", category);
      this.setState(state => {
        const open = new Array(i);
        open[i] = !this.state.open[i];
        return {
          open
        };
      });
    } else {
      // click was on a subcategory, so switch
      //console.log("handle_category", category);
      this.props.handle_category(category);
    }
  };

  addItem(category, selected, i) {
    return <React.Fragment>
      <ListItem button key={i} selected={category.id === selected.id}
                onClick={this.handleClick.bind(this, category, i)}>
        <ListItemText primary={category.name}/>
        {category.sub === undefined ? "" : this.state.open[i] ? <ExpandLess/> : <ExpandMore/>}
      </ListItem>
      {category.sub !== undefined &&
      <Collapse in={this.state.open[i]} timeout="auto" unmountOnExit>
        <List
          className={this.props.classes.root}
        >
          {category.sub.map((category, i) =>
            this.addItem(category, selected, i)
          )}
        </List>
      </Collapse>}
    </React.Fragment>
  }

  countArticlesForCategory(category) {
    return this.props.articles.filter(a => a.category.id === category.id).length;
  }

  render() {
    const { classes, categories, selectedCategory, handle_category } = this.props;
    //console.log("ArticlesListLeft props", this.props);

    return <List className={classes.root}>
      {categories.map((category, i) =>
        <div key={category.id}>
          {(category.sub.length > 0 || this.countArticlesForCategory(category) > 0) &&
            <ListItem
              button
              key={i}
              selected={selectedCategory && category.id === selectedCategory}
              onClick={this.handleClick.bind(this, category, i)}
              className={category.sub.length > 0 ? classes.category : classes.selected}>
                <ListItemText primary={category.name} />
                {category.sub.length === 0 ? "" : this.state.open[i] ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
          }
          {category.sub.length > 0 &&
          <Collapse in={this.state.open[i]} timeout="auto" unmountOnExit>
            <List
              className={classes.root}
            >
              {category.sub.map((subcategory, i) =>
                this.countArticlesForCategory(subcategory) > 0 &&
                <ListItem button key={i} selected={selectedCategory && subcategory.id === selectedCategory}
                          onClick={handle_category.bind(this, subcategory)}>
                  <ListItemText primary={subcategory.name}/>
                </ListItem>
              )}
            </List>
          </Collapse>}
        </div>
      )}
    </List>;
  }
}

ArticlesListLeft.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.array,
  handle_category: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string,
  articles: PropTypes.array
};

export default withStyles(styles)(ArticlesListLeft);