import {
  FETCH_ARTICLES,
  FETCH_CATEGORIES,
  SELECT_ARTICLE,
} from "./types";

import { database_request_url } from "../store";

export const fetchArticlesByModelAndTopCat = (modelId, topCatId) => dispatch => {
  let url = database_request_url + "/articles?mod=" + modelId + "&cat=" + topCatId;
  fetch(url)
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: FETCH_ARTICLES,
        topcat: topCatId,
        payload: data
      });
    })
    .catch(err => {
      console.error("caught it!", err);
    });
};

export const fetchArticlesByCategory = catId => dispatch => {
  fetch(database_request_url + "/articles?cat=" + catId)
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: FETCH_ARTICLES,
        topcat: catId,
        payload: data
      });
    })
    .catch(err => {
      console.error("caught it!", err);
    });
};

export const selectArticle = (article, topcat) => dispatch => {
  dispatch({
    type: SELECT_ARTICLE,
    article: article,
    topcat: topcat
  });
};

export const selectFetchArticle = article => dispatch => {
  fetch(database_request_url + "/article/" + article)
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: SELECT_ARTICLE,
        article: data[0]
      });
    });
};

/**
 * Tested on spring
 * @returns {function(*): void}
 */
export const fetchCategories = () => dispatch => {
  fetch(database_request_url + "/categories")
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: FETCH_CATEGORIES,
        payload: data
      });
    });
};