import React from 'react';
import PropTypes from 'prop-types';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArticleListItem from "./Items/ArticleListItem";
import _ from "lodash";

import Spinner from "../Configurator/Spinner";

import { withStyles, List, ListItem, ListItemText, Collapse } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: "100%",
    //maxWidth: 360,
 //   backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
});

class ArticlesList extends React.Component {
  state = {
    opencat: [true],
    opensubcat: [true],
    filtered: undefined
  };

  handleCategoryClick = i => {
    //console.log("handleCategoryClick", i);
    this.setState(() => {
      const opencat = new Array(i);
      opencat[i] = !this.state.opencat[i];
      return { opencat };
    });
  };

  handleSubCategoryClick = i => {
    //console.log("handleSubCategoryClick", i);
    this.setState(() => {
      const opensubcat = new Array(i);
      opensubcat[i] = !this.state.opensubcat[i];
      return { opensubcat };
    });
  };

  filter() {
    const { articles, categories } = this.props;
    if (articles !== undefined && !_.isEmpty(articles) && categories !== undefined && !_.isEmpty(categories)) {
      //console.log("filter articles by category", this.props);
      var cat_accs = [];
      categories.map(cat => cat_accs[cat.id] = articles.filter(article => article.category.id === cat.id));
      this.setState({ filtered: cat_accs });
    }
  }

  componentDidMount() {
    this.filter();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // new update - modified input data on ArticleList
    if (this.props.articles !== prevProps.articles || this.props.categories !== prevProps.categories) {
      this.filter();
    }
  }

  render() {
    const { classes, categories, articles, subheader, topcat } = this.props;
    categories.sort(function(a, b) {
      return a.sorting - b.sorting;
    });
    console.log("ArticleList", this.props); // contains the categories closest to the products (cat 3 on clothing, cat 2 on accessories) - so we have to retrieve cat 2 when cat 3 is given.
    console.log("Filtered:", this.state.filtered);
    console.log("open:", this.state);

    if (_.isEmpty(categories) ||_.isEmpty(articles) || this.state.filtered === undefined)
      return <Spinner />;

      if(this.props.sublevel === 2) {
        const parents = [...new Set(categories.map(item => item.parent))]; // has at least one undefined element
        console.log("parents", parents);

        const parentids = [...new Set(categories.map(item => item.subcategory_id))];
        //console.log("parentids", parentids);
        var filtercats = [];
        parentids.map((p, i) => filtercats[i] = categories.filter(category => category.subcategory_id === parentids[i]));
        console.log("filtercats", filtercats);
        return (
          <div>
            {parents.map((category_header, i) => <div key={"par-" + i}>
                {category_header && <ListItem button onClick={this.handleCategoryClick.bind(this, i)}>
                  <ListItemText primary={category_header} />
                  {this.state.opencat[i] ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                }
              { // show sub categories only if visible.
                this.state.opencat[i] && <Collapse in={this.state.opencat[i]} timeout="auto">
                  <List className={classes.root}>
                    {filtercats[i].map((category, j) => {
                      var arts = this.state.filtered[category.id];
                      // or filter directly here: arts = this.props.articles.filter(a => a.category_id === category.id);
                      return (arts.length > 0) &&
                        <ArticleListItem
                        key={category.id}
                        open={this.state.opensubcat[j]}
                        category={category}
                        topcat={topcat}
                        articles={arts}
                        //subheader={subheader}
                        click={this.handleSubCategoryClick}
                        index={j}
                        history={this.props.history}
                      />
                      }
                    )}
                  </List>
                </Collapse>
              }
              </div>
            )}
          </div>
        );
      } else {
        return <List
          component="nav"
          //subheader={}
          className={classes.root}
        >
          {categories.map((category, j) => (this.state.filtered[category.id].length > 0) &&
            <ArticleListItem
              key={category.id}
              open={this.state.opensubcat[j]}
              category={category}
              topcat={topcat}
              articles={this.state.filtered[category.id]}
              click={this.handleSubCategoryClick}
              subheader={subheader}
              index={j}
              history={this.props.history}/>
          )}
        </List>;
      }

  }
}

ArticlesList.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.array,
  articles: PropTypes.array,
  topcat: PropTypes.object,
  subheader: PropTypes.array,
  sublevel: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
};

export default withStyles(styles)(ArticlesList);