import React from "react";
import { Tooltip } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { database_request_url } from "store.js";
import Button from "@material-ui/core/Button";

class PDFButton extends React.Component {
  render() {
    const { navLink, socialIcons, tooltip } = this.props.classes;
    const { offer } = this.props;
    if(offer !== undefined)
      return <Tooltip
        id="download"
        title={<Translate id="tooltips.download"/>}
        placement={window.innerWidth > 959 ? "top" : "left"}
        classes={{ tooltip: tooltip }}
      >
        {<Button
          href={database_request_url + "/offerpdf/" + offer.id}
          target="_blank"
          className={navLink}
        >
          <i className={socialIcons + " fas fa-download"} />
        </Button>}
      </Tooltip>;
    else
      return "";
  }
}

PDFButton.propTypes = {
  classes: PropTypes.object.isRequired,
  offer: PropTypes.object
};

const mapStateToProps = state => ({
  offer: state.config.offer
});

export default connect(mapStateToProps)(PDFButton);
