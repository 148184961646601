import PropTypes from "prop-types"
import _ from "lodash";

export const SizeList = props => {
  const { sizes } = props;

  if(sizes === undefined || _.isEmpty(sizes) || sizes.length === 0)
    return "";

  const l = sizes.length;
  return (l > 1) ? sizes[0].size + "-" + sizes[l - 1].size : sizes[0].size;
};

SizeList.propTypes = {
  sizes: PropTypes.array.isRequired
};