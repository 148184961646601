import React from "react";
import componentsStyle from "assets/jss/material-kit-react/views/components";
import { withStyles } from '@material-ui/core';

class NotFound extends React.Component {
  render() {
    return <div>NOT FOUND</div>;
  }
}

export default withStyles(componentsStyle)(NotFound);
