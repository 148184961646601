import React from "react";
import PropTypes from "prop-types";
import ArticleSummaryDetails from "./ArticleSummaryDetails";
import { TableCell, TableRow, Button as ReactButton, Box } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import Button from "../CustomButtons/Button";
import AddIcon from "@material-ui/icons/Add";

class ArticleSummaryFinance extends React.Component {
  render() {
    const { cart, finance, classes, topCategories, summary } = this.props;
    return (
      <React.Fragment>
        {topCategories.map(category => {
          let items = finance.items.filter(item => item.top_cat === category.id)
          return (items.length > 0) ? <ArticleSummaryDetails
            key={category.id}
            category={category}
            items={items}
            cart={cart}
            finance={finance}
            summary={summary.itemscash[category.id]}
            summaryFinance={summary.itemsfinanced[category.id]}
            classes={classes}
            getMode={this.props.getMode}
            handleSwitchMode={this.props.handleSwitchMode}
            cash={this.props.cash}
            history={this.props.history}
            /> :
              <TableRow key={category.id}>
                <TableCell className={classes.withBorder} colSpan={2}>
                  <Box display="flex" justifyContent="flex-end" flexDirection="row">
                    <Box>
                      <Button aria-label={<Translate id={"buttons.shopmore"} />} onClick={() => this.props.history.push("/articles/" + category.id)} size={"sm"} round justIcon color="secondary">
                        <AddIcon />
                      </Button>
                    </Box>
                    <Box display="flex" justifyContent="flex-start" alignItems="center" flexGrow={1} className={classes.articleDescription}>{category.name}</Box>
                    <Box>
                      <ReactButton variant="outlined" color="inherit" size="small" onClick={() => this.props.history.push("/articles/" + category.id)}><Translate id={"buttons.shopmore"} /></ReactButton>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
        })}
      </React.Fragment>
      );
  }
}

ArticleSummaryFinance.propTypes = {
  classes: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,
  finance: PropTypes.object.isRequired,
  summary: PropTypes.object.isRequired,
  topCategories: PropTypes.array.isRequired,
  cash: PropTypes.bool,
  history: PropTypes.object.isRequired,

  handleSwitchMode: PropTypes.func.isRequired,
  getMode: PropTypes.func.isRequired
};

export default ArticleSummaryFinance;
