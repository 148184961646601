import React from "react";
import PropTypes from "prop-types";
import red from "@material-ui/core/colors/red";
import CartDelIcon from "@material-ui/icons/RemoveShoppingCart";
import { connect } from "react-redux";
import { addToCart, removeFromCart } from "../../actions/configAction";
import Spinner from "../Configurator/Spinner";

import { ccyFormat } from "../../Utils";
import SizeSelect from "../Articles/SizeSelect";
import { getImageUrl } from "../Image/Image";
import { withStyles, IconButton, TableCell, TableRow, Grid } from "@material-ui/core";
import ImageGallery from "react-image-gallery";

const styles = theme => ({
  card: {
    maxWidth: 400,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  root: {
    display: 'flex', // ???
    flexWrap: 'wrap',
    color: theme.palette.primary.featuregray,
    "&$checked": {
      color: theme.palette.primary.main,
    }
  },
  formControl: {
    margin: theme.spacing(0.5),
    minWidth: 105,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }, // transform: translate(13px, 15px) scale(0.75);
  checked: {},
  disabled: {
    color:theme.palette.secondary.main
  },
  price: theme.price
});

class ArticleCart extends React.Component {
  state = {
    expanded: false,
    labelWidth: 0,
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  handleAddToCart(article) {
    this.props.addToCart(article);
  }

  handleRemoveFromCart(article) {
    this.props.removeFromCart(article);
  }

  render() {
    const { article, item, final, cash, classes } = this.props;
    //console.log("ArticleCart props", this.props);
    //console.log("ArticleCart article", article);

    if(article === undefined || item === undefined)
      return <Spinner/>;
    else
    return (
      <TableRow>
        <TableCell>
          <Grid container spacing={2}>
            <Grid item xs={1} sm={false} zeroMinWidth></Grid>
            <Grid item xs={false} md={1} zeroMinWidth></Grid>
            <Grid item xs={8} sm={4} md={3}  className={classes.productImage}>
              {article.images[0] &&
              <ImageGallery
                showFullscreenButton={false}
                showPlayButton={false}
                startIndex={0}
                autoPlay={false}
                showBullets={false}
                showNav={false}
                items={article.images.map(image => {
                  return { original: getImageUrl(image.filename, 240),
                           thumbnail: getImageUrl(image.filename, 160)
                         }
                  })}
                showThumbnails={false}
                />
              }
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <h5>{article.description}</h5>
              {!final && <IconButton aria-label="Remove" onClick={this.handleRemoveFromCart.bind(this, article)} ><CartDelIcon /></IconButton>}
              <span className="articlenumber">{article.sku}<br/>
                {!cash && ccyFormat(article.priceincl)} {article.labor > 0 && " zzgl. Montagekosten"}</span>
              {!final && article.sizes.length > 0 && <SizeSelect article={article} />}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={classes.price}>{ccyFormat(cash ? article.priceincl : item.rate)}</TableCell>
      </TableRow>
    );
  }
}

ArticleCart.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object,
  cart: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  final: PropTypes.bool,
  cash: PropTypes.bool,

  addToCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  cart: state.config.cart
});

export default withStyles(styles)(connect( mapStateToProps, { addToCart, removeFromCart })(ArticleCart));
