import React from "react";
import {
  withStyles,
  DialogTitle as MuiDialogTitle,
  Typography,
  IconButton,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const styles = theme => ({
  buttonAccept: {
    backgroundColor: theme.palette.porsche.main,
    color: theme.palette.porsche.contrastText,
    "&:hover": {
      color: theme.palette.porsche.main,
    },
    "&:disabled": {
      backgroundColor: theme.palette.secondary.light
    }
  },
  buttonPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.primary.main,
    }
  },
  iddSub: {
    paddingLeft: "50px"
  }
});

export const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.porsche.main
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white"
  },
  title: {
    color: "white"
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
