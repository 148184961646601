import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchCartFinance } from "../../actions/configAction";
import { getSummaryCart, getSummaryFinance } from "../../Utils";
import Spinner from "components/Configurator/Spinner";
import { defaultBoxShadow } from "assets/jss/material-kit-react";
import { Translate } from "react-localize-redux";
import PersonalData from "components/Configurator/PersonalData";
import DealerCard from "components/Dealer/DealerCard";
import Confirmation from "components/Configurator/Confirmation";
import Button from "components/CustomButtons/Button";
import { withStyles, Grid } from "@material-ui/core";
import {
  FacebookShareButton,
  TwitterShareButton
} from 'react-share';
import { base_url, database_request_url } from "../../store";
import CartDetail from "../../components/Cart/CartDetail";
import FinanceInsuranceCardFinal from "../../components/Finance/FinanceInsuranceCardFinal";
import OfferTopView from "../../components/Configurator/OfferTopView";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

const styles = theme => ({
  root: {
    //   width: '100%',
    //   marginTop: theme.spacing(3),
    //   overflowX: 'auto',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  /*menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },*/
  /*iconButton: {
    marginLeft: 30,
  },*/
  // TODO add margin to all buttons!
  table: {
    minWidth: 700,
    maxWidth: 1150,
    margin: "0 auto",
  },
  cardTitle: {
    color: "white"
  },
  cardHeader: {
    background: "white",
  },
  porscheHeader: {
    background: "linear-gradient(60deg, #094261, #004665)",
    ...defaultBoxShadow,
    padding: "1rem",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "1.15rem",
  },
  summaryHeader: {
    background: "linear-gradient(60deg, #666666, #666666)",
    ...defaultBoxShadow,
    padding: "1rem",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "1.15rem",
  },
  dealerImage: {
    width: "90%",
    padding: 15,
    margin: "auto"
  },
  offerWidth: {
    maxWidth: 1150,
    margin: "0 auto"
  }
});

class OfferView extends React.Component {
  state = {
    loaded: this.props.config.model && this.props.config.offer && this.props.config.finance,
    finance_summary: undefined
  };

  constructor(props) {
    super(props);
    if(this.props.config.offer === undefined) {
      // Data is fetched in the Offer.jsx
      this.props.history.push("/offer/" + this.props.match.params.code);
    } //else {
      //this.state = { finance_summary: getSummaryFinance(this.props.config.finance.items) };
    //}
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log("OfferView componentDidUpdate props", this.props);
    const { cart, finance, model, offer } = this.props.config;
    // if everything is loaded - or updated
    if (model && offer && finance &&
      (cart !== prevProps.config.cart
        || model !== prevProps.config.model
        || finance !== prevProps.config.finance)) {
      //console.log("OfferView componentDidUpdate OK", this.props);
      this.setState({
        ...this.state,
        summary: getSummaryCart(cart, finance.items),
        finance_summary: getSummaryFinance(finance.items),
        loaded: true
      });
    }
    if(model !== prevProps.config.model) {
      this.props.fetchCartFinance();
    }
  }

  render() {
    const { classes } = this.props;
    //console.log("OfferView state", this.state);
    //console.log("OfferView props", this.props);

    const { model, offer, finance, insurance, selected_finance, customer, dealer } = this.props.config;

    if(!this.state.loaded)
      return <Spinner />;

    const share_url = offer.id && base_url + "/offer/" + offer.id.substring(1, 16);
    const pdf_url = database_request_url + "/offerpdf/" + offer.id;
    const vcf_url = database_request_url + "/dealer/contact/" + dealer.id;
    console.log("pdf_url", pdf_url);
    return (
      <div className={classes.root} style={{ paddingTop: 84 }}>
        <OfferTopView className={classes.offerWidth} /*style={{maxWidth: 1150, margin: "0 auto"}}*/ />

        <GridContainer className={classes.offerWidth} /*style={{maxWidth: 1150, margin: "0 auto"}}*/>
          <GridItem md={7}>
            <Translate id="final.thankyou" data={customer && {name: customer.firstname + " " + customer.lastname, model: model.name, email: customer.email, brand: model.series.brand}}/>
          </GridItem>
          <GridItem item md={1}></GridItem>
          <GridItem item md={4}>
            <Confirmation classes={classes}/>
            <Button variant="contained" color="secondary" disableElevation size="lg" style={{width: "100%"}} href={vcf_url} rel="noopener noreferrer" target="_blank">
              <Translate id="final.createDealerVCard"/>
            </Button>
            {/*<Button variant="contained" color="secondary" size="lg" style={{width: "100%"}}>
              Secondary
            </Button>
            <Button variant="contained" color="secondary" size="lg" style={{width: "100%"}}>
              Secondary
            </Button>*/}
            <Button variant="contained" color="secondary" size="lg" style={{width: "100%"}}
                    onClick={() => window.open(pdf_url, "_blank")}>
               <Translate id="final.downloadPDF"/>
            </Button>
          </GridItem>
        </GridContainer>

        <Grid container spacing={2} style={{maxWidth: 1150, margin: "0 auto"}}>
          <Grid item xs={12} style={{textAlign: "center"}}>
            <FacebookShareButton  url={share_url}><Button color="facebook"><Translate id="share.facebook"/></Button></FacebookShareButton>
            <TwitterShareButton url={share_url}><Button color="twitter"><Translate id="share.twitter"/></Button></TwitterShareButton>
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{marginTop: "24px", marginBottom: "24px"}}>
            <CartDetail history={this.props.history} editable={false} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container spacing={1}>
              {selected_finance && <FinanceInsuranceCardFinal classes={{porscheHeader: classes.porscheHeader}} financing={finance.results} summary={finance.result} insurance={insurance} editable={false} />}
              {/*<CollapsibleCard classes={classes} title={<Translate id="final.headers.details"/>} body={
                <CartComplete cart={cart} sizes={sizes} finance={finance} model={model} color={color} classes={classes} cash={selected_finance.type === CASH}/>
              }/>*/}
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <PersonalData editable={false} classes={classes} />
          </Grid>
          <Grid item md={6} xs={12}>
            <DealerCard editable={false} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

OfferView.propTypes = {
  classes: PropTypes.object.isRequired,
  finance: PropTypes.object,
  dealer: PropTypes.object,
  offer: PropTypes.object
};

const mapStateToProps = state => ({
  config: state.config,
  offer: state.config.offer
});

export default withRouter(withStyles(styles)(
  connect(
    mapStateToProps,
    { fetchCartFinance }
  )(OfferView)
));
