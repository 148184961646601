import React from "react";
import PropTypes from "prop-types";

import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";

import formsStyle from "assets/jss/custom/forms";
import { withWidth } from "@material-ui/core";
import { modifyFinancingConfig, CASH, updateFinancing } from "../../actions/configAction";
import { ccyFormat, getSummaryFinance, stringToBoolean } from "../../Utils";
import { toggleFinancingMode } from "../../actions/globalAction";
import FinanceInfoDetail from "./FinanceInfoDetail";

import {
  withStyles,
  Input,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Grid,
  RadioGroup, FormControlLabel, Radio, FormLabel, Hidden, isWidthDown
} from "@material-ui/core";
import Spinner from "../Configurator/Spinner";
import { DialogSimple } from "../Dialog/DialogSimple";
import { fetchCategories } from "../../actions/articlesAction";

export function TabContainer({ children }) {
  return (
    <div style={{ padding: 8 * 1 }}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class FinanceConfig extends React.Component {
  state = {
    deposit: false,
    deposit_error: false,
    deposit_helper: "hints.deposit_euro",

    remainder: false,
    remainder_error: false,
    remainder_helper: "hints.remainder_euro",

    financing_mode: undefined,
    showInfo: false,
    modified: false,
    cash: (this.props.config && this.props.config.type) && this.props.config.type === "OHNE FINANZIERUNG"
  };

  constructor(props) {
    super(props);
    //console.log("FinanceConfig constructor", props);
    //this.state = {
      //cash: props.config.type === "OHNE FINANZIERUNG"
    //};

    if (props.model === undefined && props.match.params.model && props.match.params.color) {
      props.history.replace("/config/" + props.match.params.model + "/" + props.match.params.color);
    }

    if(props.categories === undefined) {
      this.props.fetchCategories();
    }
  }

  handleChangeMode = mode => {
    //console.log("FinanceConfig handleChangeMode props", this.props);
    //console.log("FinanceConfig handleChangeMode state", this.state);
    if(mode) {
      // Backup
      this.setState({ financing_mode: this.props.finance.result.name, modified: true });
      this.props.modifyFinancingConfig("type", CASH);
    } else { // switch back to financing
      this.setState({ modified: true });
      this.props.modifyFinancingConfig("type", this.state.financing_mode);
    }
  };

  handleChange = e => {
    this.setState({modified: true});
    this.props.modifyFinancingConfig(e.target.name, e.target.value);
  };

  keyPress(e) { // blur input field and update the value
    if(e.keyCode === 13){
      e.target.blur();
    }
  }

  updateDeposit(e) {
    //console.log("updateDeposit event", e);
    const { result } = this.props.finance;
    const percent = e.target.value / result.totals * 100.0;

    // check if value is within the allowed range:
    let values = [];
    switch (e.target.name) {
      case "deposit":
        values = result.deposits;
        break;
      case "remainder":
        values = result.restwerte;
        break;
      default:
        break;
    }
    const min = Math.min(...values);
    const max = Math.max(...values);

    if (percent >= min && percent <= max) {
      this.props.modifyFinancingConfig(e.target.name, percent);
      this.setState({
        modified: true,
        [e.target.name]: false,
        [e.target.name + "_error"]: false,
        [e.target.name + "_helper"]: "hints." + e.target.name + "_euro"
      });
    } else {
      this.setState({
        [e.target.name + "_error"]: true,
        [e.target.name + "_helper"]: "errors." + e.target.name + "_invalid_value"
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.customDeposit !== null && prevProps.finance.deposit_euro !== this.props.finance.deposit_euro) {
      this.customDeposit.value = ccyFormat(this.props.finance.deposit_euro);
    }
    if(this.customRemainder !== null && prevProps.finance.remainder_euro !== this.props.finance.remainder_euro) {
      this.customRemainder.value = ccyFormat(this.props.finance.remainder_euro);
    }
    if(prevState.cash !== this.state.cash) {
      this.handleChangeMode(this.state.cash);
    }
  }

  /**
   * Save all modifications
   */
  componentWillUnmount() {
    /*this.state.modified && */this.props.updateFinancing();
  }

  render() {
    //console.debug("FinanceConfig props", this.props);
    //console.debug("FinanceConfig state", this.state);
    const { classes, finance, config, width } = this.props;

    if(finance === undefined)
      return <Spinner/>;

    //const cash_index = finance.result.names.findIndex(e => e === CASH);
    //if(cash_index > 0) { // cash payment found and allowed
    //  finance.result.names.splice(cash_index);
    //}

    const fullconfig = finance.result.ppy === 12;

    const runtimes = (finance.result.runtimes.length > 0) &&
      <FormControl>
        <Select
          value={finance.result.n_payments}
          onChange={this.handleChange.bind(this)}
          input={<Input name="payments" id="payments-helper"/>}
          className={classes.backgroundEmphasize}
        >
          {finance.result.runtimes.map((r, i) =>
            <MenuItem key={i} value={r}>{r}</MenuItem>
          )}
        </Select>
        <FormHelperText><Translate id="hints.runtime"/></FormHelperText>
      </FormControl>;

    const deposits = (finance.result.deposits.length > 0) &&
      <FormControl>
        <Select
          value={finance.result.deposit_perc}
          onChange={this.handleChange.bind(this)}
          input={<Input name="deposit" id="deposit-helper" />}
        >
          {finance.result.deposits.map((r, i) =>
            <MenuItem key={i} value={r}>{r} %</MenuItem>
          )}
          {finance.result.deposits.find(e => e === finance.result.deposit_perc) === undefined &&
            <MenuItem value={finance.result.deposit_perc}>{finance.result.deposit_perc.toLocaleString("de-DE", { maximumFractionDigits: 2 })} %</MenuItem>
          }
        </Select>
        <FormHelperText><Translate id="hints.deposit_perc"/></FormHelperText>
      </FormControl>;

    const remainings = (finance.result.restwerte.length > 0) &&
      <FormControl >
        <Select
          value={finance.result.remainder_perc}
          onChange={this.handleChange.bind(this)}
          input={<Input name="remainder" id="remainder-helper" />}
        >
          {finance.result.restwerte.map((r, i) =>
            <MenuItem key={i} value={r}>{r} %</MenuItem>
          )}
          {finance.result.restwerte.find(e => e === finance.result.remainder_perc) === undefined &&
          <MenuItem value={finance.result.remainder_perc}>{finance.result.remainder_perc.toLocaleString("de-DE", { maximumFractionDigits: 2 })} %</MenuItem>
          }
        </Select>
        <FormHelperText><Translate id="hints.remainder_perc"/></FormHelperText>
      </FormControl>;

    const values = [false, true];

    const handleSelectMode = e => {
      //console.log("FinanceConfig handleSelectMode", { [e.target.name]: stringToBoolean(e.target.value) });
      this.setState({ ...this.state, [e.target.name]: stringToBoolean(e.target.value) });
    };

    const labelWidth = 4;

    return (
      <Grid container className={"config"}>
        <Grid item xs={12} md={12} className={classes.configLeft}>
          <h2>
            <Translate id="finance.intro.title"/>{" "}
            <i className="fas fa-info-circle" onClick={() => this["dialog-info-finintro"].handleClickOpen()}/>
            <DialogSimple ref={dialog => this["dialog-info-finintro"] = dialog}  id="dialog-info-fin" title={<Translate id="finance.intro.title" />}>
              <Translate id="finance.intro.info" />
            </DialogSimple>
          </h2>
        </Grid>
        <Grid item xs={12} md={6} className={classes.configLeft}>
          <p><Translate id={isWidthDown("xs", width) ? "finance.intro_xs.text" : "finance.intro.text"}/></p>
          <FormControl component="fieldset" className={classes.selectFinance}>
            <RadioGroup row aria-label="cash" value={this.state.cash} name="cash" /*defaultValue="top"*/ onChange={handleSelectMode}>
              {values.map((v, i) =>
                <FormControlLabel
                  key={i}
                  value={v}
                  control={<Radio color="primary" />}
                  label={<Translate id={"finance.selectcash." + v} />}
                />
              )}
            </RadioGroup>
          </FormControl>
          <p><Translate id={isWidthDown("xs", width) ? "finance.intro_xs.post" : "finance.intro.post"} /></p>
          <Grid container spacing={2}>
            {!this.state.cash && <>
              <Hidden mdDown>
                <Grid item lg={labelWidth}><FormLabel><Translate id="hints.type"/></FormLabel></Grid>
              </Hidden>
              <Grid item xs={12} lg={12 - labelWidth}>
                <FormControl>
                  <Select value={config.type} onChange={this.handleChange.bind(this)} input={<Input name="type" id="type-helper"/>}>
                    {finance.result.names.map((n, i) =>
                      <MenuItem key={i} disabled={n === CASH} value={n}>{n}</MenuItem>
                    )}
                  </Select>
                  <FormHelperText><Translate id="hints.type"/></FormHelperText>
                </FormControl>
              </Grid>
            </>
            }
            {!this.state.cash && fullconfig && <React.Fragment>
              <Hidden mdDown>
                <Grid item xs={6} lg={labelWidth}><FormLabel><Translate id="hints.runtime"/></FormLabel></Grid>
              </Hidden>
              <Grid item xs={12} lg={12 - labelWidth}>
                {runtimes}
              </Grid>
              <Hidden mdDown>
                <Grid item xs={12} lg={labelWidth}><FormLabel><Translate id="hints.deposit"/></FormLabel></Grid>
              </Hidden>
              <Grid item xs={6} lg={(12 - labelWidth) / 2}>
                {deposits}
              </Grid>
              <Grid item xs={6} lg={(12 - labelWidth) / 2}>
                <TextField
                  id="depositAmount"
                  error={this.state.deposit_error}
                  disabled={!this.state.deposit}
                  defaultValue={ccyFormat(finance.result.deposit_euro)}
                  onClick={() => {
                    if(!this.state.deposit) { // activate
                      this.setState({
                        deposit: !this.state.deposit,
                      });
                      this.customDeposit.value = finance.result.deposit_euro;
                    } /*else {
                          this.setState({
                            customDeposit: !this.state.customDeposit
                          });
                        }*/

                  }}
                  onBlur={this.updateDeposit.bind(this)}
                  onKeyDown={this.keyPress}
                  name="deposit"
                  className={classes.formControl}
                  helperText={<Translate id={this.state.deposit_helper}/>}
                  inputRef={el => this.customDeposit = el}
                />
              </Grid>
              <Hidden mdDown>
                <Grid item xs={12} lg={labelWidth}><FormLabel><Translate id="hints.remainder"/></FormLabel></Grid>
              </Hidden>
              <Grid item xs={6} lg={(12 - labelWidth) / 2}>
                {remainings}
              </Grid>
              <Grid item xs={6} lg={(12 - labelWidth) / 2}>
                <TextField
                  id="remainderAmount"
                  error={this.state.remainder_error}
                  disabled={!this.state.remainder}
                  defaultValue={ccyFormat(finance.result.remainder_euro)}
                  onClick={() => {
                    if(!this.state.remainder) { // activate
                      this.setState({
                        remainder: !this.state.remainder,
                      });
                      this.customRemainder.value = finance.result.remainder_euro;
                    }
                  }}
                  onBlur={this.updateDeposit.bind(this)}
                  onKeyDown={this.keyPress}
                  name="remainder"
                  className={classes.formControl}
                  helperText={<Translate id={this.state.remainder_helper}/>}
                  inputRef={el => this.customRemainder = el}
                />
              </Grid>
            </React.Fragment>}

            {!this.state.cash && !fullconfig &&
            <Grid item md={9}>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary={<Translate id="payments.first"/>}
                    secondary={<Translate id="payments.first_sub"/>}
                  />
                  <ListItemSecondaryAction>
                    {ccyFormat(finance.result.deposit_euro)}
                  </ListItemSecondaryAction>
                </ListItem>
                {Array(finance.result.n_payments).fill(1).map((v, i) => (
                  <ListItem key={i}>
                    <ListItemText
                      primary={<Translate id="payments.nth" data={{n:i+2}}/>}
                      secondary={<Translate id="payments.nth_sub" data={{n:i+2}}/>}
                    />
                    <ListItemSecondaryAction>
                      {ccyFormat(finance.vehicle.rate + getSummaryFinance(finance.items).rate)}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <FinanceInfoDetail cash={this.state.cash} history={this.props.history} />
          {/*<Button color={"secondary"} onClick={() => console.log("reset")}>Reset to defaults</Button>
          <Button
            variant={"contained"}
            //color={"porsche"}
            className={classes.buttonAccept}
            onClick={() => { this.props.updateFinancing(); this.props.handleNext(this.props.step + 1);}}>Speichern und weiter</Button>*/}
        </Grid>
      </Grid>
    );
  }
}

FinanceConfig.propTypes = {
  classes: PropTypes.object.isRequired,
  finance: PropTypes.object,
  config: PropTypes.object,
  mode: PropTypes.number.isRequired,
  categories: PropTypes.array,

  modifyFinancingConfig: PropTypes.func.isRequired,
  toggleFinancingMode: PropTypes.func.isRequired,
  updateFinancing: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  finance: state.config.finance,
  config: state.config.finance_config,
  model: state.config.model,
  color: state.config.color,
  allfinance: state.config,
  categories: state.articles && state.articles.categories,
  mode: state.global.financingMode
});

export default withWidth()(withStyles(formsStyle)(
  connect(
    mapStateToProps,
    { modifyFinancingConfig, updateFinancing, toggleFinancingMode, fetchCategories }
  )(withLocalize(FinanceConfig)))
);
