import { combineReducers } from "redux";

import articleReducer from "./articleReducer";
import bikesReducer from "./bikesReducer";
import configReducer from "./configReducer";
import globalReducer from "./globalReducer";
import errorReducer from "./errorReducer";

export default combineReducers({
  articles: articleReducer,
  bikes: bikesReducer,
  config: configReducer,
  global: globalReducer,
  error: errorReducer
});
