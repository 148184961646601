import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchModelsBySeriesId, fetchSeries } from "../actions/bikesAction";

import Carousel from "react-slick";
import BikeSeriesItem from "../components/Configurator/BikeSeriesItem";

import { CircularProgress, Fab } from "@material-ui/core";
import { getImageUrl } from "../components/Image/Image";
import { Translate } from "react-localize-redux";
import { getBackgroundWidth, getBikeImageWidth } from "../actions/screens";
import { showSeries } from "../actions/globalAction";
import { setSeries } from "../actions/configAction";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { carouselSettings } from "../store";

export function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className}>
      <Fab color="primary" aria-label="Nächstes" onClick={onClick} size="small">
        <ArrowRight />
      </Fab>
    </div>
  );
}

export function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className}>
      <Fab color="primary" aria-label="Voriges" onClick={onClick} size="small">
        <ArrowLeft />
      </Fab>
    </div>
  );
}

class BikeSeriesCarousel extends React.Component {
  state = {
    loaded: 0, // required for spinner
    ready: this.props.available.length > 0,
    images: false
  };

  constructor(props) {
    super(props);
    this.props.fetchSeries(); // calls the reducer action
    carouselSettings.initialSlide = props.selected && props.available ? props.available.findIndex(s => s.id === props.selected.id) : 0;
  }

  imageLoaded(path) {
    this.setState((previousState) => {
      const images = { ...previousState.images, [path]: true };
      const ready = Object.values(images).reduce((accumulator, currentValue) => accumulator && currentValue);
      return {
        ...previousState,
        loaded: previousState.loaded + 1,
        images: images,
        ready: ready
      };
    });
  }

  preloadImage(image, width) {
    const img = new Image();
    img.src = getImageUrl(image, width);

    this.setState((previousState) => { return {
        ...previousState,
        images: { ...previousState.images, [img.src]: false }
      };
    });
    img.onload = this.imageLoaded.bind(this, img.src);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.available !== prevProps.available) {
      if(this.props.match.params.series !== undefined) {
        this.preselect(this.props.match.params.series);
      } else {
        this.props.showSeries(this.props.available[0]);
      }
      this.props.available.map(item => {
        this.preloadImage(item.background,getBackgroundWidth(this.props.width));
        this.preloadImage(item.bike, getBikeImageWidth(this.props.width));
        return item.id;
      });
    }
  }

  handleChange(index) {
    const series = this.props.available[index];
    if(series) {
      this.props.showSeries(series);
    }
  }

  handleClick(e) {
    this.props.setSeries(e.series);
    this.props.history.push("/models/" + e.series.id);
  }

  preselect(series) {
    //console.debug("preselect Series: ", series);
    const index = this.props.available.findIndex(s => s.id === series);
    if (index >= 0) {
      carouselSettings.initialSlide = index;
      this.props.showSeries(this.props.available[index]);
    }
  }

  render() {
    const classes = this.state.ready ? "carousel show" : "carousel ";
    return (
      <div>
        {!this.state.ready && (
          <div className="carousel-loading">
            <CircularProgress
              className="progress"
              variant="determinate"
              value={(this.props.available.length * 2 / this.state.loaded) * 100 }
              color="secondary"
            />
            <p>
              <Translate id="loader.series" />
            </p>
          </div>
        )}
        <div className={classes}>
          {this.state.ready && (
            <Carousel ref={ slider => this['series_slider'] = slider } {...carouselSettings} afterChange={this.handleChange.bind(this)}>
              {this.props.available.map(series => (
                <BikeSeriesItem
                  key={series.id}
                  series={series}
                  select={this.handleClick.bind(this)}
                />
              ))}
            </Carousel>
          )}
        </div>
      </div>
    );
  }
}

BikeSeriesCarousel.propTypes = {
  fetchSeries: PropTypes.func.isRequired,
  fetchModelsBySeriesId: PropTypes.func.isRequired,
  showSeries: PropTypes.func.isRequired,
  setSeries: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  available: PropTypes.array,
  selected: PropTypes.object,
  screen: PropTypes.number
};

const mapStateToProps = state => ({
  available: state.bikes.available_series,
  selected: state.config.series
});

export default connect(mapStateToProps, {
  fetchSeries,
  fetchModelsBySeriesId,
  showSeries,
  setSeries
})(BikeSeriesCarousel);
