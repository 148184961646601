import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchCartFinance, fetchOffer } from "../actions/configAction";

import { defaultBoxShadow } from "../assets/jss/material-kit-react";
import OfferForm from "./Configurator/OfferForm";
import OfferPublicView from "./Configurator/OfferPublicView";
import { withStyles } from '@material-ui/core';
import Spinner from "components/Configurator/Spinner";
import { fetchCategories } from "../actions/articlesAction";

const styles = theme => ({
  root: {
    //   width: '100%',
    //   marginTop: theme.spacing(3),
    //   overflowX: 'auto',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  /*menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },*/
  /*iconButton: {
    marginLeft: 30,
  },*/
  // TODO add margin to all buttons!
  table: {
    minWidth: 700,
    maxWidth: 1150,
    margin: "0 auto",
  },
  cardTitle: {
    color: "white"
  },
  cardHeader: {
    background: "white",
  },
  porscheHeader: {
    background: "linear-gradient(60deg, #094261, #004665)",
    ...defaultBoxShadow,
    padding: "1rem",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "1.15rem",
  },
  summaryHeader: {
    background: "linear-gradient(60deg, #666666, #666666)",
    ...defaultBoxShadow,
    padding: "1rem",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "1.15rem",
  },
  dealerImage: {
    width: "90%",
    padding: 15,
    margin: "auto"
  }
});

class Offer extends React.Component {
  state = {
    loading: undefined
  };

  constructor(props) {
    super(props);
    const { code } = this.props.match.params;
    if(code !== undefined) {
      // prepare loading of data:
      this.state = { loading: true };
      this.props.fetchOffer(code);
      this.props.fetchCategories(); // just preload
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.offer !== this.props.offer) {
      if(this.props.match.code === undefined && this.props.offer !== undefined) { // set offer to URL
        this.props.history.push("/offer/" + this.props.offer.id);
        this.setState({ loading: false });
      }
    }
    if(prevProps.error !== this.props.error) {
      this.setState({ loading: false });
    }
    if(this.props.offer && prevProps.model !== this.props.model && this.props.model !== undefined) {
      if (this.props.offer.status === "CREATED" || this.props.offer.status === "SAVED") {
        // TODO if customer is incomplete forward to customer)
//        if(this.props.customer === undefined)
          this.props.history.push("/customer/" + this.props.offer.id);
//        else
        // TODO if dealer is incomplete forward to dealer
//          if(this.props.dealer === undefined)
//            this.props.history.push("/delivery/" + this.props.offer.id);
//          else
          // TODO else forward to confirmation
//            this.props.history.push("/confirmation/" + this.props.offer.id);
      }
      else
        this.props.history.push("/status/" + this.props.offer.id);
    }
  }

  render() {
    if(this.state.loading)
      return <Spinner/>;

    if(this.props.offer === undefined)
      return <OfferForm history={this.props.history} />;

    if(this.props.shareview)
      return <OfferPublicView />;

    if(this.props.offer.id && this.props.model !== undefined) { // VERIFY wait until offer, customer, dealer, model is set.
      // if offer is not submitted show final page.
      return <Spinner/>;
    } else {
      return <Spinner/>;
    }
  }
}

Offer.propTypes = {
  classes: PropTypes.object.isRequired,
  shareview: PropTypes.bool.isRequired,
  offer: PropTypes.object,
  model: PropTypes.object,
  dealer: PropTypes.object,
  customer: PropTypes.object,

  fetchOffer: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  fetchCartFinance: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  offer: state.config.offer,
  model: state.config.model,
  finance: state.config.finance,
  customer: state.config.customer,
  dealer: state.config.dealer,
  shareview: state.config.status === -1,
  error: state.error.id
});

export default withRouter(withStyles(styles)(
  connect(
    mapStateToProps,
    { fetchOffer, fetchCategories, fetchCartFinance }
  )(Offer)
));
