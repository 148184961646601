import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { NextArrow, PrevArrow } from "./views/BikeSeriesCarousel";
import React from "react";
import { Facebook, Instagram } from "@material-ui/icons";

/*** Some Constants ***/
export const HEADER_APP_BAR_HEIGHT = 84;
export const DRAWER_WIDTH = 360;
export const MOBILE_STEPPER_HEIGHT = 55;
export const GOOGLE_API_KEY = "AIzaSyDeE0RvQpQFGw3VvzQWxfOupeg9Y6HiZqU";

// reference https://www.youtube.com/watch?v=93p3LxR9xfM
// compose and enhancer is only needed for the chrome extension

export const database_request_url = "https://duc-server.rennviech.com";
//export const calculator_request_url = "https://calc.ducati.at/calculator/Calc.php";
export const base_url = "https:/duc-conf.rennviech.com";
export const image_url = "https://d1s8h3ngyi7cb6.cloudfront.net";
//export const image_url = "http://ducati-configurator.s3-website.eu-central-1.amazonaws.com/processed";

export const impress_url = "https://www.ducati.at/impressum/";
export const gdpr_url = "https://www.ducati.at/datenschutz/";
export const datasheet_base_url = "https://d1c22obbl1e6as.cloudfront.net/";

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    // FIXME this may cause problems on the live server and browsers without the redux extension
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export const socialLinks = [
  {
    id: "instagram",
    title: "Folge Ducati auf Instagram",
    url: "https://www.instagram.com/ducatiaustria/?hl=de",
    icon: <Instagram />
  }, {
    id: "facebook",
    title: "Folge Ducat auf facebook",
    url: "https://www.facebook.com/DucatiAustria",
    icon: <Facebook />
  }];

export const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  centerMode: true,
  className: "center",
  centerPadding: "16.6666%",
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false
      }
    }
  ]
};

export default store;