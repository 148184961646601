import React from "react";
import PropTypes from "prop-types";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

export default class CollapsibleCard extends React.Component {
  state = {
    collapse: false
  };

  collapse(){
    console.log("collapse", this.state);
    this.setState({
      collapse: !this.state.collapse
    })
  };

  render(){
    const { body, title, classes } = this.props;
    return (
      <Card>
        <CardHeader className={classes.summaryHeader} collapse={this.state.collapse} collapseFunction={this.collapse.bind(this)}>{title}</CardHeader>
        <CardBody>
          {!this.state.collapse && body}
        </CardBody>
      </Card>);
  };

}

CollapsibleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  body: PropTypes.object.isRequired
};