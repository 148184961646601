import React from "react";
import { CategoryButton } from "../Cart/ActionButtons";
import { Translate } from "react-localize-redux";

export const buttons = [
  {
    link: "accessories",
    key: "9A0726EC-EE92-4475-839E-562D3AF29318",
    title: <Translate id="labels.accessories" />,
    shorttitle: <Translate id="shortlabels.accessories" />,
    icon: <i className="duc-fender-right lg" />
  },
  {
    link: "outfit",
    key: "26098EC4-C1B5-46EC-A393-CDAD7C00A0C5",
    title: <Translate id="labels.clothing" />,
    shorttitle: <Translate id="shortlabels.clothing" />,
    icon: <i className="duc-shirt lg" />
  },
  {
    link: "packages",
    key: "1438463F-2F8F-46B3-9CDF-576345C13179",
    title: <Translate id="labels.service" />,
    shorttitle: <Translate id="shortlabels.service" />,
    icon: <i className="duc-wrenchcheck lg" />
  }
];

function ActionSelectButtons({ ...props }) {
  return <>
    {buttons.map((button, i) => {
      const disabled = !(props.articles && props.articles[button.key] && props.articles[button.key].length > 0);
      return <CategoryButton
        key={i}
        onClick={() => !disabled && (props.history.push(props.baseUrl + button.key))}
        title={props.size === "lg" ? button.title : button.shorttitle}
        icon={button.icon}
        justIcon
        size={props.size}
        round
        disabled={disabled}
        //classes={props.classes}
      />}
    )}
      {/*<Tooltip
        id="cart"
        title="Bitte fügen Sie Artikel hinzu"
        //placement={window.innerWidth > 959 ? "top" : "left"}
        //classes={{tooltip: props.classes.tooltip}}
        //classes={}
      >
        TT
      </Tooltip>*/}
    </>;
}

export default ActionSelectButtons;
