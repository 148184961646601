import {
  FETCH_ARTICLES,
  FETCH_ACCESSORIES_CATEGORIES,
  FETCH_OUTFIT,
  FETCH_OUTFIT_CATEGORIES,
  SELECT_ARTICLE, FETCH_CATEGORIES,
  FETCH_PACKAGES,
  FETCH_ARTICLE_SIZES
} from "../actions/types";

const initialState = {
  articles: {},
  categories: [],
  //parentMapping: {},

  sizes: {},
  selected: undefined,
  selected_topcat: undefined
};

export default function(state = initialState, action) {
  //console.log("articleReducer", action);
  switch (action.type) {
    case FETCH_ARTICLES:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.topcat]: action.payload
        }
      };
    case FETCH_ACCESSORIES_CATEGORIES:
      return {
        ...state,
        accessories_categories: action.payload // return the current state but also set the series coming from the action payload
      };

    case FETCH_OUTFIT:
      return {
        ...state,
        outfit: action.payload
      };
    case FETCH_OUTFIT_CATEGORIES:
      return {
        ...state,
        outfit_categories: action.payload
      };
    case FETCH_CATEGORIES:
      //  traverse tree to set each category's parent // non-recursive three levels
      /*let parents = {};
      action.payload.map(category => {
        category.sub.map(sub => {
          parents = {
            ...parents,
            [sub.id]: category.id
          };
          //console.log("PARENTS", parents);
          sub.sub.map(subsub => parents = {
            ...parents,
            [subsub.id]: category.id
          });
        });
      });*/
      return {
        ...state,
        categories: action.payload
        //parentMapping: parents
      };
    case SELECT_ARTICLE:
      return {
        ...state,
        selected: action.article,
        selected_topcat: action.topcat
      };
    case FETCH_PACKAGES:
      return {
        ...state,
        packages: action.payload
      };
    case FETCH_ARTICLE_SIZES:
      //console.log("Sizes of " + action.id, action.payload);
      return {
        ...state,
        sizes: {
          ...state.sizes,
          [action.id]: action.payload
        }
        //,
        //...array.slice(action.index)

        //sizes: [...state.sizes, [action.id]: action.payload]
      };
    default:
      return state;
  }
};
