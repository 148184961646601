import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArticlesList from "../ArticlesSubList";
import Spinner from "../../Configurator/Spinner";
import { Translate } from "react-localize-redux";

export const TOP_CATEGORY_KEY = "cl";

class OutfitList extends React.Component {
  render() {
    const { category, articles } = this.props;
    // remove category parent (subheader) but the first
    console.log("OutfitList", this.props);

    if(this.props.articles === undefined || this.props.category.sub === undefined) {
      return <Spinner/>;
    } else {
      if (this.props.articles.length === 0) {
        return <div style={{ textAlign: "center" }}><Translate id={"list.empty"}/></div>;
      } else {
        return (
          <div>
            <ArticlesList categories={category.sub} topcat={category} articles={articles} history={this.props.history} sublevel={2}/>
          </div>
        );
      }
    }
  }
}

OutfitList.propTypes = {
  articles: PropTypes.array,
  category: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => {
  const topcat = state.articles.categories.find(e => e.key === TOP_CATEGORY_KEY);
  return ({
    articles: state.articles.articles[topcat.id],
    category: topcat
  });
};

export default connect(
  mapStateToProps,
  { }
)(OutfitList);
