import { FETCH_MODELS, FETCH_SERIES, SET_MODEL, SET_SERIES, FETCH_MODEL_IMAGES, SET_COLOR, SHOW_MODEL, SHOW_SERIES } from "./types";

import { database_request_url } from "../store";

export const fetchSeries = preselect => (dispatch, getState) => {
  const state = getState();
  if(state.bikes.available_series.length === 0) {
    //console.log("fetchSeries do fetch", preselect);
    fetch(database_request_url + "/series")
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: FETCH_SERIES,
          payload: data
        });
        //console.log("PRESELECT " + preselect);
        //console.log(data);
        //console.log(data.find(s => s.id === preselect));
        preselect &&
        dispatch({
          type: SET_SERIES,
          payload: data.find(s => s.id === preselect)
        });
        //caller.setState({ available: data });
      })
      .catch(err => {
        console.error("caught it!", err);
      });
  } else {
    //console.log("fetchSeries just select", preselect);
    preselect &&
    dispatch({
      type: SET_SERIES,
      payload: state.bikes.available_series.find(s => s.id === preselect)
    });
  }
};

/*export const fetchSeriesById = seriesId => dispatch => {
  fetch(database_request_url + "/series/" + seriesId)
    .then(response => response.json())
    .then(data => {
      if(data.length === 1)
        dispatch({
          type: SET_SERIES,
          payload: data[0]
        });
      else
        dispatch({
          type: FATAL_ERROR,
          id: fetchSeriesById.name
        });
    })
    .catch(err => {
      console.error("caught it!", err);
    });
};*/

export const fetchModelsBySeriesId = seriesId => (dispatch, getState) => {
  const state = getState();
  //console.log("fetchModelsBySeriesId call", seriesId);
  if(!state.bikes.available_models[seriesId] || state.bikes.available_models[seriesId].length === 0) {
    fetch(database_request_url + "/models/" + seriesId)
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: FETCH_MODELS,
          series: seriesId,
          payload: data
        });
        // fetch images
        //data.map(model => dispatch(fetchModelImages(model.id)));
        dispatch(fetchSeries(seriesId));
      })
      .catch(err => {
        console.error("caught it!", err);
      });
  } else {
    dispatch(fetchSeries(seriesId));
  }
};

export const fetchModelsBySeriesName = seriesName => (dispatch, getState) => {
  const state = getState();
  const series = state.series && state.series.find(s => s.name.toLowerCase() === seriesName.toLowerCase());
  //console.log("Series cached by name: ", series);
  if(series && state.bikes.available_models[series.id]) {
    //console.log("fetchModelsBySeriesName models are already cached for series.", series);
    dispatch(fetchSeries(series.id));
  } else {
    fetch(database_request_url + "/models/" + seriesName)
      .then(response => response.json())
      .then(data => {
       // console.log("fetchModelsBySeriesName data", data); // FIXME should load by name
        dispatch({
          type: FETCH_MODELS,
          series: series.id,
          payload: data
        });
        // fetch images
        //data.map(model => dispatch(fetchModelImages(model.id)));
      })
      .catch(err => {
        console.error("caught it!", err);
      });
  }
};

/*export const fetchSeriesBySeriesId = seriesId => dispatch => {
  console.log("fetchSeriesBySeries Action", seriesId);
  dispatch(fetchSeries());
  //dispatch(fetchSeriesById(seriesId));
};*/

export const fetchModelById = (modelId, colorId) => dispatch => {
  fetch(database_request_url + "/models/find?id=" + modelId)
    .then(response => response.json())
    .then(data => {
      //console.debug("fetchModelById result", data);
      dispatch({
        type: SET_MODEL,
        model: data
      });

      // set default color:
      const color = (colorId === undefined) ?  data.colors[0] :
        data.colors.find(color => color.color === parseInt(colorId));

      dispatch({
        type: SET_COLOR,
        color: color
      });
      dispatch({
        type: SHOW_MODEL,
        model: data,
        color: color.color
      });
      dispatch({
        type: SHOW_SERIES,
        series: data.series
      });
    })
    .catch(err => {
      console.error("caught it!", err);
    });
};

export const fetchModelImages = modelId => (dispatch, getState) => {
  const { bikes } = getState();
  //console.log("fetchModelImages Action", modelId);
  if(bikes.views[modelId] === undefined)
    fetch(database_request_url + "/modelimages/" + modelId)
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: FETCH_MODEL_IMAGES,
          id: modelId,
          payload: data,
        });
      })
      .catch(err => {
        console.log("caught it!", err);
      });
};
