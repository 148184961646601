import _ from "lodash";

/**
 *
 * @param num
 * @param digits
 * @returns {string}
 */
export function ccyFormat(num, digits = 2) {
  return num.toLocaleString("de-DE", {
    minimumFractionDigits: digits,
    style: "currency",
    currency: "EUR",
    currencyDisplay: "symbol",
    useGrouping: true,
    maximumFractionDigits: digits
  });
}

export function numFormat(num, digits = 2) {
  return num.toLocaleString("de-DE", {
    minimumFractionDigits: digits,
    //style: "currency",
    //currency: "EUR",
    //currencyDisplay: "symbol",
    useGrouping: true,
    maximumFractionDigits: digits
  });
}

export function percFormat(num, digits = 2) {
  return (num / 100.0).toLocaleString("de-DE", {
    style: "percent",
    maximumFractionDigits: digits
  });
}

export function dateFormat(date) {
  return date.toLocaleString("de-DE", { year: 'numeric', month: 'long', day: 'numeric' });
}

/**
 *
 * @param num
 * @returns {string}
 */
export function timeFormat(num) {
  var hours = Math.floor(num / 60);
  var minutes = num % 60;
  return (hours > 0 ? hours + "\u00A0h\u00A0" : "") + minutes + "\u00A0min";
}

export function getSummary(cart, items) {
  //console.debug("getSummary", {cart, items});
  let summary = {
    taxes: 0,
    time: 0,
    subexkl: 0,
    subinkl: 0,
    count: 0,
    total: { inkl: 0, taxes: 0, time: 0, exkl: 0},
    items: {},
    itemscash: {},
    itemsfinanced: {},
    tax: cart.map(({ vat }) => (vat)).reduce((sum) => sum, 20) /* should be equal to 20 */
  };
  cart.map(({ vat, vat_value, priceexcl, labor, priceincl, id, top }) => {
    const item = items.find(item => item.id === id);
    //console.debug("> getSummary item", item);
    return summary = {
      taxes: summary.taxes + priceexcl * (vat / 100.0),
      time: summary.time + labor,
      subexkl: summary.subexkl + priceexcl,
      subinkl: summary.subinkl + priceincl,
      count: summary.count + 1,
      total: {
        inkl: summary.total.inkl + priceincl,
        taxes: summary.total.taxes + vat_value,
        time: summary.total.time + labor,
        exkl: summary.total.exkl + priceexcl
      },
      items: {
        ...summary.items,
        [top]: ((summary.items[top] === undefined) ? 0 : summary.items[top]) + priceincl,
      },
      itemscash: {
        ...summary.itemscash,
        [top]: ((summary.itemscash[top] === undefined) ? 0 : summary.itemscash[top]) + item.price,
      },
      itemsfinanced: {
        ...summary.itemsfinanced,
        [top]: ((summary.itemsfinanced[top] === undefined) ? 0 : summary.itemsfinanced[top]) + item.rate,
      }
    };
  });
  return summary;
}
/**
 *
 * @param cart
 * @returns {{count: number, taxes: number, time: number, subexkl: number, subinkl: number}}
 */
export function getArticlesSummary(cart) {
  // TODO filter articles by top categories
  let summary = { taxes: 0, time: 0, subexkl: 0, subinkl: 0, count: 0};
  cart.map(({ vat, priceexcl, labor, priceincl }) => {
    return summary = {
      taxes: summary.taxes + priceexcl * (vat / 100.0),
      time: summary.time + labor,
      subexkl: summary.subexkl + priceexcl,
      subinkl: summary.subinkl + priceincl,
      count: summary.count + 1
    };
  });
  return summary;
}

/**
 * TODO combine getSummaryCart with getSummaryFinance and move to reducer!
 * @param cart
 * @returns {{total: {inkl: number, taxes: number, time: number, exkl: number}, tax: *, items: {}}}
 */
export function getSummaryCart(cart, items) {
  /*console.log("tax", tax);
  const invoiceSubtotal = cart.map(({ priceincl }) => priceincl).reduce((sum, i) => sum + i, 0);
  const invoiceTaxes = cart.map(({ vat, priceexcl }) => priceexcl * (vat / 100.0)).reduce((sum, i) => sum + i, 0);
  const invoiceTime = cart.map(({ labor }) => labor).reduce((sum, i) => sum + i, 0);
  console.log("invoiceTaxes", invoiceTaxes);
  console.log("invoiceTime", invoiceTime);
  const invoiceTotal = 0; //invoiceTaxes + invoiceSubtotal;
  */
  //console.log("getSummaryCart cart", cart);
  //console.log("getSummaryCart items", items);
  // compute all data:
  let invoice = {
    total: { inkl: 0, taxes: 0, time: 0, exkl: 0},
    items: {},
    tax: cart.map(({ vat }) => (vat)).reduce((sum) => sum, 20) /* should be equal to 20 */
  };
  cart.map(({ vat_value, priceexcl, labor, priceincl, id, top }) => {
    const item = items.find(item => item.id === id);
    //console.log("item", item);
    return invoice = {
      total: {
        inkl: invoice.total.inkl + priceincl,
        taxes: invoice.total.taxes + vat_value,
        time: invoice.total.time + labor,
        exkl: invoice.total.exkl + priceexcl
      },
      items: {
        ...invoice.items,
        [top]: ((invoice.items[top] === undefined) ? 0 : invoice.items[top]) + priceincl,
      }
    };
  });
  return invoice;
}

/**
 *
 * @param items
 * @returns {{baseprice: number, provision: number, price: number, rate: number, items: {}, kaskoN: number, labor: number}}
 */
export function getSummaryFinance(items) {
  //console.debug("getSummaryFinance", items);
  let result = {
    items: {},
    rate: 0,
    provision: 0,
    laborTime: 0,
    price: 0,
    kaskoN: 0,
    total: 0
    //tax: cart.map(({ vat }) => (vat)).reduce((sum) => sum, 20) /* should be equal to 20 */
  };
  items.map(({ top_cat, rate, laborHours, provision, price, kasko, total }) => {
    return result = {
      items: {
        ...result.items,
        [top_cat]: ((result.items[top_cat]===undefined)?0:result.items[top_cat]) + rate,
      },
      rate: result.rate + rate,
      laborTime: result.laborTime + laborHours,
      provision: result.provision + provision,
      price: result.price + price,
      total: result.total + total,
      kaskoN: result.kaskoN + kasko
    };
  });
  console.debug("getSummaryFinance results", result);
  return result;
}

/**
 *
 * @param ppy
 * @returns {string}
 */
export const getPaymentText = ppy => {
  switch (ppy) {
    case 1:
      return "jährlich ab";
    case 12:
      return "monatlich ab";
    default:
      return "unknown";
  }
};

export const getPaymentUnitText = ppy => {
  switch (ppy) {
    case 1:
      return "Jahre";
    case 12:
      return "Monate";
    default:
      return "unknown";
  }
};

export const stringToBoolean = string => {
  if(string === undefined || string === null)
    return false;
  switch(string.toLowerCase().trim()){
    case "true": case "yes": case "1": return true;
    case "false": case "no": case "0": return false;
    default: return Boolean(string);
  }
};

export const isValidUUID = uuid => {
  return uuid && uuid.match("[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[34][0-9a-fA-F]{3}-[89ab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}");
};

export const isCustomerValid = customer => {
  return customer
    && !_.isEmpty(customer.gender)
    && !_.isEmpty(customer.firstname)
    && !_.isEmpty(customer.lastname)
    && !_.isEmpty(customer.email);
};