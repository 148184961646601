import {
  FETCH_SERIES,
  FETCH_MODELS,
  FETCH_BY_SERIES,
  RESET_SERIES, RESET_MODEL, FETCH_MODEL_IMAGES
} from "../actions/types";

const initialState = {
  available_series: [],
  available_models: [],
  views: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_SERIES:
      return {
        ...state,
        available_series: action.payload // return the current state but also set the series coming from the action payload
      };
    case FETCH_MODELS:
      return {
        ...state,
        available_models: {...state.available_models, [action.series] : action.payload }
      };
    case FETCH_BY_SERIES:
      return {
        ...state,
        available_series: action.available,
        series: action.series
      };
    case FETCH_MODEL_IMAGES:
      return {
        ...state,
        views: {
          ...state.views,
          [action.id]: action.payload
        }
      };

    case RESET_SERIES:
      return {
        ...state,
        series: undefined
      };
    case RESET_MODEL:
      return {
        ...state,
        model: undefined
      };
    default:
      return state;
  }
};
