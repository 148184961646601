import React from "react";
import { Translate } from "react-localize-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setDelivery } from "../../actions/configAction";
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

class DeliverySelect extends React.Component {
  handleSelectDelivery = event => {
    this.props.setDelivery(event.target.value);
  };

  render() {
    const { delivery, classes } = this.props;

    const deliverySelections = [{
        type: "dealer",
        text: <Translate id="delivery.selections.dealer"/>
      },
      {
        type: "dealerNoContact",
        text: <Translate id="delivery.selections.dealerNoContact"/>
      },
      {
        type: "delivery",
        text: <Translate id="delivery.selections.delivery"/>
      },{
        type: "deliveryNoContact",
        text: <Translate id="delivery.selections.deliveryNoContact"/>
      }
    ];
    console.debug("DeliverySelect", delivery);
    return (
      <FormControl component="fieldset" className={classes.formControl} error={delivery === 0}>
        {/*<FormLabel component="legend"><Translate id="delivery.hints.deliveryselect"/></FormLabel>*/}
          <RadioGroup aria-label="delivery-simple" name="delivery" value={delivery !== undefined ? delivery : false} onChange={this.handleSelectDelivery.bind(this)}>
            {deliverySelections.map((d, i) =>
              <FormControlLabel key={d.type} value={d.type} control={<Radio color={"primary"} />} label={d.text} />
            )}
          </RadioGroup>
      </FormControl>
    );
  }
}

DeliverySelect.propTypes = {
  classes: PropTypes.object.isRequired,
  delivery: PropTypes.string,
  setDelivery: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  delivery: state.config.offer && state.config.offer.delivery
});

export default connect( mapStateToProps, { setDelivery })(DeliverySelect);
