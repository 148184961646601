import React from "react";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { Translate } from "react-localize-redux";
import PropTypes from "prop-types";
import countryList from "react-select-country-list";
import { connect } from "react-redux";
import { setCustomer } from "../../actions/configAction";

import CloseIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  MenuItem,
  Select,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Newsletter from "./Newsletter";

class PersonalData extends React.Component {
  state = {
    data: {
      ...this.props.customer,
      /*gender: this.props.customer && this.props.customer.gender,
      firstname: this.props.customer && this.props.customer.firstname,
      lastname: this.props.customer && this.props.customer.lastname,
      street: this.props.customer && this.props.customer.street,
      number: this.props.customer && this.props.customer.number,
      zip: this.props.customer && this.props.customer.zip,
      city: this.props.customer && this.props.customer.city,*/
      country: this.props.customer ? this.props.customer.country : "AT"
      /*
      phone: this.props.customer && this.props.customer.phone,
      email: this.props.customer && this.props.customer.email,*/
    },
    valid: {
      gender: this.props.customer !== undefined && this.props.customer !== null && this.props.customer.gender !== undefined,
      firstname: this.props.customer !== undefined && this.props.customer !== null && this.props.customer.firstname !== undefined,
      lastname: this.props.customer !== undefined && this.props.customer !== null && this.props.customer.lastname !== undefined,
      street: true,
      number: true,
      zip: true,
      city: true,
      country: true,
      phone: true,
      email: this.props.customer !== undefined && this.props.customer !== null && this.props.customer.email !== undefined
    },
    edit: this.props.edit
  };

  constructor(props) {
    super(props);
    this.countries = countryList().getData();
  };

  handleChange = event => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value
      },
      valid: {
        ...this.state.valid,
        [event.target.name]: this.validateField(event.target.name, event.target.value)
      }
    });
  };

  handleUpdate = () => {
    const values = Object.values(this.state.valid);
    if(values.reduce((prev,curr) => prev && curr, true))
      this.props.setCustomer(this.state.data);
    this.setState({ edit: false });
  };

  handleEdit = () => {
    this.setState({ edit: true });
  };

  validateField(fieldName, value) {
    switch(fieldName) {
      case "gender":
        return value !== undefined;
      case "firstname":
      case "lastname":
        return value !== undefined && value !== "";
      case "email":
        return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
      case "street":
      case "zip":
      case "city":
      case "country":
      case "phone":
      default:
        return true;
    }
  }

  render() {
    const { classes, editable, customer } = this.props;
    const { data, valid, edit } = this.state;
    console.log("PersonalData state", this.state);
    console.log("PersonalData props", this.props);
    const country = this.countries.find(e => e.value === data.country);
    if(edit || customer === undefined)
      return (
        <Card>
          <CardHeader className={classes.summaryHeader} icon={<CloseIcon/>} action={this.handleUpdate.bind(this)}><Translate id="personal.title.edit"/></CardHeader>
          <CardBody>
            <h4><Translate id="personal.title.view"/></h4>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <FormControl component="fieldset" className={classes.formControl} error={!valid.gender}>
                  <FormLabel component="label"><Translate id="personal.gender"/></FormLabel>
                  <RadioGroup
                    row
                    aria-label={<Translate id="personal.gender"/>}
                    name="gender"
                    className={classes.group}
                    value={data.gender}
                    onChange={this.handleChange}
                    onBlur={this.handleUpdate}
                  >
                    <FormControlLabel value="female" control={<Radio />} label={<Translate id="personal.female"/>} />
                    <FormControlLabel value="male" control={<Radio />} label={<Translate id="personal.male"/>} />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <TextField
                  id="firstname"
                  name="firstname"
                  onChange={this.handleChange}
                  onBlur={this.handleUpdate}
                  label={<Translate id="personal.firstname"/>}
                  error={!valid.firstname}
                  value={data.firstname}
                  //style={{ margin: 8 }}
                  //placeholder="Placeholder"
                  //helperText="Full width!"
                  fullWidth
                  margin="normal"
                  //InputLabelProps={{
                  //  shrink: true,
                  //}}
                />
              </Grid>
              <Grid item md={8}>
                <TextField
                  id="lastname"
                  name="lastname"
                  onChange={this.handleChange}
                  onBlur={this.handleUpdate}
                  error={!valid.lastname}
                  label={<Translate id="personal.lastname"/>}
                  value={data.lastname}
                  //style={{ margin: 8 }}
                  //placeholder="Placeholder"
                  //helperText="Full width!"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  id="street"
                  name="street"
                  onChange={this.handleChange}
                  onBlur={this.handleUpdate}
                  error={!valid.street}
                  label={<Translate id="personal.street"/>}
                  value={data.street}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="number"
                  name="number"
                  onChange={this.handleChange}
                  onBlur={this.handleUpdate}
                  error={!valid.number}
                  label={<Translate id="personal.number"/>}
                  value={data.number}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="zip"
                  name="zip"
                  onChange={this.handleChange}
                  onBlur={this.handleUpdate}
                  error={!valid.zip}
                  label={<Translate id="personal.zip"/>}
                  value={data.zip}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  id="city"
                  name="city"
                  onChange={this.handleChange}
                  onBlur={this.handleUpdate}
                  error={!valid.city}
                  label={<Translate id="personal.city"/>}
                  value={data.city}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <Select
                    style={{ marginTop: 16 }}
                    value={data.country}
                    onChange={this.handleChange}
                    onBlur={this.handleUpdate}
                    inputProps={{
                      name: 'country',
                      id: 'country',
                    }}
                  >
                    {this.countries.map(e =>
                      <MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="phone"
                  name="phone"
                  onChange={this.handleChange}
                  onBlur={this.handleUpdate}
                  error={!valid.phone}
                  label={<Translate id="personal.phone"/>}
                  value={data.phone}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  name="email"
                  onChange={this.handleChange}
                  onBlur={this.handleUpdate}
                  error={!valid.email}
                  label={<Translate id="personal.email"/>}
                  value={data.email}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              {/*<Button variant="contained" color="primary" onClick={this.handleUpdate.bind(this)}>
              <Translate id="personal.confirm"/>
            </Button>*/}
            </Grid>
            <Newsletter classes={classes} />

          </CardBody>
        </Card>
      );
    else
      return (
        <Card>
          <CardHeader className={classes.summaryHeader} icon={editable && <EditIcon/>} action={this.handleEdit.bind(this)}><Translate id="personal.title.view"/></CardHeader>
          <CardBody>
            <p><strong><Translate id={"personal." + data.gender} /> {data.firstname} {data.lastname}</strong></p>
            {data.street !== undefined && data.street !== null && (<p>{data.street} {data.number}, {data.zip} {data.city}</p>) }
            <p>{country !== undefined && country.label}</p>
            {data.phone !== undefined && data.phone && (<p><Translate id="personal.phone"/>: {data.phone}</p>)}
            <p><Translate id="personal.email"/>: {data.email}</p>
          </CardBody>
        </Card>
      );
  }
}

PersonalData.propTypes = {
  classes: PropTypes.object.isRequired,
  customer: PropTypes.object,
  editable: PropTypes.bool,
  setCustomer: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  customer: state.config.customer
});

export default connect(mapStateToProps, { setCustomer })(PersonalData);
