import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from "components/Configurator/Spinner";
import CartComplete from "components/Cart/CartComplete";
import { defaultBoxShadow } from "assets/jss/material-kit-react";
import { Translate } from "react-localize-redux";
import CollapsibleCard from "components/Card/CollapsibleCard";
import OfferTopView from "components/Configurator/OfferTopView";

import { withStyles, Grid } from "@material-ui/core";

const styles = theme => ({
  root: {
    //   width: '100%',
    //   marginTop: theme.spacing(3),
    //   overflowX: 'auto',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  /*menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },*/
  /*iconButton: {
    marginLeft: 30,
  },*/
  // TODO add margin to all buttons!
  table: {
    minWidth: 700,
    maxWidth: 1150,
    margin: "0 auto",
  },
  cardTitle: {
    color: "white"
  },
  cardHeader: {
    background: "white",
  },
  porscheHeader: {
    background: "linear-gradient(60deg, #094261, #004665)",
    ...defaultBoxShadow,
    padding: "1rem",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "1.15rem",
  },
  summaryHeader: {
    background: "linear-gradient(60deg, #666666, #666666)",
    ...defaultBoxShadow,
    padding: "1rem",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "1.15rem",
  },
  dealerImage: {
    width: "90%",
    padding: 15,
    margin: "auto"
  }
});

class OfferPublicView extends React.Component {
  render() {
    const { classes } = this.props;
    console.log("OfferView state", this.state);
    console.log("OfferView props", this.props);

    const { cart, series, model, finance, color } = this.props.config;

    if(model === undefined || color === undefined || series === undefined || finance === undefined)
      return <Spinner />;

    return (
      <div className={classes.root} style={{ paddingTop: 84 }}>
        <Grid container style={{maxWidth: 1150, margin: "0 auto"}}>
          <Grid item md={12}><OfferTopView/></Grid>

          <Grid item md={12}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <CollapsibleCard classes={classes} title={<Translate id="final.headers.details"/>} body={
                  <CartComplete cart={cart} model={model} color={color} classes={classes} finance={finance} cash={false}/>
                }/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

OfferPublicView.propTypes = {
  classes: PropTypes.object.isRequired,

  series: PropTypes.object,
  color: PropTypes.object,
  model: PropTypes.object.isRequired,
  finance: PropTypes.object,
  cart: PropTypes.array
};

const mapStateToProps = state => ({
  config: state.config,
  finance: state.config.finance,
});

export default withRouter(withStyles(styles)(
  connect(
    mapStateToProps,
    {  }
  )(OfferPublicView)
));
