import { container } from "assets/jss/material-kit-react";

const defaultStyle = theme => ({
  container,
  title: {
    display: "inline-block",
    //position: "relative",
    marginTop: theme.appBar.height,
    [theme.breakpoints.up("md")]: {
      textAlign: "left"
    }
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px 0 0"
  },
  link: {
    textDecoration: "none"
  },
  textCenter: {
    textAlign: "center"
  },
  content: {
    marginTop: -theme.appBar.height,
    backgroundColor: theme.palette.primary.realwhite
  },
  activeColor: {
    border: "2px solid #666666",
    borderRadius: "50%",
    padding: 1
  },
  actionButton: {
    height: 48,
    width: 48
  },
  changeViewButton: {
    width: theme.buttons.round.width,
    height: theme.buttons.round.width,
    transformStyle: "preserve-3d",
    transition: "transform 0.3s ease-out",
    //transform: "scale(-1, 1)", //(100px, -50%)',
    '&:hover': {
      //color: "red",
      transform: "rotateZ(180deg)",
      transition: "transform 0.6s ease-in",
    }
  },
  colorButton: {
    //backgroundColor: "green",
    //[theme.breakpoints.down("sm")]: {
      width: theme.buttons.round.width,
    //  minWidth: 58
    //}
  },
  showPropertiesButton: {
    width: theme.buttons.round.width,
    height: theme.buttons.round.width
  },
  propTable: {
    backgroundColor: "blue",
    "td" : {
      backgroundColor: "yellow",
    },

  },
  overrides: {
    MuiTable: {
      root: {
        //width: "calc(100% - 20px)",
        width: "inherit",
        position: "absolute",
        bottom: "25px"
        //paddingRight: "40px"
        //backgroundColor: 'transparent',
      }
    },
    MuiTableRow: {
      root: {               //for the body
        height: "32px",
        "&:last-child": {
          borderBottom: "1px solid black",
        },
      },
      head: {               //for the head
        //height: 70,         // smaller row height
      }
    },
    MuiTableCell: {
      root: {               //for the body
        padding: 0,
        borderBottom: "0px",
        "&:last-child": {
          // FIXME paddingRight: 100,
        },
      },
      /*head: {               //for the head
        paddingRight: '28px!important',   // smaller column padding
      }*/
    }
  },
  buttonAccept: {
    backgroundColor: theme.palette.porsche.main,
    color: theme.palette.porsche.contrastText,
    "&:hover": {
      color: theme.palette.porsche.main,
    },
    "&:disabled": {
      backgroundColor: theme.palette.secondary.light
    }
  },
  ModelTitle: {
    fontSize: "1rem"
  },
  modelColorPrice: {
    marginTop: 6
  },
  modelColorSubPrice: {
    fontSize: 13,
    display: "block",
    lineHeight: "0.9rem"
  }
});

export default defaultStyle;
