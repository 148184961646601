import React from 'react';
import PropTypes from 'prop-types';

import { selectArticleSize } from "../../actions/configAction";
import { connect } from "react-redux";

import styles from "assets/jss/material-kit-pro-react/customSelectStyle.js";

import {
  withStyles,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem, FormHelperText
} from "@material-ui/core";

class SizeSelect extends React.Component {
  state = {
    selectedValue: "",
  };

  handleChange = event => {
    //console.debug("SizeSelect handleChange", event.target.value);
    this.setState({ selectedValue: event.target.value });
    this.props.selectArticleSize(this.props.article, this.props.article.sizes.find(size => size.id === event.target.value));
  };

  render() {
    const { classes, article, small } = this.props;
    console.log("SizeSelect", this.props);
    if(small) // TODO verify
      return <FormControl variant="outlined" className={classes.formControl} error={true}>
          <InputLabel ref={ref => { this.InputLabelRef = ref; }} htmlFor="size">Größe</InputLabel>
          <Select
            value={this.state.selectedValue}
            onChange={this.handleChange.bind(this)}
            input={<OutlinedInput labelWidth={this.state.labelWidth} name="size" id="size"/>}>
            {article.sizes.map((s, i) =>
              <MenuItem key={s.id}
                        value={s.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}>
                        {s.size}
              </MenuItem>)}
          </Select>
        </FormControl>;
    else
      return <FormControl  className={classes.selectFormControl} error={!(this.props.size && this.props.size.id)}>
        {/*<InputLabel className={classes.selectLabel} ref={ref => { this.InputLabelRef = ref; }} htmlFor="size">Größe</InputLabel>*/}
        <Select
          value={this.props.size && this.props.size.id}
          onChange={this.handleChange}
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
        >
          {article.sizes.map((s, i) =>
            <MenuItem key={s.id}
                      value={s.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}>{s.size}
            </MenuItem>)}
        </Select>
        <FormHelperText>Größe</FormHelperText>
      </FormControl>;
  }
}

SizeSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired,
  size: PropTypes.object,
  small: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  return {
    size: state.config.sizes[props.article.id]
  };
};

export default withStyles(styles)(connect(mapStateToProps, { selectArticleSize })(SizeSelect));
