import React from "react";
import PropTypes from "prop-types";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import _ from "lodash";
import Article from "./Article";
import Spinner from "../ArticlesList";

import { makeStyles, ListSubheader, List, ListItem, ListItemText, Collapse } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function ArticleListItem(props) {
  const classes = useStyles();

  const { category, articles, subheader, index, open, history, topcat } = props;
  console.debug("ArticleListItem", props);
  if(_.isEmpty(category) || _.isEmpty(articles))
    return  <Spinner />;
  else
  return (
    <List
      component="nav"
      className={classes.root}
      aria-labelledby="nested-list-subheader"
      subheader={<ListSubheader component="div" id="nested-list-subheader">{!_.isEmpty(subheader) && subheader.includes(index) && subheader[index]}</ListSubheader>}
      disablePadding
    >
      <ListItem button onClick={props.click.bind(this, index)}>
        <ListItemText primary={category.name + " (" + articles.length + ")"} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense={true} component="div" disablePadding>
          {articles.map((article, i) =>
            <Article key={"art-" + article.id} article={article} classes={classes} topcat={topcat} history={history} />
          )}
        </List>
      </Collapse>
    </List>
  );

}

ArticleListItem.propTypes = {
  //classes: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  topcat: PropTypes.object.isRequired,
  articles: PropTypes.array,
  subheader: PropTypes.array,
  index: PropTypes.number,
  open: PropTypes.bool,
  click: PropTypes.func.isRequired,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
};

//export default ArticleListItem;