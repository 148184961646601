import React from "react";
import { Translate } from "react-localize-redux";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import DealerSelect from "components/Dealer/DealerSelect";
import { Image } from "components/Image/Image";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { fetchDealers } from "../../actions/configAction";
import { defaultBoxShadow } from "assets/jss/material-kit-react";
import PropTypes from "prop-types";
import Spinner from "../Configurator/Spinner";
import DeliverySelect from "./DeliverySelect";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

const styles = theme => ({
  root: {
    //   width: '100%',
    //   marginTop: theme.spacing(3),
    //   overflowX: 'auto',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  /*menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },*/
  /*iconButton: {
    marginLeft: 30,
  },*/
  // TODO add margin to all buttons!
  table: {
    minWidth: 700,
    maxWidth: 1150,
    margin: "0 auto",
  },
  cardTitle: {
    color: "white"
  },
  cardHeader: {
    background: "white",
  },
  porscheHeader: {
    background: "linear-gradient(60deg, #094261, #004665)",
    ...defaultBoxShadow,
    padding: "1rem",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "1.15rem",
  },
  summaryHeader: {
    background: "linear-gradient(60deg, #666666, #666666)",
    ...defaultBoxShadow,
    padding: "1rem",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "1.15rem",
  },
  dealerImage: {
    width: "90%",
    padding: 15,
    margin: "auto"
  }
});

class DealerCard extends React.Component {

  constructor(props) {
    super(props);
    this.props.fetchDealers();
  }

  render() {
    const { classes, dealers, dealer } = this.props;

    if(dealers.length === 0)
      return <Spinner/>;

    return (
      <Card>
        <CardHeader className={classes.summaryHeader}><Translate id={this.props.editable ? "final.orderdealers" : "final.dealerselected"}/></CardHeader>
        <CardBody>
          {this.props.editable && <DealerSelect classes={classes}/>}
          {dealer && <GridContainer>
            <GridItem xs={6} className={classes.dealerImage}>
              <Image src={dealer.H03_Filename_Logo_4C} width={800}/>
            </GridItem>
            <GridItem xs={12}>
              <strong>{dealer.name}</strong>
            </GridItem>
            <GridItem xs={12} sm={6}>
              {dealer.B08_Straße}<br/>
              {dealer.B06_PLZ}{" "}{dealer.B07_Ort}<br/>
              <Translate id="final.phone"/> {dealer.B12_Telefon}
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Translate id="final.email"/> <a href={"mailto:" + dealer.B13_EMail} target="_blank" rel="noopener noreferrer">{dealer.B13_EMail}</a>
            </GridItem>
          </GridContainer>}
          <h4><Translate id="delivery.title"/></h4>
          {this.props.editable ? <DeliverySelect classes={classes}/> : <Translate id={"delivery.selections." + this.props.delivery }/>}
        </CardBody>
      </Card>
    );
  }
}

DealerCard.propTypes = {
  dealers: PropTypes.array.isRequired,
  dealer: PropTypes.object,
  editable: PropTypes.bool,
  delivery: PropTypes.string,

  fetchDealers: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  dealers: state.config.dealers,
  dealer: state.config.dealer,
  delivery: state.config.offer.delivery
});

export default withRouter(withStyles(styles)(
  connect(
    mapStateToProps,
    { fetchDealers }
  )(DealerCard)
));
