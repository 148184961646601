import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { connect } from "react-redux";
import { setMode } from "../../actions/configAction";
import { ccyFormat, getPaymentText, getSummary, timeFormat } from "../../Utils";

import ArticleSummaryFinance from "../Cart/ArticleSummaryFinance";
import Spinner from "../Configurator/Spinner";

import tooltip from "assets/jss/material-kit-react/tooltipsStyle";

import { withStyles, Table, TableBody, TableCell, TableHead, TableRow, Hidden } from "@material-ui/core";
import { ReactComponent as Logo } from "../../assets/img/porsche_bank.svg";
import { ReactComponent as LogoWhite } from "../../assets/img/porsche_bank_white.svg";

export const styles = theme => ({
  ...tooltip,
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  actions: {
    display: "flex"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    //marginRight: 10,
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  priceColumn: {
    textAlign: "right",
    borderBottom: "none",
    [theme.breakpoints.up("sm")]: {
      paddingRight: 0,
      width: "30%"
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 12,
      width: "20%"
    },
    position: "relative"
  },
  noLine: {
    borderBottom: "none"
  },
  sub: {
    color: theme.palette.secondary.dark
  },
  summaryColumn: {
    fontWeight: 700,
    borderBottom: "none"
  },
  icon: {
    position: "absolute",
    right: 0,
    paddingTop: 3,
    fontSize: "1.1rem"
  },
  iconActivate: {
    color: theme.palette.primary.main
  },
  subArticle: {
    borderBottom: "none",
    color: theme.palette.primary.smokyblack,
    paddingRight: "0!important",
    "& .articlenumber": {
      color: theme.palette.primary.darkgray
    }
  },
  configPaper: {
    padding: 5, borderTopLeftRadius: 0, borderTopRightRadius: 0
  },
  separator: {
    //paddingTop: 16,
    borderTop: `solid 1px ${theme.palette.porsche.main}`,
    //marginTop: 30,
  },
  withBorder: {
    borderBottom: "none",
    borderTop: "1px solid rgba(224, 224, 224, 1)"
  },
  articleCollapse: {
    height: 0
  },
  articleCollapseCell: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: "0!important",
    paddingLeft:0
  },
  articleDescription: {
    paddingLeft: 10
  },
  porscheHeader: {
    backgroundColor: theme.palette.porsche.main
  },
  financeTable: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 36
    }
  }
});

class FinanceInfoDetail extends React.Component {

  handleSwitchMode = category => {
    let key = this.props.categories.find(c => c.id === category).key;
    this.props.setMode(key, !this.props.finance_config.mode[key]);
  };

  getMode = category => {
    let key = this.props.categories.find(c => c.id === category).key;
    return this.props.finance_config.mode[key];
  };

  render() {
    const { classes, cart, model, color, finance, cash } = this.props;
    if (cart === undefined || model === undefined || color === undefined || finance === undefined) {
      return <Spinner />;
    }
    const summary = getSummary(this.props.cart, this.props.finance.items);
    //const finance_summary = getSummaryFinance(this.props.finance.items);

    //console.log("FinanceInfoDetail state", this.state);
    //console.log("FinanceInfoDetail props", this.props);
    //console.log("FinanceInfoDetail summary", summary);
    //console.log("FinanceInfoDetail finance_summary", finance_summary);
    const priceHeader = (cash) ? "Barpreis" : getPaymentText(finance.result.ppy);

    return (
      <Table className={classes.financeTable}>
        <TableHead>
          <Hidden smUp>
            {!cash && <TableRow className={classes.porscheHeader}>
              <TableCell colSpan={2}><LogoWhite height={36} /></TableCell>
            </TableRow>}
            <TableRow>
              <TableCell className={classes.priceColumn} colSpan={2}>{priceHeader}</TableCell>
            </TableRow>
          </Hidden>
          <Hidden smDown>
            <TableRow>
              <TableCell>{!cash && <Logo height={36} />}</TableCell>
              <TableCell className={classes.priceColumn}>{priceHeader}</TableCell>
            </TableRow>
          </Hidden>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{borderBottom: "none"}}>{model.series.brand} {model.name}</TableCell>
            <TableCell style={{borderBottom: "none"}} className={classes.priceColumn}>{ccyFormat(cash ? model.price : finance.vehicle.rate)}</TableCell>
          </TableRow>
          <ArticleSummaryFinance
            //close={this.props.close}
            cart={cart}
            finance={finance}
            summary={summary}
            history={this.props.history}
            //summaryFinance={finance_summary}
            topCategories={this.props.categories}
            classes={classes}
            handleSwitchMode={this.handleSwitchMode.bind(this)}
            getMode={this.getMode.bind(this)}
          />
          {summary.total.time > 0 && (
            <TableRow>
              <TableCell className={classes.withBorder}>Montagekosten ({timeFormat(summary.total.time)})</TableCell>
              <TableCell className={classNames(classes.priceColumn, classes.withBorder)}>
                {ccyFormat(cash ? finance.result.laborTimeEuro : finance.result.laborTimeRate)}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell component="th" scope="row" className={classNames(classes.summaryColumn, classes.withBorder)}>Ihre Traum-Ducati</TableCell>
            <TableCell className={classNames(classes.priceColumn, classes.summaryColumn, classes.withBorder, classes.total)}>
              {ccyFormat(cash ? (summary.total.inkl + model.price + finance.result.laborTimeEuro) : finance.result.monthly)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

FinanceInfoDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
  color: PropTypes.object.isRequired,
  finance: PropTypes.object,
  categories: PropTypes.array.isRequired,
  //parentMapping: PropTypes.object.isRequired,
  cash: PropTypes.bool,

  history: PropTypes.object.isRequired,

  //close: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  model: state.config.model,
  series: state.config.series,
  color: state.config.color,

  cart: state.config.cart,
  categories: state.articles.categories,
  finance: state.config.finance,
  finance_config: state.config.finance_config,
  //parentMapping: state.articles.parentMapping
});

export default withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      { setMode }
    )(FinanceInfoDetail)

);
