import Offer from "../views/Offer";
import OfferView from "../views/Configurator/OfferView";
import EmptyLayout from "../views/Configurator/EmptyLayout";
import ConfigurationStepper from "../views/ConfigurationStepper";

/**
 * These components are embedded in the StandardLayout
 * @type {*[]}
 */
var configuratorRoutes = [
  {
    path: "/series/:series?",
    name: "Select a series",
    component: ConfigurationStepper
  },
  {
    path: "/models/:series?",
    name: "Select a model",
    component: ConfigurationStepper
  },
  {
    path: "/config/:model?/:color?",
    name: "Configurator",
    component: ConfigurationStepper
  },
  {
    path: "/financing/:model?/:color?",
    name: "Configurator",
    component: ConfigurationStepper
  },
  {
    path: "/insurance/:model?/:color?",
    name: "Configurator",
    component: ConfigurationStepper
  },
  {
    path: "/customer/:code?",
    name: "Configurator",
    component: ConfigurationStepper
  },
  {
    path: "/delivery/:code?",
    name: "Configurator",
    component: ConfigurationStepper
  },
  {
    path: "/confirmation/:code?",
    name: "Configurator",
    component: ConfigurationStepper
  },
  {
    path: "/offer/:code?",
    name: "Finalize your configuration",
    component: Offer
  },
  {
    path: "/status/:code?",
    name: "Status of your configuration",
    component: OfferView,
    layout: EmptyLayout
  }
];

export default configuratorRoutes;
