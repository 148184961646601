import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "assets/jss/custom/largeButtonStyle";
import { withStyles, Button, Tab, Fab } from "@material-ui/core";

function LargeIconButton({ ...props }) {
  const {
    classes,
    color,
    round,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    //className,
    icon,
    title,
    mobile,
    ...rest
  } = props;
  const pillsClasses = classNames({
    [classes.pills]: true,
    //[classes.horizontalPills]: false,
    [classes.pillsWithIcons]: true,
    [classes.mobile]: mobile
  });

  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [classes.mobileButton]: mobile//,
    //[className]: className
  });
  if(title)
    return (
        <Tab
          component="span"
          label={title}
          icon={<Fab disabled={disabled} className={btnClasses}>{icon}</Fab>}
          {...rest}
          classes={{
            root: pillsClasses,
            //labelContainer: classes.labelContainer,
            //label: classes.label,
            }}
        />
  )
  else
    return (
      <Button aria-label={title} {...rest} disabled={disabled} component="div">
        <Fab disabled={disabled} className={btnClasses}>{icon}</Fab>
      </Button>
    );
}

LargeIconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "facebook",
    "twitter",
    "google",
    "github",
    "transparent",
    "secondary",
    "porsche"
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  icon: PropTypes.object,
  title: PropTypes.object,
  mobile: PropTypes.bool
};

export default withStyles(styles)(LargeIconButton);
