import React from "react";
import componentsStyle from "assets/jss/material-kit-react/views/components";
import { withStyles } from '@material-ui/core';

class EmptyLayout extends React.Component {
  render() {
    const { classes } = this.props;
    //console.log(this);
    //console.log("EMPTY Children", this.props.children);
    return (
      <div>
        <div
          className={classes.pageHeader}
          style={{
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          {this.props.children}
          {/*
           <GridContainer>
        <h1>Series</h1>
        <GridItem xs={12} sm={12} md={8}>
           {this.props.children}
           </GridItem>
      </GridContainer>
          {this.state.active === "Series" && <BikeSeries name={"Test"} changeActive={this.changeActive.bind(this)} setSeries={this.setSeries.bind(this)} /> }
        */}
        </div>
      </div>
    );
  }
}

export default withStyles(componentsStyle)(EmptyLayout);
