import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.jsx";
import { Badge, Tooltip } from "@material-ui/core";
import { ShoppingCart } from "@material-ui/icons";

class CartIcon extends React.Component {
  render() {
    const { cart, classes } = this.props;

    if(cart.length > 0)
      return (
        <Badge badgeContent={cart.length} color="primary" className={classes.cartBadge}>
        <Button
          color="success"
          justIcon
          round
          className={classes.navLink}
          onClick={this.props.onClick}
        >
          <ShoppingCart />
        </Button>
        </Badge>
      );
    else
      return (
        <Tooltip
          id="cart"
          title="Bitte fügen Sie Artikel hinzu"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
          arrow
        >
          <Button
            color="transparent"
            disabled
            justIcon
            className={classes.navLink}
          >
            <ShoppingCart />
          </Button>
        </Tooltip>
      );
  }
}

CartIcon.propTypes = {
  cart: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  cart: state.config.cart
});

export default connect(mapStateToProps, {})(CartIcon);
