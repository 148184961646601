import React from "react";
import PropTypes from "prop-types";
import { ccyFormat, getPaymentText, timeFormat } from "../../Utils";
import classNames from "classnames";
import { withStyles, Table, TableBody, TableCell, TableHead, TableRow, Hidden, Collapse} from "@material-ui/core";
import { ReactComponent as ClickNRide } from "assets/img/CLICKRIDE_B.svg";
import { DialogSimple } from "../Dialog/DialogSimple";
import { FinanceCredits } from "./FinanceCredits";
import { Translate } from "react-localize-redux";
import Badge from "../Badge/Badge";
import { KeyboardArrowDown } from "@material-ui/icons";
import Button from "../CustomButtons/Button";
import { isWidthDown, withWidth } from "@material-ui/core";

const styles = theme => ({
  progress: {
    margin: theme.spacing(1),
  },
  table: {
    //position: "absolute",
    //bottom: 0,
    //backgroundColor: "red",
    //...theme.table,
    /*overrides: {
      MuiTableCell: {
        root: {
          fontSize: '4rem',
        },
      },
    },
    MuiTableCell: {
      root: {
        backgroundColor: "blue"
      }
    }*/
  },
  topline: {
    borderTop: theme.MuiTableCell,
  },
  price: theme.price,
  total: theme.price.total,
  header: theme.price.header,
  voucher: {
    color: theme.palette.primary.main,
    paddingTop: 0
  },
  vehicle: {},
  vehicleVoucher: {
    borderBottom: "none",
    paddingBottom: "8px"
  },
  withInfo: {
    whiteSpace: "nowrap",
    //paddingRight: "4px!important"
  },
  rowCollapse: {
    height: 0
  },
  cellCollapse: {
    padding: "0!important"
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
});

class FinanceInfo extends React.Component {
  state = {
    expanded: {
      kasko: false,
      articles: false
    }
  };

  toggleExpand = key => {
    this.setState({
      expanded: {
        ...this.state.expanded,
        [key]: !this.state.expanded[key]
      }
    });
  }

  render() {
    //console.debug("FinanceInfo: props", this.props);
    //console.debug("FinanceInfo: state", this.state);
    const { finance, insurance, model, classes, topCategories, width, cart } = this.props;

    if(finance !== undefined && finance.vehicle !== undefined ) {
      let rate = finance.result.mode.bk ? finance.vehicle.rate : 0.0;
      let total = model.price;
      let total_articles_rate = 0.0;
      let total_articles_sum = 0.0;
      let rows = [];
      topCategories.map((category, i) => {
        let items = finance.items.filter(item => item.top_cat === category.id);
        const articles = cart.filter(item => item.top === category.id);
        if(items.length > 0) {
          const rates = items.reduce((accumulator, item) => item.rate + accumulator, 0);
          //console.log(rates);
          rate += rates;
          total_articles_rate += rates;
          const prices = articles.reduce((accumulator, item) => item.price + accumulator, 0);
          total += prices;
          total_articles_sum += prices;
          rows[i] = (
            <TableRow key={category.id} classes={classes.row}>
              <TableCell component="th" scope="row">{category.name}</TableCell>
              <TableCell className={classes.price}>{finance.result.mode[category.key] && ccyFormat(rates)}</TableCell>
              <TableCell className={classes.price}>{ccyFormat(prices)}</TableCell>
            </TableRow>
          );
          return rows[i]; // ??? to avoid warning
        } else return ""; // ??? to avoid warning
      });
      const TOTAL = (insurance) ? rate + insurance.hpv + insurance.mvv + insurance.kbv + insurance.kav : total;

      return (
      <Table className={classNames("meine", classes.table)} style={{...this.props.style}} >
        <TableHead classes={classes.head} >
          <TableRow>
            <TableCell colSpan={2} className={classes.header}>
              {finance.result.mode.bk && <><span onClick={() => this["dialog-info-fin"].handleClickOpen()}>
              {getPaymentText(finance.result.ppy)} {" "} <i className="fas fa-info-circle"/></span>
              <DialogSimple ref={dialog => this["dialog-info-fin"] = dialog} id="dialog-info-fin" title={<Translate id={"finance.dialog." + (isWidthDown("sm", width) ? "shorttitle" : "title")}/>}>
                <FinanceCredits result={finance.result} className={classes.creditsSmall} />
              </DialogSimple></>
            }
            </TableCell>
            <TableCell className={classes.header}>Preis</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {finance.result.discount &&
          <TableRow>
            <TableCell className={finance.result.mode.bk && classes.vehicleVoucher} component="th" scope="row" colSpan={2}>
              Fahrzeugpreis
            </TableCell>
            <TableCell className={classNames(classes.price, finance.result.mode.bk && classes.vehicleVoucher)}>
              {ccyFormat(model.baseprice)}
            </TableCell>
          </TableRow>}
          {finance.result.discount && finance.result.mode.bk &&
            <TableRow>
              <TableCell className={classes.voucher} component="th" scope="row" colSpan={2}>
                <Hidden xsDown>
                  Preisvorteil
                </Hidden>
                <Hidden smUp><i className="fas fa-info-circle"  onClick={() => this["dialog-info-clicknride"].handleClickOpen()}/></Hidden>
                <ClickNRide height={16}  onClick={() => this["dialog-info-clicknride"].handleClickOpen()} />
                <Hidden xsDown><i className="fas fa-info-circle"  onClick={() => this["dialog-info-clicknride"].handleClickOpen()}/></Hidden>
                <DialogSimple ref={dialog => this["dialog-info-clicknride"] = dialog} id="dialog-info-clicknride" title={<ClickNRide height={28}/>}>
                  <Translate id={"info.clicknride"} />
                </DialogSimple>
              </TableCell>
              <TableCell className={classNames(classes.price, classes.voucher)}><strong>{ccyFormat(-model.voucher)}</strong></TableCell>
            </TableRow>}
          <TableRow>
            <TableCell component="th" scope="row" className={"modelname"}>{model.series.brand} {model.name}</TableCell>
            <TableCell className={classes.total}>{ccyFormat(finance.vehicle.rate)}</TableCell>
            <TableCell className={classes.total}>{ccyFormat(model.price)}</TableCell>
          </TableRow>
          {finance.items.length > 0 && <TableRow>
            <TableCell component="th" scope="row">
              <Translate id="stepper.article_single" data={{count:finance.items.length}} />
              <Button onClick={() => this.toggleExpand("articles")}
                      className={classNames(classes.expand, {[classes.expandOpen]: this.state.expanded.articles})}
                      aria-expanded={this.state.expanded.articles}
                      aria-label="Details anzeigen"
                      size="sm"
                      round
                      justIcon
                      color="transparent"
              >
                <KeyboardArrowDown />
              </Button>
            </TableCell>
            <TableCell className={classes.price}>{ccyFormat(total_articles_rate + finance.result.laborTimeRate)}</TableCell>
            <TableCell className={classes.price}>{ccyFormat(total_articles_sum + finance.result.laborTimeEuro)}</TableCell>
          </TableRow>}
          <TableRow className={classes.rowCollapse}>
            <TableCell className={classes.cellCollapse} colSpan={3}>
              <Collapse in={this.state.expanded.articles} timeout="auto" unmountOnExit>
                <Table>
                  {rows}
                  {(finance.result.laborTime > 0) &&
                  <TableRow classes={classes.row}>
                    <TableCell component="th" scope="row">Montagekosten ({timeFormat(finance.result.laborTime)})</TableCell>
                    <TableCell className={classes.price}>{ccyFormat(finance.result.laborTimeRate)}</TableCell>
                    <TableCell className={classes.price}>{ccyFormat(finance.result.laborTimeEuro)}</TableCell>
                  </TableRow>
                  }
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row" >Gesamt</TableCell>
            <TableCell className={classes.total}>{finance.result.mode.bk && ccyFormat(finance.result.monthly)}</TableCell>
            <TableCell className={classes.total}>{ccyFormat(total + finance.result.laborTimeEuro)}</TableCell>
          </TableRow>

          {insurance && insurance.hp && <>
            <TableRow>
              <TableCell component="th" scope="row"><Translate id="insurance.hpv"/></TableCell>
              <TableCell className={classes.price}>{ccyFormat(insurance.hpv)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row"><Translate id="insurance.tax"/></TableCell>
              <TableCell className={classes.price}>{ccyFormat(insurance.mvv)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Translate id={"insurance.kasko"}/>
                <Button onClick={() => this.toggleExpand("kasko")}
                        className={classNames(classes.expand, {[classes.expandOpen]: this.state.expanded.kasko})}
                        aria-expanded={this.state.expanded.kasko}
                        aria-label="Details anzeigen"
                        size="sm"
                        round
                        justIcon
                        color="transparent"
                >
                  <KeyboardArrowDown />
                </Button>
              </TableCell>
              <TableCell className={classes.price}>{ccyFormat(insurance.kbv + insurance.kav)}</TableCell>
              <TableCell></TableCell>
            </TableRow>

            <TableRow className={classes.rowCollapse}>
              <TableCell className={classes.cellCollapse} colSpan={2}>
                <Collapse in={this.state.expanded.kasko} timeout="auto" unmountOnExit>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row"><Translate id={"kaskotype." + insurance.kt}/></TableCell>
                        <TableCell className={classes.price}>{ccyFormat(insurance.kbv)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row"><Translate id="insurance.kasko_zub"/></TableCell>
                        <TableCell className={classes.price}>{ccyFormat(insurance.kav)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
          }
          <TableRow>
            <TableCell component="th" scope="row" ><Translate id="finance.offertotal"/></TableCell>
            <TableCell className={classes.total}><Badge color="porsche">{ccyFormat(TOTAL)}</Badge></TableCell>
            <TableCell className={classes.total}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      );
    } else {
      return <h4>Fahrzeugpreis: {ccyFormat(model.price)}</h4>;
    }
  }
}

FinanceInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  finance: PropTypes.object.isRequired,
  insurance: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  cart: PropTypes.array,

  topCategories: PropTypes.array.isRequired
};

export default withWidth()(withStyles(styles, {withTheme: true})(FinanceInfo));