import React from "react";
import { Translate } from "react-localize-redux";

import DealerSelect from "components/Dealer/DealerSelect";
import { Image } from "components/Image/Image";
import { withRouter } from "react-router-dom";
import { Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { fetchDealers, setDealer } from "../../actions/configAction";
import { defaultBoxShadow } from "assets/jss/material-kit-react";
import PropTypes from "prop-types";
import Spinner from "../Configurator/Spinner";
import DeliverySelect from "./DeliverySelect";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import MapContainer from "../Configurator/Map/MapContainer";

const styles = theme => ({
  root: {
    //   width: '100%',
    //   marginTop: theme.spacing(3),
    //   overflowX: 'auto',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  /*menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },*/
  /*iconButton: {
    marginLeft: 30,
  },*/
  // TODO add margin to all buttons!
  table: {
    minWidth: 700,
    maxWidth: 1150,
    margin: "0 auto",
  },
  cardTitle: {
    color: "white"
  },
  cardHeader: {
    background: "white",
  },
  porscheHeader: {
    background: "linear-gradient(60deg, #094261, #004665)",
    ...defaultBoxShadow,
    padding: "1rem",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "1.15rem",
  },
  summaryHeader: {
    background: "linear-gradient(60deg, #666666, #666666)",
    ...defaultBoxShadow,
    padding: "1rem",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "1.15rem",
  },
  dealerImage: {
    width: "60%",
    padding: 15
    //margin: "auto"
  },
  greeting: {
    textTransform: "initial",
    paddingBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      //paddingLeft: 10,
      //paddingRight: 10,
      marginTop: 64
    }
  },
  spacingContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
});

class DealerWide extends React.Component {

  constructor(props) {
    super(props);

    if (props.offer === undefined && props.match.params.code) {
      props.history.push("/offer/" + props.match.params.code);
    }

    this.props.fetchDealers();
  }

  render() {
    const { classes, dealers, dealer } = this.props;
    //console.debug("Dealer Wide", this.props);
    if(dealers.length === 0)
      return <Spinner/>;

    return (
      <Grid container spacing={2} className={classes.spacingContainer}>
        <Grid item xs={12} className={classes.greeting}>
          <h2><Translate id={this.props.editable ? "final.orderdealers" : "final.dealerselected"}/></h2>
        </Grid>
        <Grid item md={6}>
          <DealerSelect classes={classes}/>
          {dealer && <Grid container>
            <Grid item xs={6} className={classes.dealerImage}>
              <Image src={dealer.H03_Filename_Logo_4C} width={600}/>
            </Grid>
            <Grid item xs={12}>
              <strong>{dealer.name}</strong>
            </Grid>
            <Grid item xs={12} sm={6}>
              {dealer.B08_Straße}<br/>
              {dealer.B06_PLZ}{" "}{dealer.B07_Ort}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Translate id="final.email"/> <a href={"mailto:" + dealer.B13_EMail} target="_blank" rel="noopener noreferrer">{dealer.B13_EMail}</a><br/>
              <Translate id="final.phone"/> {dealer.B12_Telefon}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <h4><Translate id="delivery.title"/></h4>
              <DeliverySelect classes={classes}/>
            </Grid>
          </Grid>}
        </Grid>
        <Grid item xs={12} md={6} style={{minHeight: 450}}>
          {/*<DistanceMatrixService
            options={{
              destinations: [{lat:1.296788, lng:103.778961}],
              origins: [{lng:103.780267, lat:1.291692}],
              travelMode: "DRIVING",
            }}
            callback = {(response) => {console.log(response)}}
          />*/}
          <MapContainer dealer={dealer} dealers={dealers} setDealer={this.props.setDealer} />
        </Grid>
      </Grid>
    );
  }
}

DealerWide.propTypes = {
  dealers: PropTypes.array.isRequired,
  dealer: PropTypes.object,
  offer: PropTypes.object,
  editable: PropTypes.bool,
  delivery: PropTypes.string,

  fetchDealers: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  dealers: state.config.dealers,
  dealer: state.config.dealer,
  offer: state.config.offer,
  delivery: state.config.offer && state.config.offer.delivery
});

export default withRouter(withStyles(styles)(
  connect(
    mapStateToProps,
    { fetchDealers, setDealer }
  )(DealerWide)
));
