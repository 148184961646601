import React from "react";
import { Translate} from "react-localize-redux";
import { ccyFormat, percFormat, dateFormat } from "../../Utils";
import PropTypes from "prop-types";

export const FinanceCredits = props => (
  <div className={props.className}>
    <p>
      <Translate id="credits.infotext" data={{
          fees: ccyFormat(props.result.provision),
          interests: percFormat(props.result.zins),
          effective: percFormat(props.result.effective_zins),
          total: ccyFormat(props.result.total_costs),
          effective_kasko: percFormat(props.result.effective_kasko),
          date: dateFormat(new Date()),
          fixed: props.result.fixed ? "fix" : "variabel"
      }}
      />
    </p>
    <Translate id="credits.disclaimer"/>
  </div>
);

export const FinanceIntroduction = props => (
  <div style={props.style}>
    <h4><Translate id="credits.title"/></h4>
    <p><Translate id="credits.info"/></p>

    <h4><Translate id="credits.reasons.header"/></h4>
  </div>
);

FinanceCredits.propTypes = {
  className: PropTypes.string,
  result: PropTypes.shape({
    provision: PropTypes.number,
    zins: PropTypes.number,
    effective_zins: PropTypes.number,
    total_costs: PropTypes.number,
    effective_kasko: PropTypes.number
  }).isRequired
};
