import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchOffer, fetchDealers, setDealer, fetchCartFinance, submitOffer } from "../../actions/configAction";
import { getSummaryCart, getSummaryFinance } from "../../Utils";
import Spinner from "../../components/Configurator/Spinner";
import { defaultBoxShadow } from "../../assets/jss/material-kit-react";
import { Translate } from "react-localize-redux";
import tooltip from "assets/jss/material-kit-react/tooltipsStyle.jsx";
import {
  withStyles,
  Grid, Backdrop, CircularProgress
} from "@material-ui/core";
import CartDetail from "../../components/Cart/CartDetail";
import FinanceInsuranceCardFinal from "../../components/Finance/FinanceInsuranceCardFinal";
import _ from "lodash";

const styles = theme => ({
  root: {
    //   width: '100%',
    //   marginTop: theme.spacing(3),
    //   overflowX: 'auto',
    flexGrow: 1,
    paddingBottom: 36
  },
  /*grow: {
    flexGrow: 1,
  },*/
  /*menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },*/
  /*iconButton: {
    marginLeft: 30,
  },*/
  // TODO add margin to all buttons!
  table: {
    minWidth: 700,
    maxWidth: 1150,
    margin: "0 auto"
  },
  cardTitle: {
    color: "white"
  },
  cardHeader: {
    background: "white"
  },
  porscheHeader: {
    background: "linear-gradient(60deg, #094261, #004665)",
    ...defaultBoxShadow,
    padding: "1rem",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "1.15rem",
  },
  summaryHeader: {
    background: "linear-gradient(60deg, #666666, #666666)",
    ...defaultBoxShadow,
    padding: "1rem",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "1.15rem"
  },
  dealerImage: {
    width: "90%",
    padding: 15,
    margin: "auto"
  },
  financeTable: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  finalConditions: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    "& p": {
      fontSize: "0.75rem!important"
    }
  },
  ...tooltip,
  greeting: {
    textTransform: "initial",
    paddingBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 64
    }
  },
  spacingContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
});

class OfferFinal extends React.Component {
  state = {
    submit: false,
    loaded: this.props.config.model && this.props.config.offer && this.props.config.finance && this.props.config.sizes
  };

  constructor(props) {
    super(props);

    if ((props.config === undefined || props.config.offer === undefined) && props.match.params.code) {
      props.history.push("/offer/" + props.match.params.code);
    }
  }

  handleComplete() {
    this.setState({
      ...this.state,
      submit: true
    });
    this.props.submitOffer();
  }

  isReady() {
    const { cart, sizes } = this.props.config;
    return cart.filter(e => e.sizes.length > 0 && sizes[e.id] === undefined).length === 0
  }

  subtotal(items) {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("Final componentDidUpdate props", this.props);
    const { cart, sizes, finance, model, offer } = this.props.config;
    // if everything is loaded - or updated
    if (model && offer && finance && sizes &&
      (cart !== prevProps.config.cart
        || sizes !== prevProps.config.sizes
        || model !== prevProps.config.model
        || finance !== prevProps.config.finance)) {
        //console.log("Final componentDidUpdate OK", this.props);
        this.setState({
          ...this.state,
          summary: getSummaryCart(cart, finance.items),
          finance_summary: getSummaryFinance(finance.items),
          loaded: true
        });
    }
    if(model !== prevProps.config.model) {
      this.props.fetchCartFinance();
    }
  };

  isCustomerValid = customer => {
    return customer
      && !_.isEmpty(customer.gender)
      && !_.isEmpty(customer.firstname)
      && !_.isEmpty(customer.lastname)
      && !_.isEmpty(customer.email);
  };

  render() {
    const { classes } = this.props;
    const { model, finance } = this.props.config;
    //console.log("Final state", this.state);
    //console.log("Final Props", this.props);

    if(!this.state.loaded)
      return <Spinner />;
    
   /* const tooltipText = <React.Fragment>
      <Typography color="inherit"><Translate id="tooltips.missing.text"/></Typography>
      <br/>
      <ul>
        {!this.isCustomerValid(customer) && <li><Translate id="tooltips.missing.1"/></li>}
        {(dealer === null || dealer === undefined || delivery === null || delivery === undefined) && <li><Translate id="tooltips.missing.2"/></li>}
        {without_sizes.length > 0 && <li><Translate id="tooltips.missing.3"/></li>}
      </ul>
    </React.Fragment>;
*/
    return (
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={this.state.submit} style={{zIndex: 10000}}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container className={classes.spacingContainer}>
          <Grid item xs={12} md={12} spacing={1} className={classes.greeting}>
            <h3>
              <Translate id="submit.title" />
            </h3>
            <h4>
              <Translate id="submit.header" data={{model: model.name, brand: model.series.brand}}/>
            </h4>
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CartDetail history={this.props.history} editable={true} submit={this.isReady() && this.handleComplete.bind(this)}/>
              </Grid>
              <Grid item xs={12} md={7} className={classes.financeTable}>
                {finance.result.mode.bk && <FinanceInsuranceCardFinal
                  style={{marginBottom: "65px", marginTop: "65px"}}
                  editable={true} />}
              </Grid>
              <Grid item xs={12} md={finance.result.mode.bk ? 5 : 12} className={classes.finalConditions}>
                <Translate id={"final.condition"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/*
        <GridContainer style={{maxWidth: 1150, margin: "0 auto"}}>
          <GridItem xs={12}>
            <h3><Translate id="final.finalize.0" /></h3>
          </GridItem>
          <GridItem md={6} xs={12} sm={6}>
            <Translate id="final.finalize.1" data={{model: model.name, brand: model.series.brand}}/>
          </GridItem>
          <GridItem md={6} xs={12} sm={6}>
            <Translate id="final.finalize.2" data={{model: model.name, brand: model.series.brand}}/>
          </GridItem>

          <GridItem item md={7} xs={12}>
            <LastStepStepper configSizes={countRequireSizes > 0} configComplete={without_sizes.length === 0} model={model} items={cart.length} customer={customer} dealer={dealer} delivery={delivery}/>
          </GridItem>
          <GridItem item md={4} xs={12}>
          </GridItem>
        </GridContainer>
      */}
  </div>
    );
  }
}

OfferFinal.propTypes = {
  classes: PropTypes.object.isRequired,

  config: PropTypes.shape({
    items: PropTypes.array,
    cart: PropTypes.array,
    sizes: PropTypes.array,
    dealer: PropTypes.object,
    customer: PropTypes.object,
    color: PropTypes.object,
    model: PropTypes.object,
    vehicle: PropTypes.object,
    offer: PropTypes.object
  }).isRequired,

  fetchOffer: PropTypes.func.isRequired,
  fetchDealers: PropTypes.func.isRequired,
  fetchCartFinance: PropTypes.func.isRequired,
  setDealer: PropTypes.func.isRequired,
  submitOffer: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  config: state.config
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { fetchOffer, fetchDealers, setDealer, fetchCartFinance, submitOffer }
  )(OfferFinal)
);
