/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React  from "react";
// @material-ui/core components
import ImageGallery from "react-image-gallery";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Assignment from "@material-ui/icons/Assignment";
import Timeline from "@material-ui/icons/Timeline";
import Button from "components/CustomButtons/Button";
import InfoArea from "components/InfoArea/InfoArea";

import style from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import { Grid, Hidden, IconButton, isWidthDown, isWidthUp, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { getImageUrl } from "../Image/Image";
import { datasheet_base_url } from "../../store";
import { DialogActions } from "../Dialog/DialogSimple";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(style);

const detailItems = [
  {
    title: "DATEN",
    content: "Alle Daten und Informationen wurden mit größter Sorgfalt zusammengestellt und geprüft. Eine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der zur Verfügung gestellten Informationen kann nicht übernommen werden.",
    //icon: { Timeline },
    iconColor: "primary"
  },
  {
    title: "GEWICHT TROCKEN",
    content: "Der bei Trockengewicht angegebene Wert entspricht dem Fahrzeuggewicht ohne Flüssigkeiten, Batterie, Bedienungsanleitung und Bordwerkzeug.",
    //icon: { Timeline },
    iconColor: "secondary"
  },
  {
    title: "GEWICHT FAHRFERTIG",
    content: "Die Masse eines Fahrzeugs in fahrbereitem Zustand (Mass in Runing Order – 2.1.1.) wird bestimmt durch die Messung der Masse des unbeladenen Fahrzeugs, das für den normalen Betrieb bereit ist und umfasst die Massen der Flüssigkeiten, der Standardausrüstung gemäß den Spezifikationen des Herstellers, des „Kraftstoffs“ in den Kraftstoffbehältern, die zu mindestens 90% ihres Fassungsvermögens gefüllt sind.",
    //icon: { Timeline },
    iconColor: "secondary"
  },
  {
    title: "PREISE",
    content: "Unverbindlich empfohlene Verkaufspreise inkl. 20% Mehrwertsteuer und Normverbrauchsabgabe (Bei Fahrzeugen).",
    //icon: { Timeline },
    iconColor: "info"
  },
  {
    title: "",
    content: "Änderungen in Preis, Technik und Ausstattung vorbehalten."
  }
];

export default function ProductDialog(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { model, images, width, ...rest } = props;


  const imageData = images && images.map(image => {
    return {
      original: getImageUrl(image.filename, 500),
      thumbnail: getImageUrl(image.filename, 100)
    }
  });

  const properties = <Table>
    <TableBody>
      <TableRow>
        <TableCell component="th" scope="row">Hubraum</TableCell>
        <TableCell align="right">{model.data.displacement} ccm</TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">Drehmoment</TableCell>
        <TableCell align="right">{model.data.torque}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">Leistung</TableCell>
        <TableCell align="right">{Math.ceil(model.data.power * 1.36)} PS ({model.data.power}{" "}kW)</TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">Trockengewicht</TableCell>
        <TableCell align="right">{model.data.weight} kg</TableCell>
      </TableRow>
      <TableRow /*className={classes.noborder}*/>
        <TableCell component="th" scope="row">Sitzhöhe</TableCell>
        <TableCell align="right">{model.data.seatheight} mm</TableCell>
      </TableRow>
      <TableRow /*className={classes.noborder}*/>
        <TableCell component="th" scope="row">Datenblatt</TableCell>
        <TableCell align="right"><a href={datasheet_base_url + model.sku + ".pdf"}>PDF</a></TableCell>
      </TableRow>
    </TableBody>
  </Table>;

  return (
    <><Button justIcon round color="white" onClick={() => setOpen(true)} {...rest}>
        <Assignment />
      </Button>
      <Dialog
          onClose={() => setOpen(false)}
          aria-labelledby={model.sku}
          open={open}
          maxWidth={"md"}
          fullWidth
          fullScreen={isWidthDown("sm", width)}
        >
          <DialogTitle id={model.sku} onClose={() => setOpen(false)}>
            <Hidden xsDown>{model.series.brand}</Hidden> {model.name}
            <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item hidden={isWidthUp("sm", width)} xs={12} >
                {properties}
              </Grid>
              <Grid item xs={12} sm={5} md={5} className={classes.mlAuto}>
                {detailItems.map((item, i) => <InfoArea
                    key={i}
                    //className = { classes.infoArea }
                    title={item.title}
                    description={item.content}
                    icon={Timeline}
                    iconColor={item.iconColor}
                  />
                )}
              </Grid>
              <Grid item hidden={isWidthDown("sm", width)} xs={12} sm={5} md={5} className={classes.mrAuto}>
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  startIndex={0}
                  items={imageData}
                  showThumbnails={true}
                  renderLeftNav={(onClick,disabled) => {
                    return (
                      <button
                        className='image-gallery-left-nav'
                        disabled={disabled}
                        onClick={onClick}
                      />
                    );
                  }}
                  renderRightNav={(onClick,disabled) => {
                    return (
                      <button
                        className='image-gallery-right-nav'
                        disabled={disabled}
                        onClick={onClick}
                      />
                    );
                  }}
                />
                {properties}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} size={"sm"} color="primary">Schließen</Button>
          </DialogActions>
        </Dialog>
    </>
  );
}