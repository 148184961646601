import "core-js/stable";
import "react-app-polyfill/ie11"; // for IE11 compatibility
import React from "react";
import ReactDOM from "react-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import "./assets/scss/material-kit-pro-react.scss";
import "./assets/scss/ducati-icons/style.scss";
import App from "./App";
import store from "./store";
import theme from "./theme";
import { Provider } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { LocalizeProvider } from "react-localize-redux";

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <CssBaseline />
        <LocalizeProvider>
          <App/>
        </LocalizeProvider>
    </Provider>
  </MuiThemeProvider>
  ,
  document.getElementById("root")
);
