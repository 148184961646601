const ducati = {
  light: "#f90005",
  main: "#CC0000",
  dark: "#9d0000",
  contrastText: "#FAFAFA",
  black: "#0F0F0F", // smoky-black

  offwhite: "#FAFAFA",
  smokyblack: "#0F0F0F",
  realwhite: "#FFFFFF",

  lightgray: "#EBEBEB",
  featuregray: "#BBBCBC",
  darkgray: "#666666"
};

export default ducati;