/*eslint-disable*/
import React from "react";
import PDFButton from "components/CustomButtons/PDFButton";
//import Button from "components/CustomButtons/Button.jsx";
import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle";
import DialogCart from "components/Cart/DialogCart";
import { withStyles, List, ListItem, Tooltip } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import { socialLinks } from "../../store";

function HeaderLinks({ ...props }) {
  const { list, listItem, tooltip, navLink, socialIcons, ...classes } = props.classes;
  return (
    <List className={list}>
      <ListItem className={listItem} disableGutters>
        <PDFButton classes={{tooltip, navLink, socialIcons}}/>
      </ListItem>
      <ListItem className={listItem} disableGutters>
        <DialogCart editable={true} />
      </ListItem>
      {socialLinks.map((icon, i) => <ListItem key={i} className={listItem} disableGutters>
        <Tooltip
          id={icon.id}
          title={icon.title}
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: tooltip }}
        >
          <Button
            href={icon.url}
            target="_blank"
            className={navLink}
            rel="noopener noreferrer"
          >
            {icon.icon}
          </Button>
        </Tooltip>
      </ListItem>)}
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
