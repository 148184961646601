import React from "react";
import { Marker, GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from "../../../store";
import Spinner from "../Spinner";

const containerStyle = {
  width: '100%',
  height: '100%'
};

const defaultProps = {
  zoom: 6,
  initialCenter: {
    lat: 47.47,
    lng: 13.13
  },
  centerAroundCurrentLocation: false,
  visible: true
};

function MapContainer(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY
  });
  /*const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds();
    //map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [])
  */
  const { dealer, dealers } = props;
  const center = dealer ? {lat: dealer.lat, lng: dealer.lng} : defaultProps.initialCenter;
  const zoom = dealer ? 14 : defaultProps.zoom;
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      //onLoad={onLoad}
      //onUnmount={onUnmount}
    >
      <Marker /*onClick={this.onMarkerClick}*/ name={'current location'}/>
      {dealers && dealers.map(d =>
        <Marker key={d.dealer_id}
                title={'The marker`s title will appear as a tooltip.'}
                name={d.name}
                position={{ lat: d.lat, lng: d.lng }}
          onClick={() => props.setDealer(d)}
        />)
      }
      {!dealers && dealer && // show only one, if the list is not loaded.
      <Marker
        title={'The marker`s title will appear as a tooltip.'}
        name={dealer.name}
        position={{ lat: dealer.lat, lng: dealer.lon }}/>
      }
      {/*<InfoWindow
        marker={this.state.activeMarker}
        visible={this.state.showingInfoWindow}
        onClose={this.onClose}
      >
        <div>
          <h4>{this.state.selectedPlace.name}</h4>
        </div>
      </InfoWindow>*/}
    </GoogleMap>
  ) : <Spinner />;
}

export default /*React.memo*/(MapContainer);
