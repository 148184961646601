import React from "react";
import Carousel from "react-slick";
import Button from "../CustomButtons/Button.jsx";
import PropTypes from "prop-types";
import ChangeViewIcon from "@material-ui/icons/Replay";
import classNames from "classnames";
import { Image } from "../Image/Image";
import Spinner from "./Spinner";
import { Grid, Button as ColorButton, withStyles, withWidth, isWidthDown } from "@material-ui/core";
import { getBikeImageWidth } from "../../actions/screens";
import defaultStyle from "assets/jss/custom/default";
import ProductDialog from "./ProductDialog";
import LinkIcon from "@material-ui/icons/Link";
import ActionSelectButtons from "./ActionSelectButtons";
import { connect } from "react-redux";
import { fetchModelById } from "../../actions/bikesAction";
import { fetchArticlesByCategory, fetchArticlesByModelAndTopCat, fetchCategories } from "../../actions/articlesAction";
import { setModel, fetchCartFinance } from "../../actions/configAction";
import { showModel } from "../../actions/globalAction";
import FinanceInfo from "../Finance/FinanceInfo";
const button_size = 42;
const base_url = "/articles/";//+ model.id + "/";

class BikeConfig extends React.Component {
  state = {
    current_color: this.props.color || (this.props.model && this.props.model.colors[0]),
    current_view_index: 0,
    openProperties: false,
    finance: undefined
  };

  constructor(props) {
    super(props);
    //console.log("BikeConfig constructor", props);
    if (props.model === undefined) {
      if (props.match.params.model) {
        //console.log("Configurator: DIRECT ACCESS - load data: " + props.match.params.model);
        if (props.match.params.color) {
          //console.log("Configurator: DIRECT ACCESS -SET COLOR TODO: " + props.match.params.color);
          props.fetchModelById(props.match.params.model, props.match.params.color);
        } else {
          props.fetchModelById(props.match.params.model);
        }
      }
    }
    this.props.fetchCategories();
    //this.handleColorChange = this.handleColorChange.bind(this);
    //this.handleLink = this.handleLink.bind(this);
    if(this.props.finance === undefined && this.props.model && this.props.color) {
      this.props.fetchCartFinance();
    }

      //this.handleColorChange = this.handleColorChange.bind(this);
    // if no vehicle finance information is provided: fetch it:
    //this.handleColorChange(props.model, props.model.colors[0]);
  }

  getViewsForColor(color_index) {
    //console.log("getViewsForColor", this.props.model.colors[color_index].images.length);
    return this.props.model.colors[color_index].images.length;
  }

  handleToggleProperties() {
    this.setState((previousState) => { return {...this.state, openProperties: !previousState.openProperties } } );
  }

  handleColorChange(model, color) { // TODO replace .color by .id soon
    const color_index = model.colors.findIndex(c => c.color === color.color);
    this.setState({
      current_color: color,
      current_view_index: this.state.current_view_index % this.getViewsForColor(color_index)
    });
    this.props.setModel(model, color);
    this.props.showModel(model, color);
  }

  handleViewChange() {
    const { current_color, current_view_index } = this.state;
    const color_index = this.props.model.colors.findIndex(c => c.color === current_color.color);
    this.setState({
      current_view_index: (current_view_index + 1) % this.getViewsForColor(color_index)
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.model !== undefined && this.state !== prevState && this['color_slider']) {
      this['color_slider'].slickGoTo(this.state.current_view_index);
    }

    if(prevProps.model !== this.props.model || prevProps.color !== this.props.color) {
      //console.log("BikeConfig componentDidUpdate", this.props);
      this.props.fetchCartFinance();
      this.setState({current_color: this.props.color});
    }
    // when cateogries are loaded, fetch articles in background:
    // trigger either on category or model update
    if(this.props.categories && this.props.model && (prevProps.categories !== this.props.categories || prevProps.model !== this.props.model)) {
      //console.debug("BikeConfig: Categories are loaded, fetch articles for model and category.", this.props.categories);
      this.props.categories.forEach(category => {
        if(category.generic)
          this.props.fetchArticlesByCategory(category.id)
        else
          this.props.fetchArticlesByModelAndTopCat(this.props.model.id, category.id)
      });
    }


    /*if(this.props.finance === undefined)
      this.fetchVehicleFinance();
    else
      this.state.finance = this.props.finance_vehicle;
      */
  }

  render() {
    const { model, cart, classes, className, width } = this.props;
    const { current_color } = this.state;
    //console.log("BikeConfig props", this.props);
    //console.log("BikeConfig state", this.state);
    if(model === undefined || current_color === undefined) {
      return <Spinner/>;
    }

    const settings = {
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      speed: 300,
      fade: true,
      cssEase: "linear",
      className: "colors",
      // if props color is preset then show the selected color:
      initialSlide: this.state.current_view_index // this is often called twice.
    };

    const color_index = model.colors.findIndex(c => c.color === current_color.color);
    const color_views = model.colors[color_index].images;
    const images = color_views.map(i => <Image key={i.id} src={i.filename} width={getBikeImageWidth(width)}/>);

    const bikeActionButtons = <Grid item container xs={12} justifyContent="center" /*className="hide-not-center"*/>
      {/* <Button justIcon round color="transparent"><Favorite style={{ color: "#666666" }} /></Button> */}
      {model.colors.map(col =>
        <ColorButton key={col.id} onClick={() => this.handleColorChange(model, col)}>
          <Image src={col.filename} width={button_size} className={(current_color && current_color.id === col.id) ? classes.activeColor : ""} />
        </ColorButton>
      )}
      {color_views.length > 1 &&
      <Button justIcon round color="white" disabled={color_views.length === 1} onClick={() => this.handleViewChange()} className={classes.actionButton}>
        <ChangeViewIcon className={classes.changeViewButton} />
      </Button>
      }
      <ProductDialog model={model} images={color_views} width={width} className={classes.actionButton} />
      <Button justIcon round color="white" rel="noopener noreferrer" href={"https://www.ducati.at/index.php?id=" + model.website} target="_blank" className={classes.actionButton}>
        <LinkIcon />
      </Button>
    </Grid>;

    if(isWidthDown("sm", width))
      return (
        <div id={"colors-" + model.id} className={classNames({"modelitem":true, [classes.BikeColors]: true, "models": true})}>
          <Grid container direction="row">
            <Grid item xs={12} md={12} className={classes.title}>
              <h1>{model.name}</h1>
              <h2>{model.colors[color_index].name}</h2>
            </Grid>
            <Grid item xs={10} md={12}>
              <Carousel ref={(slider) => this['color_slider'] = slider} {...settings} >{images}</Carousel>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container>
                {bikeActionButtons}
                <Grid container item alignItems="flex-start" justifyContent="center" xs={12}>
                  <ActionSelectButtons
                    baseUrl={base_url}
                    //size={}
                    categories={this.props.categories}
                    articles={this.props.articles}
                    history={this.props.history}
                    classes={classes}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {this.props.finance && this.props.categories &&
                  <FinanceInfo
                    finance={this.props.finance}
                    insurance={this.props.insurance}
                    model={model}
                    cart={cart}
                    topCategories={this.props.categories}
                  />
                }
              </Grid>
            </Grid>
          </Grid>
        </div>);
    else
      return <div id={"colors-" + model.id} className={classNames({"models":true, [className]:true})}>
          <Grid container>
            <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-end" xs={6} lg={7}>
              <h1>{model.name}</h1>
              <h2>{current_color.name}</h2>
            </Grid>
            <Grid container item alignItems="flex-start" justifyContent="center" xs={6} lg={5}>
              <ActionSelectButtons
                baseUrl={base_url}
                categories={this.props.categories}
                articles={this.props.articles}
                history={this.props.history}
                size={"lg"}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ position: "relative", height: "calc(100%-80px)" }}>
            <Grid item md={6} lg={6} className="fade-not-center" justifyContent="center">
              <Carousel ref={(slider) => this['color_slider'] = slider} {...settings} >{images}</Carousel>
              {bikeActionButtons}
            </Grid>
            <Grid item container md={6} lg={5} alignItems="flex-end">
              {this.props.finance && this.props.categories &&
              <FinanceInfo
                finance={this.props.finance}
                insurance={this.props.insurance}
                model={model}
                cart={cart}
                topCategories={this.props.categories}
              />
              }
            </Grid>
          </Grid>
        </div>;
  }
}

BikeConfig.propTypes = {
  classes: PropTypes.object.isRequired,
  series: PropTypes.object,
  model: PropTypes.object,
  color: PropTypes.object,
  select: PropTypes.func,
  cart: PropTypes.array,

  categories: PropTypes.array,
  articles: PropTypes.object,

  // allow just a color change of an already selected model (by presetting the color
  setModel: PropTypes.func.isRequired,
  showModel: PropTypes.func.isRequired,
  fetchModelById: PropTypes.func.isRequired,
  fetchCartFinance: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  fetchArticlesByCategory: PropTypes.func.isRequired,
  fetchArticlesByModelAndTopCat: PropTypes.func.isRequired,

  finance_vehicle: PropTypes.object,

  overrideContent: PropTypes.element
};

const mapStateToProps = state => ({
  series: state.config.series,
  model: state.config.model,
  color: state.config.color,
  cart: state.config.cart,
  categories: state.articles.categories,
  articles: state.articles.articles,
  finance: state.config.finance,
  insurance: state.config.insurance,
  // calculated props:
  configurationValid: state.global.configurationValid,
  finished: state.config.status !== undefined && state.config.status >= 2,
});

export default connect(
  mapStateToProps,
  {
    fetchModelById,                 // 1
    fetchCartFinance,               // 2
    fetchCategories,                // 3
    fetchArticlesByCategory,        // 4
    fetchArticlesByModelAndTopCat,  // 5
    setModel,
    showModel,
  }
)(withWidth()(withStyles(defaultStyle)(BikeConfig)));