export const FETCH_ARTICLES = "FETCH_ARTICLES";
export const FETCH_ARTICLE_SIZES = "FETCH_ARTICLE_SIZES";
export const FETCH_ACCESSORIES_CATEGORIES = "FETCH_ACCESSORIES_CATEGORIES";

export const FETCH_OUTFIT_CATEGORIES = "FETCH_OUTFIT_CATEGORIES";
export const FETCH_OUTFIT = "FETCH_OUTFIT";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const SELECT_ARTICLE = "SELECT_ARTICLE";
export const FETCH_PACKAGES = "FETCH_PACKAGES";

export const FETCH_SERIES = "FETCH_SERIES";
export const FETCH_BY_SERIES = "FETCH_BY_SERIES";
export const FETCH_MODELS = "FETCH_MODELS";
export const FETCH_MODEL_IMAGES = "FETCH_MODEL_IMAGES";

export const FETCH_CART_FINANCE = "FETCH_CART_FINANCE";
export const MODIFY_FINANCING = "MODIFY_FINANCING";
export const UPDATE_FINANCING = "UPDATE_FINANCING";
export const MODIFY_INSURANCE = "MODIFY_INSURANCE";
export const UPDATE_INSURANCE = "UPDATE_INSURANCE";
export const SET_MODE = "SET_MODE";
export const SELECT_SIZE = "SELECT_SIZE";

export const SET_SERIES = "SET_SERIES";
export const SET_MODEL = "SET_MODEL";
export const SET_COLOR = "SET_COLOR";
export const RESET_SERIES = "RESET_SERIES";
export const RESET_MODEL = "RESET_MODEL";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const REMOVE_ARTICLE = "REMOVE_ARTICLE";
export const SAVE_OFFER = "SAVE_OFFER";
export const FETCH_OFFER = "FETCH_OFFER";
export const FETCH_DEALERS = "FETCH_DEALERS";
export const SET_DEALER = "SET_DEALER";
export const SET_CUSTOMER = "SET_CUSTOMER";

export const TOGGLE_FINANCING_VIEW = "TOGGLE_FINANCING_VIEW";
export const TOGGLE_INSURANCE_VIEW = "TOGGLE_INSURANCE_VIEW";
export const TOGGLE_FINANCING_MODE = "TOGGLE_FINANCING_MODE";
export const TOGGLE_INSURANCE_MODE = "TOGGLE_INSURANCE_MODE";
export const TOGGLE_IDD_VIEW = "TOGGLE_IDD_VIEW";
export const TOGGLE_ARTICLES_VIEW = "TOGGLE_ARTICLES_VIEW";
export const FINISH_IDD = "FINISH_IDD";
export const SHOW_SERIES = "SHOW_SERIES";
export const SHOW_MODEL = "SHOW_MODEL";

export const ERROR = "ERROR";
export const FATAL_ERROR = "FATAL_ERROR";
export const ERROR_CLEAR = "ERROR_CLEAR";
export const ERROR_FETCHING_OFFER = "ERROR_FETCHING_OFFER";

export const SET_STATUS = "SET_STATUS";
export const SET_SCREEN = "SET_SCREEN";
export const SET_DELIVERY = "SET_DELIVERY";
export const SET_NEWSLETTER = "SET_NEWSLETTER";