import React from "react";
import { Button, Hidden, List, ListItem } from "@material-ui/core";
import { socialLinks } from "../../store";

export default function FooterLinks(props) {
  return (<>
    <List>
      {props.links.map((link, i) =>
        <ListItem key={i} className={props.classes.inlineBlock} disableGutters>
          <a
            href={link.link}
            className={props.classes.block}
            target={(link.blank? "_blank": "")}
            rel="noopener noreferrer"
          >
            {link.text}
          </a>
        </ListItem>
      )}
    </List>
    <Hidden smUp>
      <List>
        {socialLinks.map((icon, i) => <ListItem key={i} className={props.classes.inlineBlock}>
          <Button //justIcon
              href={icon.url}
              //className={props.classes.iconlarge}
              target={"_blank"}
              title={icon.title}
              size="large"
              rel="noopener noreferrer"
          >
              {icon.icon}
          </Button>
        </ListItem>)}
      </List>
    </Hidden>
    </>
  );
}
