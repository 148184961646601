import React from "react";
import { Translate } from "react-localize-redux";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core";
import { Check, CheckBoxOutlineBlank } from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-kit-react/customCheckboxRadioSwitch";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setNewsletter } from "../../actions/configAction";

class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.newsletter
    };
  }

  handleToggle() {
    this.setState({
      ...this.state,
      checked: !this.state.checked
    });
    this.props.setNewsletter(!this.state.checked);
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem md={12}>
          <h4><Translate id="newsletter.title"/></h4>
        </GridItem>
        <GridItem md={12}>
          <p><Translate id="newsletter.text"/></p>
        </GridItem>
        <GridItem md={12}>
            <FormControlLabel
                classes={{
                  label: classes.label
                }}
                control={
                  <Checkbox
                    tabIndex={-1}
                    onClick={() => this.handleToggle()}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<CheckBoxOutlineBlank className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                    checked={this.state.checked}
                  />
                }
                label={<Translate id="newsletter.accept"/>}
              />
        </GridItem>
        <GridItem md={12}>
          <p><Translate id="newsletter.dataprotect"/></p>
        </GridItem>
        {/*<GridItem xs={6} sm={4} md={3}>
          <Button color="secondary" disabled><Translate id="newsletter.button"/></Button>
        </GridItem>*/}
      </GridContainer>
    );
  }
}

Newsletter.propTypes = {
  newsletter: PropTypes.bool,
  setNewsletter: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  newsletter: state.config.newsletter
});

export default withStyles(customCheckboxRadioSwitch)(connect(mapStateToProps, { setNewsletter })(Newsletter));
