import {
  TOGGLE_FINANCING_MODE,
  TOGGLE_FINANCING_VIEW,
  TOGGLE_INSURANCE_VIEW,
  TOGGLE_INSURANCE_MODE,
  TOGGLE_ARTICLES_VIEW,
  ERROR_CLEAR,
  SET_SCREEN, SHOW_SERIES, SHOW_MODEL
} from "./types";
import { fetchCartFinance } from "./configAction";

export const toggleFinancingView = () => dispatch => {
  dispatch({
    type: TOGGLE_FINANCING_VIEW
  });
  dispatch(fetchCartFinance());
};

export const toggleInsuranceView = () => dispatch => {
  dispatch({
    type: TOGGLE_INSURANCE_VIEW
  });
};

export const toggleFinancingMode = (mode = undefined) => dispatch => {
  dispatch({
    type: TOGGLE_FINANCING_MODE,
    mode: mode
  });
};

export const toggleInsuranceMode = () => dispatch => {
  dispatch({
    type: TOGGLE_INSURANCE_MODE
  });
};

export const toggleArticlesView = () => dispatch => {
  dispatch({
    type: TOGGLE_ARTICLES_VIEW
  });
};

export const clearError = () => dispatch => {
  dispatch({
    type: ERROR_CLEAR
  });
};

export const setScreen = width => dispatch => {
  dispatch({
    type: SET_SCREEN,
    width: width
  });
};

export const showSeries = series => dispatch => {
  document.title = series.name + " - Ducati Configurator";
  dispatch({
    type: SHOW_SERIES,
    series: series
  });
};

export const showModel = (model, color) => dispatch => {
  var col = undefined;
  if(color !== undefined) {
    col = model.colors.find(c => c.color === color.color);
  }
  if(col === undefined) {
    col = model.colors[0]; // set first color as default.
  }
  document.title = model.name + " " + col.name + " - Ducati Configurator";
  dispatch({
    type: SHOW_MODEL,
    model: model,
    color: col.color
  });
};
