import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchArticlesByModelAndTopCat } from "../../../actions/articlesAction";
// core components
import ArticlesList from "../ArticlesList";
import Spinner from "../../Configurator/Spinner";
import { Translate } from "react-localize-redux";

export const TOP_CATEGORY_KEY = "ac";

class AccessoriesList extends React.Component {
  handleClick(e) {
    this.props.history.push("/select");
  }

  render() {
    if(this.props.articles === undefined || this.props.category.sub === undefined) {
      return <Spinner/>;
    } else {
      if(this.props.articles.length === 0) {
        return <div style={{textAlign:"center"}}><Translate id={"list.emptymodel"}/></div>;
      }
      else {
        return (
          <div>
            <ArticlesList categories={this.props.category.sub} articles={this.props.articles}
                          history={this.props.history} topcat={this.props.category}/>
          </div>
        );
      }
    }
  }
}

AccessoriesList.propTypes = {
  fetchArticles: PropTypes.func.isRequired,

  articles: PropTypes.array,
  model: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  const topcat = state.articles.categories.find(
    cat => cat.key === TOP_CATEGORY_KEY
  );
  return (
    { articles: state.articles.articles[topcat.id],
      model: state.config.model,
      category: topcat
    });
}

export default connect(
  mapStateToProps,
  { fetchArticles: fetchArticlesByModelAndTopCat }
)(AccessoriesList);
