import React from 'react';
import PropTypes from 'prop-types';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArticleListItem from "./Items/ArticleListItem";
import _ from "lodash";

import Spinner from "../Configurator/Spinner";

import { withStyles, List, ListItem, ListItemText, Collapse } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: "100%",
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class ArticlesSubList extends React.Component {
  state = {
    opencat: [true],
    opensubcat: [true],
    filtered: undefined
  };

  handleCategoryClick = i => {
    this.setState(() => {
      const opencat = new Array(i);
      opencat[i] = !this.state.opencat[i];
      return { opencat };
    });
  };

  handleSubCategoryClick = i => {
    this.setState(() => {
      const opensubcat = new Array(i);
      opensubcat[i] = !this.state.opensubcat[i];
      return { opensubcat };
    });
  };

  filter() {
    const { articles, categories } = this.props;
    if (
      articles !== undefined && !_.isEmpty(articles) &&
      categories !== undefined && !_.isEmpty(categories)
    ) {
      //console.log("filter articles by category", this.props);
      var cat_accs = [];
      categories.map(cat => {
        //console.log("filter cat", cat);
        cat_accs[cat.id] = articles.filter(article => article.category.id === cat.id);
        return cat.sub.map(subcat => {
          return (cat_accs[subcat.id] = articles.filter(
            article => article.category.id === subcat.id
          ));
        });
      });
      this.setState({ filtered: cat_accs });
    }
  }

  componentDidMount() {
    this.filter();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // new update - modified input data on ArticleList
    if (this.props.articles !== prevProps.articles || this.props.categories !== prevProps.categories) {
      this.filter();
    }
  }

  render() {
    const { classes, categories, articles, topcat } = this.props;

    if (_.isEmpty(categories) ||_.isEmpty(articles) || this.state.filtered === undefined)
      return <Spinner />;

    return (
      <div>
        {categories.map((category_header, i) =>
          <div key={"par-" + i}>
            <ListItem button onClick={this.handleCategoryClick.bind(this, i)}>
              <ListItemText primary={category_header.name} />
              {this.state.opencat[i] ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            {this.state.opencat[i] &&
            <Collapse in={this.state.opencat[i]} timeout="auto">
              <List className={classes.root}>
                {categories[i].sub.map((category, j) => {
                  var arts = this.state.filtered[category.id];
                  // or filter directly here: arts = this.props.articles.filter(a => a.category_id === category.id);
                  return (arts.length > 0) &&
                    <ArticleListItem
                    key={category.id}
                    open={this.state.opensubcat[j]}
                    category={category}
                    articles={arts}
                    topcat={topcat}
                    click={this.handleSubCategoryClick}
                    index={j}
                    history={this.props.history}
                  />
                  }
                )}
              </List>
            </Collapse>
            }
          </div>
        )}
      </div>
    );
  }
}

ArticlesSubList.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.array,
  topcat: PropTypes.object,
  articles: PropTypes.array,
  subheader: PropTypes.array,
  sublevel: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
};

export default withStyles(styles)(ArticlesSubList);