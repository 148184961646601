import React from "react";


class FooterContainer extends React.Component {
  render() {
    return <div className="footer" style={{ paddingBottom: 70 }}>{this.props.children}</div>;
  }
}

export default FooterContainer;
