import React from "react";
import { Translate } from "react-localize-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchDealers, setDealer } from "../../actions/configAction";

import { InputLabel, Select, MenuItem, FormControl, FormHelperText } from "@material-ui/core";
import Spinner from "../Configurator/Spinner";

class DealerSelect extends React.Component {
  constructor(props) {
    super(props);
    this.props.fetchDealers();
  }

  handleSelectDealer = event => {
    this.props.setDealer(this.props.dealers.find(dealer => dealer.id === event.target.value));
  };

  render() {
    const { dealer, dealers, predefined, classes } = this.props;
    console.log("DealerSelect", this.props);

    if(dealers === undefined || dealers.length === 0)
      return <Spinner/>

    return (
      <FormControl className={classes.formControl} error={dealer === 0}>
        <InputLabel htmlFor="dealer-simple"><Translate id="hints.dealerselect"/></InputLabel>
        <Select
          value={dealer !== undefined ? dealer.id : ""}
          onChange={this.handleSelectDealer.bind(this)}
          inputProps={{
            name: 'dealer',
            id: 'dealer-simple',
          }}
          error={dealer === undefined}
          style={{ width: "100%" }}
          disabled={predefined}
        >
          {dealers.map((d, i) =>
            <MenuItem key={d.id} value={d.id}>
              {d.name}, {d.B07_Ort} ({d.B10_Bundesland_Kurz})
            </MenuItem>
          )}
        </Select>
        <FormHelperText><Translate id="hints.dealerselect"/></FormHelperText>
      </FormControl>
    );
  }
}

DealerSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  dealer: PropTypes.object,
  dealers: PropTypes.array.isRequired,
  predefined: PropTypes.bool.isRequired,
  fetchDealers: PropTypes.func.isRequired,
  setDealer: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  dealer: state.config.dealer,
  dealers: state.config.dealers,
  predefined: state.config.dealerPredefined
});

export default connect( mapStateToProps, { fetchDealers, setDealer })(DealerSelect);
