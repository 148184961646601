import React from "react";

import { Translate } from "react-localize-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SnackbarContent from "../Snackbar/SnackbarContent";

class Confirmation extends React.Component {
  render() {
    const { classes, offer } = this.props;
    return (
      <SnackbarContent
        message={<span><Translate id={"status." + offer.status}/></span>}
        color="success"
        icon="info_outline"
        classes={classes}
      />
    );
  }
}

Confirmation.propTypes = {
  classes: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  customer: state.config.customer,
  offer: state.config.offer
});

export default connect(mapStateToProps, { })(Confirmation);
