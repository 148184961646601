import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  createTheme,
  MuiThemeProvider,
  withStyles,
  withWidth
} from "@material-ui/core";
import defaultStyle from "assets/jss/custom/default";
import theme from "../../theme";

const desktopTheme = createTheme(theme, {
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiTable: {
      root: {
        [theme.breakpoints.up("sm")]: {
          width: "inherit",
          position: "absolute",
          bottom: "25px"
        }
      }
    },
    MuiTableRow: {
      root: {
        [theme.breakpoints.up("sm")]: {
          height: "32px",
          "&:last-child": {
            borderBottom: "1px solid black"
          }
        }
      }
    },
    MuiTableCell: {
      root: {
        [theme.breakpoints.up("sm")]: {
          padding: 0,
          borderBottom: "0px",
          "&:last-child": {
            // FIXME paddingRight: 100,
          }
        }
      }
    }
  }
});

const themeFinal = createTheme(theme, {
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiTable: {
      root: {
        width: "100%", // "calc(100% - 20px)",
        position: "absolute",
        bottom: "80px",
        [theme.breakpoints.up("md")]: {
        //  backgroundColor: "yellow"
        }
      }
    },
    MuiTableRow: {
      root: {
        //for the body
        height: "32px",
        "&:last-child": {
          borderBottom: "1px solid black"
        }
      }
    },
    MuiTableCell: {
      root: {
        //for the body
        padding: 0,
        borderBottom: "0px",
        "&:last-child": {
          paddingRight: 1
        }
      }
    }
  }
});

const PropertiesTable = props => {
  const { model } = props;
  const currentTheme = props.final ? themeFinal : desktopTheme;

  const info = (
    " "/*<i className="fas fa-info-circle grey" onClick={() => toggle()} />*/
  );

  return (
    <React.Fragment>
    <MuiThemeProvider theme={currentTheme}>
        <Table className={"properties"}>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">Hubraum</TableCell>
              <TableCell align="right">{model.data.displacement} ccm</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">Drehmoment {info}</TableCell>
              <TableCell align="right">{model.data.torque}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">Leistung {info}</TableCell>
              <TableCell align="right">{Math.ceil(model.data.power * 1.36)} PS ({model.data.power}{" "}kW)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">Trockengewicht {info}</TableCell>
              <TableCell align="right">{model.data.weight} kg</TableCell>
            </TableRow>
            <TableRow /*className={classes.noborder}*/>
              <TableCell component="th" scope="row">Sitzhöhe</TableCell>
              <TableCell align="right">{model.data.seatheight} mm</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      {/*<DetailConditions />*/}
      </MuiThemeProvider>
    </React.Fragment>
  );
};

PropertiesTable.propTypes = {
  model: PropTypes.object.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired,
  final: PropTypes.bool
};

export default withWidth()(
  withStyles(defaultStyle(desktopTheme))(PropertiesTable)
);
