import React from "react";
import PropTypes from "prop-types";

import { ccyFormat, timeFormat } from "../../Utils";
import classNames from "classnames";
import { TableCell, Table, TableRow, TableBody, Collapse, Tooltip, Box } from "@material-ui/core";
import Button from "../CustomButtons/Button";
import { Translate } from "react-localize-redux";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import { Add, KeyboardArrowDown } from "@material-ui/icons";

class ArticleSummaryDetails extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    //console.log("ArticleSummaryDetails", this.props);
    const { classes, category, items, cart, handleSwitchMode, finance, summary, summaryFinance } = this.props;

    return (
      <React.Fragment key={category.id}>
        {/*<Table>
          <TableBody className={classes.head}>*/}
            <TableRow>
              <TableCell className={classes.withBorder}>
                <Box display="flex" flexDirection="row">
                  <Box display="flex" justifyContent="flex-start" alignItems="center" flexDirection="row">
                      <Button aria-label={<Translate id={"buttons.shopmore"} />} onClick={() => this.props.history.push("/articles/" + category.id)} size={"sm"} round justIcon color="secondary">
                        <AddIcon />
                      </Button>
                    <Button onClick={this.handleExpandClick}
                            className={classNames(classes.expand, {[classes.expandOpen]: this.state.expanded})}
                            aria-expanded={this.state.expanded}
                            aria-label="Details anzeigen"
                            size="sm"
                            round
                            justIcon
                            color="transparent"
                    >
                      <KeyboardArrowDown />
                    </Button>
                  </Box>
                  <Box display="flex" justifyContent="flex-start" alignItems="center" flexGrow={1} className={classes.articleDescription}>
                    <Translate id="list.articles" data={{name:category.name, count:items.length, totals:ccyFormat(summary)}} />
                  </Box>
                  <Box display="flex" alignItems="center">
                    {this.props.getMode(category.id) ?
                        <Tooltip
                          id="cash"
                          title={`Ändere von Finanzierung auf Barzahlung für ${category.name} Artikel`}
                          placement={window.innerWidth > 959 ? "bottom" : "left"}
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Button onClick={handleSwitchMode.bind(this, category.id)} size={"sm"} round justIcon color="porsche">
                            <CheckIcon />
                          </Button>
                        </Tooltip>
                      :
                        <Tooltip
                          id="financing"
                          title={`Inkludiere Finanzierung für ${category.name} Artikel`}
                          placement={window.innerWidth > 959 ? "bottom" : "left"}
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Button onClick={handleSwitchMode.bind(this, category.id)} size="sm" round justIcon color="primary">
                            <Add />
                          </Button>
                        </Tooltip>
                    }
                  </Box>
                </Box>
              </TableCell>
              {/*<TableCell className={classNames(classes.priceColumn, classes.withBorder)}>
                {!this.props.cash && "("}{ccyFormat(summary)}{!this.props.cash && ")"}
              </TableCell>*/}
              {!this.props.cash &&
              <TableCell className={classNames(classes.priceColumn, classes.withBorder)}>
                  {this.props.getMode(category.id) ? ccyFormat(summaryFinance) : "Barzahlung"}
                </TableCell>
              }
            </TableRow>
            {/*</TableBody>
        </Table>*/}
        <TableRow className={classes.articleCollapse}>
          <TableCell className={classes.articleCollapseCell} colSpan={2}>
            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {items.map(item => {
                      const article = cart.find(a => a.id === item.id);
                      return (
                        <TableRow key={article.id}>
                          <TableCell className={classes.subArticle}>{article.description}
                            {!this.props.cash && <span><br/>{ccyFormat(article.priceincl)}</span>}
                            {(article.labor > 0) && <span className="articlenumber"><br/><Translate id="cart.addlabor" data={{time: timeFormat(article.labor)}} /></span>}
                          </TableCell>
                          <TableCell className={classNames(classes.priceColumn)}>
                            {!this.props.cash && ccyFormat(finance.items.find(i => i.id === article.id).rate)}
                            {this.props.cash && ccyFormat(article.priceincl)}
                          </TableCell>
                        </TableRow>
                      )}
                    )}
                  </TableBody>
                </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

ArticleSummaryDetails.propTypes = {
  category: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  finance: PropTypes.object.isRequired,
  summary: PropTypes.number.isRequired,
  summaryFinance: PropTypes.number.isRequired,
  cash: PropTypes.bool,

  handleSwitchMode: PropTypes.func.isRequired,
  getMode: PropTypes.func.isRequired
};

export default ArticleSummaryDetails;
