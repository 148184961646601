import React from "react";
import defaultStyle from "assets/jss/custom/default";
import Header from "../../components/Header/Header";

import Footer from "../../components/Footer/Footer";
import FooterContainer from "../../components/Footer/FooterContainer";
import { withStyles } from "@material-ui/core";
import { ReactComponent as Logo } from "assets/img/Ducati_Shield_Rot.svg";
import DialogCart from "../../components/Cart/DialogCart";

class MobileLayout extends React.Component {
  componentDidMount() {
    document.title = "Ducati Configurator - Serien";
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <React.Fragment>
        <Header
          brand={<Logo height={48} width={48} id="logo" title="Ducati" onClick={() => this.props.history.replace("/")} />}
          rightLinks={<DialogCart editable={true} />}
          color="transparent"
          {...rest}
        />
        <div className={classes.content}>{this.props.children}</div>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultStyle)(MobileLayout);
