import React from "react";
import PropTypes from "prop-types";
import { withStyles, CircularProgress, LinearProgress } from '@material-ui/core';

const styles = theme => ({
  progress: {
    margin: theme.spacing(1),
  },
});

function Spinner(props) {
  const { classes, type } = props;
  let spinner;
  switch(type) {
    case "linear":
      spinner = <LinearProgress className={classes.progress} />;
      break;
    default:
      spinner = <CircularProgress className={classes.progress} color="secondary" size="20px" />;
      break;
  }

  return <div style={{ textAlign: "center"} }>{spinner}</div>;
}

Spinner.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string
};

export default withStyles(styles)(Spinner);