import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { connect } from "react-redux";
import {
  selectFetchArticle,
  fetchArticlesByModelAndTopCat,
  fetchArticlesByCategory
} from "../actions/articlesAction";
import { fetchModelById } from "../actions/bikesAction";
import ArticleCardsGrid from "../components/Articles/ArticleCardsGrid";
import ArticlesListLeft from "../components/Articles/ArticlesListLeft";
import { withRouter } from "react-router-dom";
import Spinner from "../components/Configurator/Spinner";
import { addToCart, removeFromCart } from "../actions/configAction";

import { TOP_CATEGORY_KEY as CAT_ACCESSORIES } from "../components/Articles/Lists/AccessoriesList";
import { TOP_CATEGORY_KEY as CAT_PACKAGES } from "../components/Articles/Lists/PackagesList";
import { TOP_CATEGORY_KEY as CAT_CLOTHING } from "../components/Articles/Lists/OutfitList";
import DialogCart from "../components/Cart/DialogCart";

import {
  withStyles,
  Drawer,
  AppBar,
  Toolbar,
  Button,
  Typography,
  Divider,
  IconButton, Hidden, withWidth, isWidthUp, isWidthDown, Badge
} from "@material-ui/core";
import { buttons } from "../components/Configurator/ActionSelectButtons";
import LargeIconButton from "../components/CustomButtons/LargeIconButton";
import { SkipNext } from "@material-ui/icons";

const drawerWidth_xs = 180;
const drawerWidth_md = 240;

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    flexGrow: 1,
  },
  appBarShift: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth_md}px)`,
      marginLeft: drawerWidth_md
    },
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${drawerWidth_xs}px)`,
      marginLeft: drawerWidth_xs
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  button: {
    margin: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: 10
    }
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20
  },
  menuButtonLast: {
    marginLeft: 12
  },
  hide: {
    display: 'none',
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth_md
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth_xs
    },
    flexShrink: 0
  },
  drawerPaper: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth_md
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth_xs
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginLeft: -drawerWidth_md
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: -drawerWidth_xs
    },
    marginTop: 64 // TODO find the variable, where the height is defined.
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0
  },
  CategoryButton: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 4px",
      minWidth: 45
    }
  }
});

class ArticlesGridView extends React.Component {
  state = {
    open: undefined,
    preset: false,
    show_categories: undefined,
    sub_cat_id: undefined,
    top_cat_id: undefined,

    // some constants:
    cat_clothing: this.props.categories.find(e => e.key === CAT_CLOTHING),
    cat_accessories: this.props.categories.find(e => e.key === CAT_ACCESSORIES),
    cat_packages: this.props.categories.find(e => e.key === CAT_PACKAGES),

    flattened: this.flattenCategories()
  };

  constructor(props) {
    super(props);
    //console.log("ArticlesGridView constructor", props);
    if(this.props.model) {
      if(this.props.match.params.top_cat) {
        const selected_cat = this.props.categories.find(e => e.id === this.props.match.params.top_cat);
        const updateState = this.getStateForCategory(selected_cat);
        this.state = {
          ...this.state,
          ...updateState
        };
      }

      // TODO probably fetch articles if this view is called directly
      //this.props.fetchArticlesByCategory(this.state.cat_clothing.id);
      //this.props.fetchArticlesByModelAndTopCat(this.props.model.id, this.state.cat_accessories.id);
      //this.props.fetchArticlesByModelAndTopCat(this.props.model.id, this.state.cat_packages.id);
    } else {
      this.props.history.goBack(); // if directly called
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleCloseView = () => {
    this.props.history.goBack();
  };

  getStateForCategory = category => {
    if(category.sub[0].sub.length > 0) { // category has two levels of subs
      const subCatsWithArticles = category.sub.filter(c => this.props.articles[category.id].filter(ac => ac.category.id === c.sub[0].id).length > 0);

      return {
        top_cat_id: category.id,
        sub_cat_id: subCatsWithArticles[0].sub[0].id,
        show_categories: category.sub
      };
    } else {
      const subCatsWithArticles = category.sub.filter(c => this.props.articles[category.id].filter(ac => ac.category.id === c.id).length > 0);
      //console.log("subCatsWithArticles", subCatsWithArticles);
      return{
        top_cat_id: category.id,
        sub_cat_id: subCatsWithArticles.length > 0 && subCatsWithArticles[0].id, // no articles in this category
        show_categories: subCatsWithArticles
      };
    }
  };

  handleChangeTopCategory = category => {
    this.setState(this.getStateForCategory(category));
  };

  handleChangeSubCategory = category => {
    console.log("handleChangeSubCategory", category);
    this.setState({
      sub_cat_id: category.id
    });
    if(isWidthDown("xs", this.props.width)) {
      this.handleDrawerClose();
    }
  };

  flattenCategories() {
    var subs = [];
    this.props.categories.forEach(top => {
      top.sub.forEach(cat => {
        cat.top = top;
        subs.push(cat);
        cat.sub.forEach(scat => {
          scat.top = top;
          subs.push(scat);
        });
      });
    });
    return subs;
  }

  /**
   * Executed when loaded
   * @param prevProps
   * @param prevState
   * @param snapshot
   * @returns {*}
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.width !== this.props.width || this.state.open === undefined) {
      this.setState({
        open: isWidthUp("sm", this.props.width)
      });
    }

    if (this.state.preset) { // later updates:
      if (this.state.top_cat_id !== prevState.top_cat_id || this.props !== prevProps) { // ATTENTION is executed on any pro change
        this.update();
      }
    }
  }

  update() {
    //console.log("updated is called");
    if(this.state.top_cat_id !== undefined)
    switch (this.state.top_cat_id.id) {
      case this.props.top_categories[0].id: // TODO find another data representation
        this.setState({
          show_articles: this.props.articles[this.state.cat_accessories],
          show_categories: this.props.categories[this.state.cat_accessories]
        });
        break;
      case this.props.top_categories[1].id:
        this.setState({
          show_articles: this.props.outfit,
          show_categories: this.props.outfit_categories
        });
        break;
      case this.props.top_categories[2].id:
        break;
      default:
        break;
    }
  }

  render() {
    const { classes, model, categories, articles } = this.props;
    const { open, top_cat_id, sub_cat_id, show_categories } = this.state;
    console.log("ArticlesGridView props", this.props);
    console.log("ArticlesGridView state", this.state);

    if(articles === undefined || articles[top_cat_id] === undefined || categories === undefined || model === undefined ) {
      return <Spinner />
    }
    const af = articles[top_cat_id];
    const filter = af.filter(a => a.category.id === sub_cat_id);

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          color="default"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar disableGutters={!open}>
            <IconButton
              color="inherit"
              aria-label="Open menu"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, open && classes.hide)}
            >
              <Badge badgeContent={af.length} color={"secondary"}>
                <MenuIcon />
              </Badge>
            </IconButton>

              <Hidden xsDown>
                <Typography variant="h6" color="inherit" noWrap className={classes.grow}>
                  {categories.find(e => e.id === top_cat_id).name} für {model.name}
                </Typography>
              </Hidden>

            <Hidden xsDown>
              {categories.map((cat, i) => (
                <Button
                  key={i}
                  variant="contained"
                  //disableElevation={true}
                  className={classes.button}
                  disabled={cat.id === top_cat_id}
                  onClick={this.handleChangeTopCategory.bind(this, cat)} >
                  {cat.name}
                </Button>
              ))}
              <DialogCart editable={true} />
              <Button
                color="primary"
                variant="contained"
                //disableElevation={true}
                aria-label="Close Articles"
                onClick={this.handleCloseView}
                className={classNames(classes.menuButtonLast)}
              >
                Zurück <SkipNext />
              </Button>
            </Hidden>
            <Hidden smUp>
              {categories.map((cat, i) => {
                const button = buttons.find(b => b.key === cat.id);
                return <LargeIconButton
                    key={i}
                    onClick={this.handleChangeTopCategory.bind(this, cat)}
                    icon={button.icon}
                    justIcon
                    round
                    className={classes.CategoryButton}
                    disabled={!(articles && articles[button.key] && articles[button.key].length > 0)}
                  />;
              })}
              <DialogCart editable={true} />
              <LargeIconButton justIcon
                               round
                               color={"primary"}
                               icon={<SkipNext />}
                               onClick={this.handleCloseView}
                               aria-label="Close Articles"
              />
          </Hidden>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <ArticlesListLeft
            categories={show_categories}
            articles={af}
            selectedCategory={sub_cat_id}
            handle_category={this.handleChangeSubCategory.bind(this)}
          />
          <Divider/>
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: open
          })}
        >
          <ArticleCardsGrid articles={filter} />
        </main>
      </div>
    );
  }
}

ArticlesGridView.propTypes = {
  classes: PropTypes.object.isRequired,

  cart: PropTypes.array.isRequired,
  model: PropTypes.object,

  addToCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  selectFetchArticle: PropTypes.func.isRequired,
  fetchArticlesByCategory: PropTypes.func.isRequired,
  fetchArticlesByModelAndTopCat: PropTypes.func.isRequired,

  //selected: PropTypes.object,
  selected_topcat: PropTypes.object,
  categories: PropTypes.array,
  articles: PropTypes.object
};

function mapStateToProps(state) {
  return {
    cart: state.config.cart,
    model: state.config.model,
    categories: state.articles.categories,
    //selected: state.articles.selected,
    selected_topcat: state.articles.selected_topcat,
    articles: state.articles.articles
  };
}

export default withRouter(
  connect(mapStateToProps,
    { addToCart, removeFromCart, fetchArticlesByCategory, selectFetchArticle, fetchArticlesByModelAndTopCat, fetchModelById }
  )(withStyles(styles)(withWidth()(ArticlesGridView))));
