import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ccyFormat } from "../../../Utils";

import ArticleDetailIcons from "./ArticleDetailIcons";

import { addToCart, removeFromCart } from "../../../actions/configAction";
import { SizeList } from "../SizeList";
import AddCartIcon from "components/Cart/AddCartIcon";
import { getImageUrl } from "../../Image/Image";

import {
  withStyles,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Box, withWidth
} from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import { Translate } from "react-localize-redux";
import ArticleDialog from "./ArticleDialog";
import Assignment from "@material-ui/icons/Assignment";

const styles = theme => ({
  root: {

  },
  card: {
    //maxWidth: 400,
    //backgroundColor: "yellow"
  },
  media: {
    //height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize: "contain"
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: "rotate(0deg)",
    //color: "green",
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  price: {
    marginLeft: "auto!important",
    textAlign: "right"
  },
  description: {
    paddingTop: 6,
    fontSize: "0.95rem"
  }
});

class ArticleCard extends React.Component {
  state = {
    expanded: false
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  handleAddToCart(article) {
    this.props.addToCart(article);
  }

  handleRemoveFromCart(article) {
    this.props.removeFromCart(article);
  }

  render() {
    const { classes, article } = this.props;
    const time = (article.labor > 0) ? (<span className="modelcolor"><Translate id="montage"/> {/*timeFormat(article.labor)*/}</span>) : "";
    //console.log("exclusive", ex);
    //console.log("article", article);
    // is one article of the same category already in cart?
    const cart_filtered = this.props.cart.filter(i => i.category.id === article.category.id);
    // exclude all other articles of the same category
    //console.log(cart_filtered);
    //console.log("index", !cart_filtered.find(a => a === article));
    const excludeThisArticle = article.category.exclusive && cart_filtered.length > 0 && !cart_filtered.find(a => a === article);
    //console.log("exclude", excludeThisArticle);

    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={<AddCartIcon article={article} disabled={excludeThisArticle}/>}
          /*action={
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          }*/
          title={article.description}
          subheader={article.sku} //{ccyFormat(article.priceincl)}
        />
        <CardContent>
          <CardMedia
            className={classes.media}
            image={getImageUrl(article.images[0].filename, "360")}
            alt={article.description}
            onClick={() => this['article_details'].show(article, excludeThisArticle)}
          />
          {/*<Typography component="p" className={classes.description}>
            {article.descriptionadd}
          </Typography>*/}
        </CardContent>
        <CardActions className={classes.actions} /*disableActionSpacing*/>
            <Box display="flex" flexGrow={1}>
              <IconButton size="small" onClick={() => this['article_details'].show(article, excludeThisArticle)}>
                <Assignment/>
              </IconButton>
              <ArticleDetailIcons article={article} classes={classes} />
              <ArticleDialog ref={diag => this['article_details'] = diag} />
              {article.sizes.length > 0 && <SizeList article={article} sizes={article.sizes} />}
            </Box>
            <Box justifyContent="flex-end" className={classes.price}>
              {ccyFormat(article.priceincl)}<br/>{time}
            </Box>
        </CardActions>
        {/*<Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>{article.description}</Typography>
            <Typography paragraph>{article.descriptionadd}</Typography>
            {article.images.length > 1 && <ArticleImages images={article.images}/>}
          </CardContent>
        </Collapse>*/}
      </Card>
    );
  }
}

ArticleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,

  addToCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  cart: state.config.cart
});

export default withWidth()(withStyles(styles)(connect( mapStateToProps, { addToCart, removeFromCart })(ArticleCard)));
