import React from "react";
import { Link } from "react-router-dom";

const AccessoryDetail = () => (
  <div>

    DETAILS
    <p><Link to="/config/accessories">zurück</Link></p>
  </div>
  );

export default AccessoryDetail;