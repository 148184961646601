import React from "react";
import { ReactComponent as ScramblerIcon } from "../../../assets/img/icons/Scrambler.svg";
import { ReactComponent as DamenIcon } from "../../../assets/img/icons/Damen.svg";
import { ReactComponent as KinderIcon } from "../../../assets/img/icons/Kinder.svg";
import { ReactComponent as NichtzugelassenIcon } from "../../../assets/img/icons/Nichtzugelassen.svg";
import { ReactComponent as MattIcon } from "../../../assets/img/icons/Matt.svg";
import { ReactComponent as FuerRennstreckeIcon } from "../../../assets/img/icons/FuerRennstrecke.svg";
import { ReactComponent as DucatiCorseIcon } from "../../../assets/img/icons/DucatiCorse.svg";
import { ReactComponent as GoretexIcon } from "../../../assets/img/icons/Goretex.svg";
import { ReactComponent as ECEzugelassenIcon } from "../../../assets/img/icons/ECEzugelassen.svg";
import { ReactComponent as DetailinfoIcon } from "../../../assets/img/icons/Detailinfo.svg";

import { DialogTitle } from "../../Cart/DialogCart";
import { Translate } from "react-localize-redux";
import { DialogActions, DialogContent } from "../../Dialog/Dialog";
import {
  Grid,
  Button,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
const ICON_SIZE = 24;

const icons = {
  scrambler: {icon: <ScramblerIcon width={ICON_SIZE} height={ICON_SIZE}/>, text: <Translate id="infos.texts.scrambler"/>},
  female: {icon: <DamenIcon width={ICON_SIZE} height={ICON_SIZE}/>, text: <Translate id="infos.texts.female"/>},
  kids: {icon: <KinderIcon width={ICON_SIZE} height={ICON_SIZE}/>, text: <Translate id="infos.texts.kids"/>},
  notece: {icon: <NichtzugelassenIcon width={ICON_SIZE} height={ICON_SIZE}/>, text: <Translate id="infos.texts.notece"/>},
  ece: {icon: <ECEzugelassenIcon width={ICON_SIZE} height={ICON_SIZE}/>, text: <Translate id="infos.texts.ece"/>},
  goretex: {icon: <GoretexIcon width={ICON_SIZE} height={ICON_SIZE}/>, text: <Translate id="infos.texts.goretex"/>},
  matt: {icon: <MattIcon width={ICON_SIZE} height={ICON_SIZE}/>, text: <Translate id="infos.texts.matt"/>},
  race: {icon: <FuerRennstreckeIcon width={ICON_SIZE} height={ICON_SIZE}/>, text: <Translate id="infos.texts.race"/>},
  info: {icon: <DetailinfoIcon width={ICON_SIZE} height={ICON_SIZE}/>, text: <Translate id="infos.texts.info"/>},
  corse: {icon: <DucatiCorseIcon width={ICON_SIZE} height={ICON_SIZE}/>, text: <Translate id="infos.texts.corse"/>}
};

const iconForInfo = info => {
  switch (info.trim()) {
    case "scrambler": return icons.scrambler;
    case "damen": return icons.female;
    case "kinder": return icons.kids;
    case "nicht zugelassen": return icons.notece;
    case "matt": return icons.matt;
    case "für rennstrecke": return icons.race;
    case "ducati corse": return icons.corse;
    case "goretex": return icons.goretex;
    case "ece zugelassen": return icons.ece;
    case "detailinfo": return icons.info;
    default:
      return "";
  }
};

export function DetailIcons(props) {
  return (
    <List aria-label={props.label}>
      {(props.icons ? props.icons : props.info.map(info => iconForInfo(info))).map((icon, i) =>
          <ListItem key={i}>
            <ListItemIcon>{icon.icon}</ListItemIcon>
            <ListItemText primary={icon.text}/>
          </ListItem>
      )}
    </List>
  )
};

export default class ArticleDetailIcons extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { article } = this.props;
    return <React.Fragment>
      {article.info.map((info, i) =>
        <IconButton size="small" /*className={classes.button}*/ aria-label="" onClick={this.handleClickOpen} key={i}>
          {iconForInfo(info).icon}
        </IconButton>)}
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="dialog-infos"
        open={this.state.open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="dialog-infos" onClose={this.handleClose}>
          <Translate id="infos.title"/>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={6}>
              <h4><Translate id="infos.accessories"/></h4>
              <DetailIcons
                icons={[icons.corse, icons.race, icons.notece, icons.info, icons.ece, icons.matt]}
                label={<Translate id="infos.accessories"/>} />
            </Grid>
            <Grid item md={6}>
              <h4><Translate id="infos.clothing"/></h4>
              <DetailIcons
                icons={[icons.corse, icons.goretex, icons.info, icons.female, icons.kids, icons.scrambler]}
                label={<Translate id="infos.clothing"/>} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary"><Translate id="cart.close"/></Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  };
}