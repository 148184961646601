import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Button from "../CustomButtons/Button.jsx";
import { getImageUrl } from "../Image/Image";
import { Translate } from "react-localize-redux";
import { Grid, isWidthDown, withStyles, withWidth } from "@material-ui/core";
import { getBackgroundWidth, getBikeImageWidth } from "../../actions/screens";
import defaultStyle from "assets/jss/custom/default";

class BikeSeriesItem extends React.Component {
  render() {
    const { series, width, classes } = this.props;
    if(isWidthDown("sm", width))
      return (
        <div
          className="seriesitem"
          //key={series.id + "sm"}
          id={"series-" + series.id}
          style={{ backgroundImage: 'url(' + getImageUrl(series.background, getBackgroundWidth(width)) + ')' }} >
          <Grid container
                className="series"
                alignItems="baseline"
                style={{ backgroundImage: "url(" + getImageUrl(series.bike, getBikeImageWidth(width)) + ")" }}
                onClick={this.props.select.bind(this, { series: series })}>
            <Grid item xs={12} style={{ height: "100%" }} className={classes.title}>
              <h1>{series.name}</h1>
              <p>{series.text}</p>
            </Grid>
          </Grid>
        </div>
      );
    else
      return (
        <div
          className="seriesitem"
          //key={series.id + "md"}
          id={"series-" + series.id}
          style={{ backgroundImage: 'url(' + getImageUrl(series.background, getBackgroundWidth(width)) + ')' }} >
          <Grid container
                className="series"
                alignItems="baseline"
                style={{ backgroundImage: "url(" + getImageUrl(series.bike, getBikeImageWidth(width)) + ")" }}
                onClick={this.props.select.bind(this, { series: series })}>
            <Grid item md={7} style={{ height: "100%" }}></Grid>
            <Grid item md={5} style={{ height: "100%" }} className="overlay hide-not-center">
              <h1>{series.name}</h1>
              <p className="large">{series.text}</p>
              <Button
                color="primary"
                className=""
                size="lg"
                onClick={this.props.select.bind(this, { series: series })}
              >
                <Translate id={"buttons.series.select"} />
              </Button>
            </Grid>
            {/* Show the title twice (left and right) for slides outside the center */}
            <Grid item md={12} className="bottomtitle">
              <Grid container>
                <Grid item md={6}><h3 className="left">{series.name}</h3></Grid>
                <Grid item md={6}><h3 className="right">{series.name}</h3></Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
  }
}

BikeSeriesItem.propTypes = {
  select: PropTypes.func.isRequired,
  series: PropTypes.object.isRequired
};

export default withRouter(withWidth()(withStyles(defaultStyle)(BikeSeriesItem)));
