import React from "react";
import { getImageUrl } from "../Image/Image";
import { Translate } from "react-localize-redux";
import SizeSelect from "../Articles/SizeSelect";
import { ccyFormat, getSummaryCart, getSummaryFinance, timeFormat } from "../../Utils";
import PropTypes from "prop-types";

import { TableBody, TableRow, TableCell, Table } from '@material-ui/core';

const CartComplete = ({cart, sizes, model, color, finance, cash}) => {
  const summary = getSummaryCart(cart, finance.items);
  const finance_summary = getSummaryFinance(finance.items);
  return (
    <Table>
      <TableBody>
        <TableRow>
        <TableCell>
          <img src={getImageUrl(color.images[0].filename, 160)} title={model.name} alt={model.name} />
        </TableCell>
        <TableCell>
          {model.name}
        </TableCell>
        <TableCell align="right">
          {ccyFormat(cash?finance.vehicle.baseprice:finance.vehicle.rate)}
        </TableCell>
      </TableRow>
        {cart.map((article, i) => {
          var article_size = sizes[article.id];
          return (
          <TableRow key={i}>
          <TableCell>
          <img src={getImageUrl(article.images[0].filename, 160)} title={article.description} alt={article.sku}
          />
          </TableCell>
          <TableCell>
          {article.description} <span className="articlenumber" style={{ display: "none" }}>{article.sku}</span>
          {article_size && <React.Fragment><br/><Translate id="cart.size" data={{ size: article_size.size }}/></React.Fragment>}
          {(article.sizes.length > 0 && sizes[article.id] === undefined) && <SizeSelect article={article} small/>}
          {article.labor > 0 && <span className="articlenumber"><br/><Translate id={"montage"}/></span>}
          </TableCell>
          <TableCell align="right">
          {ccyFormat(cash ? article.priceincl : finance.items.find(e => e.id === article.id).rate)}
          </TableCell>
          </TableRow>);
        })}
        {summary !== undefined && cart.length > 0 &&
        <TableRow>
          <TableCell colSpan={2}>Zwischensumme Zubehör</TableCell>
          <TableCell align="right">{ccyFormat(cash?summary.total.inkl:finance_summary.rate)}</TableCell>
        </TableRow>
        }
        {summary !== undefined && summary.total.time > 0 && finance_summary !== undefined &&
        <TableRow>
          <TableCell colSpan={2}>Montagekosten {timeFormat(summary.total.time)} {!cash?"einmalig":""}</TableCell>
          <TableCell align="right">{ccyFormat(finance.result.laborEuro)}</TableCell>
        </TableRow>
        }
        <TableRow>
          <TableCell colSpan={2}>Gesamt <Translate id={"finance.kind." + (cash ? "cash" : "monthly")}/></TableCell>
          <TableCell align="right"><strong>{cash ? ccyFormat(summary.total.inkl + model.price) : ccyFormat(finance.vehicle.rate + finance_summary.rate, 2)}</strong></TableCell>
        </TableRow>
        {/*finance.vehicle !== undefined && finance_summary !== undefined && <TableRow>
          <TableCell className={classes.summaryColumn}>Ihre Traum-Ducati</TableCell>
          <TableCell className={classNames(classes.priceColumn, classes.summaryColumn)}>
            {ccyFormat(finance.vehicle.rate + finance_summary.rate)}
          </TableCell>
          <TableCell className={classNames(classes.priceColumn, classes.summaryColumn)}>
            {ccyFormat(summary.total.inkl + model.price)}
          </TableCell>
        </TableRow>*/}
      </TableBody>
    </Table>);
};

CartComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,
  sizes: PropTypes.array,
  model: PropTypes.object.isRequired,
  finance: PropTypes.object.isRequired,
  color: PropTypes.object.isRequired,
  cash: PropTypes.bool
};

export default CartComplete;
