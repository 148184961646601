import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { matchPath } from "react-router";
import { Translate } from "react-localize-redux";

import {
  Stepper,
  Typography,
  Step,
  StepButton,
  MobileStepper,
  withWidth,
  isWidthDown,
  Grid,
  withStyles,
  Dialog,
  DialogContentText, Backdrop, CircularProgress
} from "@material-ui/core";
import Button from "../components/CustomButtons/Button";
import { fetchDealerById, saveOffer, setModel, setSeries, submitOffer, updateFinancing, updateInsurance } from "../actions/configAction";
import { SkipNext, SkipPrevious } from "@material-ui/icons";
import { Switch } from "react-router-dom";
import LargeIconButton from "../components/CustomButtons/LargeIconButton";
import FinanceConfig from "../components/Finance/FinanceConfig";
import InsuranceConfig from "../components/Finance/InsuranceConfig";
import { DRAWER_WIDTH, HEADER_APP_BAR_HEIGHT, MOBILE_STEPPER_HEIGHT } from "../store";
import BikeConfig from "../components/Configurator/BikeConfig";
import BikeSeriesCarousel from "./BikeSeriesCarousel";
import BikeModelsCarousel from "./BikeModelsCarousel";
import { RouteWithLayout } from "../App";
import Qs from "qs";
import { DialogTitle } from "../components/Cart/DialogCart";
import { DialogActions, DialogContent } from "../components/Dialog/Dialog";
import { isCustomerValid } from "../Utils";
import PersonalDataWide from "../components/Configurator/PersonalDataWide";
import DealerWide from "../components/Dealer/DealerWide";
import OfferFinal from "./Configurator/OfferFinal";

export const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: DRAWER_WIDTH
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20
  },
  hide: {
    display: "none"
  },

  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0
  },
  drawerPaper: {
    width: DRAWER_WIDTH
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-start"
  },
  paper: {
    //background: "blue",
    width: DRAWER_WIDTH,
    top: HEADER_APP_BAR_HEIGHT,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  },
  paperMobile: {
    //background: "blue",
    width: "100%",
    top: 0, //HEADER_APP_BAR_HEIGHT,
    bottom: 86 + MOBILE_STEPPER_HEIGHT,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -DRAWER_WIDTH
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  creditsSmall: {
    //background: theme.palette.primary.featuregray,
    //padding: "10px",
    //borderRadius: "4px",
    //"& p": {
    //  fontSize: "0.75rem"
    //}
  },
  //});
  //const useStyles = makeStyles(theme => ({
  activeColor: {
    border: "2px solid #666666",
    borderRadius: "50%",
    padding: 1
  },
  text: {
    padding: theme.spacing(2, 2, 0)
  },
  paperBottom: {
    paddingBottom: 50
  },
  list: {
    marginBottom: theme.spacing(2)
  },
  subheader: {
    backgroundColor: theme.palette.background.paper
  },
  appBottomBar: {
    top: "auto",
    bottom: MOBILE_STEPPER_HEIGHT // height of stepper
  },
  toolbar: {
    margin: "0 auto"
  },
  grow: {
    flexGrow: 1
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto"
  },
  colorName: {
    color: theme.palette.secondary.main
  },
  //button: {
  Paper: {
    root: {
      color: "black"
    }
  },
  centerContainer: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 400
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 610
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 680
    }
  }
  //}
});

export const setDealerFromQuery = props => {
  if (props.location.search.startsWith("?")) {
    var obj = Qs.parse(props.location.search.replace("?", ""));
    if (obj.dealer) {
      props.fetchDealerById(obj.dealer);
    }
  }
};

const layoutFullWidth = props => (
  <Grid item xs={12} sm={12} md={12}>{props.children}</Grid>
);

const layoutButtons = props => <>
      <Grid item xs={1} sm={1} md={1} container direction="column" justifyContent="center" alignItems="center">
        <LargeIconButton justIcon
                         round
                         title={<Translate id="buttons.back"/>}
                         color={"primary"}
                         icon={<SkipPrevious />}
                         onClick={props.handleNext(props.prev)} />
      </Grid>
      <Grid item xs={12} sm={12} md={10} className={"bikecenter"}>{props.children}</Grid>
      <Grid item xs={12} sm={12} md={1} container direction="column" justifyContent="center" alignItems="center">
        <LargeIconButton justIcon
                         round
                         title={<Translate id={props.nextLabel}/>}
                         color={"primary"}
                         icon={<SkipNext />}
                         disabled={!props.next}
                         onClick={props.next ? props.handleNext(props.next) : undefined} />
      </Grid>
    </>;

const AlertNoArticles = props => <Dialog
  open={props.open}
  onClose={props.handleClose}
  aria-labelledby={props.id}
  aria-describedby={props.id}
>
  <DialogTitle id={props.id}>{props.title}</DialogTitle>
  <DialogContent>
    <DialogContentText id={props.id}>
      {props.children}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={props.handleClose} size={props.size}>
      {props.no}
    </Button>
    <Button onClick={props.handleOk} size={props.size} color="primary" autoFocus>
      {props.yes}
    </Button>
  </DialogActions>
</Dialog>;

class ProgressStepper extends React.Component {
  state = {
    activeStep: 0,
    completed: new Set(),
    submit: false,

    showNoArticleDialog: false,
    confirmedNoArticleDialog: undefined
  };

  buildConfigUrl = kind => {
    const reqDealer = (this.props.dealerPredefined && this.props.dealer !== undefined && ("?dealer=" + this.props.dealer.id)) || "";
    const reqModel = this.props.model && (this.props.model.id + "/");
    const reqColor = this.props.color && (this.props.color.color + "/");
    return "/config/" + reqModel + reqColor + kind + reqDealer;
  };

  steps = () => {
    const {series, model, cart, finance, insurance, offer, customer, dealer, delivery} = this.props;
    return [
      { // 0
        path: "/series/:series?",
        name: "Select a series",
        component: BikeSeriesCarousel,
        layout: layoutFullWidth,
        title: <Translate id="stepper.series.title"/>,
        subtitle: !_.isEmpty(series) && series.name,
        hint: <Translate id="stepper.series.hint"/>,
        link: "/series", // this is the path
        optional: false,
        nextLabel: "buttons.series.select",
        blue: false,
        required: true
      },
      { // 1
        path: "/models/:series?",
        name: "Select a model",
        component: BikeModelsCarousel,
        layout: layoutFullWidth,
        title: <Translate id="stepper.models.title"/>,
        subtitle: !_.isEmpty(model) && model.name,
        hint: <Translate id="stepper.models.hint"/>,
        link: "/models/" + (series ? series.id : ""),
        optional: false,
        nextLabel: "buttons.models.select",
        blue: false
      },
      { // 2
        path: "/config/:model?/:color?",
        name: "Configurator",
        component: BikeConfig,
        layout: layoutButtons,
        title: <Translate id="stepper.config.title"/>,
        subtitle: cart.length === 0 ? <Translate id={"cart.noarticles"} /> : <Translate id="stepper.article_single" data={{ count: cart.length }}/>,
        hint: <Translate id="stepper.config.hint"/>,
        link: "/config/" + (model ? model.id : ""),
        optional: true,
        nextLabel: "buttons.next",
        blue: true
      },
      { // 3
        path: "/config/:model?/:color?/fin",
        name: "Configurator",
        component: FinanceConfig,
        layout: layoutButtons,
        title: <Translate id="stepper.financing.title"/>,
        subtitle: finance !== undefined && <Translate id={"stepper.subtitles." + finance.type} />,
        hint: <Translate id="stepper.financing.hint"/>,
        link: this.buildConfigUrl("fin"),
        optional: true,
        nextLabel: "buttons.next",
        blue: true
      },
      { // 4
        path: "/config/:model?/:color?/ins",
        name: "Configurator",
        component: InsuranceConfig,
        layout: layoutButtons,
        title: <Translate id="stepper.insurance.title"/>,
        subtitle: insurance !== undefined && (
            insurance.hp ? (
                insurance.kasko ?
                  <Translate id="stepper.subtitles.insurance.hpv_kasko" data={{kasko: <Translate id={"kaskotype." + insurance.kasko}/>}}/>
                  :
                  <Translate id="stepper.subtitles.insurance.hpv"/>
              )
              :
              <Translate id="insurance.none"/>),
        hint: <Translate id="stepper.insurance.hint"/>,
        link: this.buildConfigUrl("ins"),
        optional: true,
        nextLabel: "buttons.next",
        blue: true,
        required: true
      },
      { // 5
        path: "/customer/:code?",
        name: "Customer",
        component: PersonalDataWide,
        layout: layoutButtons,
        title: <Translate id="laststeps.steps.data"/>,
        subtitle: !_.isEmpty(customer) && isCustomerValid(customer) && customer.firstname + " " + customer.lastname,
        hint: <Translate id="stepper.final.hint"/>,
        link: offer && ("/customer/" + offer.id),
        optional: false,
        nextLabel: "buttons.next",
        blue: false
      },
      { // 6
        path: "/delivery/:code?",
        name: "Delivery",
        component: DealerWide,
        layout: layoutButtons,
        title: !_.isEmpty(delivery) ? <Translate id={"laststeps.steps.delivery." + delivery} /> : <Translate id="laststeps.steps.delivery.title"/>,
        subtitle: !_.isEmpty(dealer) && dealer.name,
        hint: <Translate id="stepper.final.hint"/>,
        link: offer && ("/delivery/" + offer.id),
        optional: false,
        nextLabel: "buttons.next",
        blue: false
      },
      { // 7
        path: "/confirmation/:code?",
        name: "Confirmation",
        component: OfferFinal,
        layout: layoutButtons,
        title: <Translate id="stepper.final.title"/>,
        subtitle: !_.isEmpty(model) && model.name + (cart.length > 0 ? " und " + cart.length + " Artikel" : ""),
        hint: <Translate id="stepper.final.hint"/>,
        link: offer && ("/confirmation/" + offer.id),
        optional: false,
        nextLabel: "buttons.finish",
        blue: false
      }
    ];
  };

  constructor(props) {
    super(props);
    setDealerFromQuery(props);
    /*if (this.props.model === undefined) {
      if (this.props.match.params.model) {
        console.log("Configurator: DIRECT ACCESS - load data: " + this.props.match.params.model);
        if (this.props.match.params.color) {
          console.log("Configurator: DIRECT ACCESS -SET COLOR TODO: " + this.props.match.params.color);
          this.props.fetchModelById(this.props.match.params.model, this.props.match.params.color);
        } else {
          this.props.fetchModelById(this.props.match.params.model);
        }
      }
    }*/
    if(props.location) {
      const matches = this.steps().map(step => matchPath(props.location.pathname, {
        path: step.path,
        exact: true,
        strict: false
      }));
      const stepByLocation = matches.findIndex(m => m !== null);
      this.state.activeStep = stepByLocation;
    }
    //this.updateCompleted();
  }

  handleLink(target) {
    this.props.history.replace(target);
  };

  totalSteps = () => this.steps().length;

  handleStep = step => () => {
    const { activeStep } = this.state;
    console.log("handleStep", step);

    if(step === activeStep)
      return;

    // if the step is already completed, reopen it
    if (this.isStepComplete(step)) {
      if (typeof this.steps()[step].link === "function") {
        this.steps()[step].link();
      } else {
        this.props.history.replace(this.steps()[step].link);
      }
      this.setState({
        activeStep: step
      });
      return;
    }

    if(step === this.totalSteps()) {
      this.handleSubmit();
    }

    // proceed to the next step
    if (step < activeStep || (step > activeStep && (this.isStepComplete(activeStep) || this.steps()[activeStep].optional))) {
      this.handleNext(step);
    } /*else {
      this.setState({
        activeStep: step
      });
    }*/
  };

  isStepComplete(step) {
    return this.state.completed.has(step);
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  handleSubmit() {
    this.setState({
      ...this.state,
      submit: true
    });
    this.props.submitOffer();
  }

  /**
   * This method is called by component id update on step change and executes the necessary methods.
   */
  handleNext(step) { // at mobile stepper
    console.log("handleNext", step);
    switch(step) {
      case 0:
        this.setState({ activeStep: step });
        this.props.history.push("/series");
        break;
      case 1:
        this.setState({ activeStep: step });
        this.props.setSeries(this.props.showSeries);
        this.props.history.push("/models/" + (this.props.showSeries ? this.props.showSeries.id : ""));
        //this.props.history.push("/models/" + this.props.series && this.props.series.id);
        break;
      case 2:
        this.setState({ activeStep: step });
        this.props.setModel(this.props.showModel, this.props.showModel.colors.find(c => c.color === this.props.showColorIndex));
        this.props.history.push("/config/" + this.props.showModel.id + "/" + this.props.showColorIndex);
        break;
      case 3:
        if((this.props.cart && this.props.cart.length > 0) || this.state.confirmedNoArticleDialog !== undefined) {
          this.setState({
            showNoArticleDialog: false,
            activeStep: step
          });
          this.props.saveOffer();
          this.props.history.push(this.steps()[step].link);
        } else {
          this.setState({ showNoArticleDialog: true });
        }
        break;
      case 4:
      case 5:
      case 6:
      case 7:
        this.setState({ activeStep: step });
        //this.props.saveOffer(); // TODO do I have to do this here?
        this.props.history.push(this.steps()[step].link);
        break;
      case 8:
        console.log("final step!");
        this.handleSubmit();
        //break;
      default:
        console.debug("Goto STEP: ", step);
        break;
    }
  }

  updateCompleted() {
    const { series, model, offer, finance, insurance, cart, sizes, customer, dealer, delivery } = this.props;
    console.log("updateCompleted props", this.props);
    console.log("updateCompleted state", this.state.completed);
    const completes = [
      series !== undefined,       // 0
      model !== undefined,        // 1
      offer !== undefined && cart.length > 0,        // 2
      finance !== undefined  && finance.modified,      // 3
      insurance !== undefined && insurance.modified,  // 4
      isCustomerValid(customer),                      // 5
      ((dealer !== undefined && delivery !== undefined) ? true : false), // 6
      ((dealer !== undefined && delivery !== undefined) ? true : false) && cart.filter(e => e.sizes.length > 0 && sizes[e.id] === undefined).length === 0  // 7
    ].reduce((total, currentValue, currentIndex) => (currentValue)?[...total, currentIndex]:total, []);
    //console.log("sizes", cart.filter(e => e.sizes.length > 0 && sizes[e.id] === undefined));
    console.log("updateCompleted", completes);
    this.setState({ completed: new Set([...this.state.completed, ...completes]) });
  }

  /**
   * set active step based on url when props are updated.
   */
  /*updateSteps() {
    const index = this.steps.findIndex(s => this.props.history.location.pathname.startsWith(s.link));
    if(this.props.dialogOpen === false && index > 0) {
      this.setState({
        activeStep: index
      });
      return;
    }
    if(this.props.financingView) {
      this.setState({
        activeStep: 3
      });
      return;
    }
    if(this.props.insuranceView) {
      this.setState({
        activeStep: 4
      });
    }
  }*/

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      this.updateCompleted();
    }

    if (this.state.confirmedNoArticleDialog !== prevState.confirmedNoArticleDialog) {
      if (this.state.confirmedNoArticleDialog) {
        this.handleNext(this.state.activeStep + 1);
        this.setState({ showNoArticleDialog: false });
      } else {
        this.setState({ showNoArticleDialog: false });
      }
    }

    if (this.props.offer && prevProps.offer !== this.props.offer && this.props.offer.status === "SUBMITTED") {
      this.props.history.push("/status/" + this.props.offer.id);
    }
  }

  // show series, model as subtitle
  getSubTitle(step) {
     return this.steps()[step].subtitle;
  }

  getDisabled(step) {
    switch (step) {
      case 0: return false; // always active
      case 1: return !this.isStepComplete(0); // active when series selected
      case 2:
      case 3: return !(this.steps()[step].optional || this.isStepComplete(1)); // active when model selected
      case 4: return !(this.steps()[step].optional || this.isStepComplete(3));
      case 5: return !this.isStepComplete(4); // return !(this.props.insurance && this.props.insurance.modified);//  return !this.isStepComplete(4);
      case 6: return !this.isStepComplete(5);
      case 7: return !this.isStepComplete(6);
      case 8: return !this.isStepComplete(7);
      default:
        return true;
    }
  }

  getAllowBack(step) {
    return !(step > 0 && step <= 2);
  }

  closeAlertNoArticles = (confirm) => {
    if(confirm) {
      this.setState( { confirmedNoArticleDialog: true });
    } else {
      this.setState({ confirmedNoArticleDialog: false });
      this.props.history.push("/articles/" + this.props.firstcategory.id);
    }
  };

  render() {
    const { width, classes } = this.props;
    const { activeStep, showNoArticleDialog } = this.state;
    //const bgStyle = { backgroundImage: (isWidthUp("sm", width)  && activeStep > 0 && series && activeStep < 3) &&  'url(' + getImageUrl(series.background, 960)+ ')', backgroundSize: 'cover' };
    const size = isWidthDown("xs", width) ? "sm" : undefined;
    const switches = <Switch>
      {this.steps().filter(r => r.path).map((prop, step) => {
          //console.log("Setting route for " + prop.path + " to " + prop.component);
          return <RouteWithLayout key={step} exact settings={
            {
              handleNext: this.handleStep.bind(this),
              next: !this.getDisabled(step + 1) && (step + 1),
              prev: step - 1,
              nextLabel: prop.nextLabel
            }
          } layout={isWidthDown("sm", width) ? layoutFullWidth : prop.layout} path={prop.path} component={prop.component}/>
        }
      )}
    </Switch>;

    return <div /*style={bgStyle}*/>
      <Backdrop className={classes.backdrop} open={this.state.submit} style={{zIndex: 10000}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AlertNoArticles
        open={showNoArticleDialog}
        id="no-articles"
        title={<Translate id={"alert.noArticles.title"} />}
        yes={<Translate id={"alert.noArticles.yes"} />}
        no={<Translate id={"alert.noArticles.no"} />}
        handleClose={() => this.closeAlertNoArticles(false) }
        handleOk={() =>this.closeAlertNoArticles(true) }
        size={size}
      >
        <Translate id={"alert.noArticles.text"} />
      </AlertNoArticles>
      {isWidthDown("sm", width) ? <>
        {switches}
        <MobileStepper steps={this.totalSteps()}
                       variant={"dots"}
                       backButton={
                         <Button size={size} /*disabled={this.getAllowBack(activeStep)}*/
                                 onClick={() => this.handleNext(activeStep - 1)}>
                           <Translate id={"buttons.back"}/>
                         </Button>}
                       activeStep={activeStep}
                       className={"noPrint"}
                       nextButton={
                         <Button color={this.steps()[activeStep].blue ? "porsche" : "primary"} size={size}
                                 onClick={() => this.handleNext(activeStep + 1)}
                                 disabled={this.getDisabled(activeStep)}
                         >
                           <Translate id={this.steps()[activeStep].nextLabel}/>
                         </Button>}/>
        </> : <>
          <Grid container spacing={0} justifyContent="center" alignContent="center" className={classes.centerContainer}>
            {switches}
          </Grid>
          <Stepper alternativeLabel nonLinear activeStep={activeStep} className={"noPrint"}>
            {this.steps().map((step, index) => {
              const props = {};
              const buttonProps = {};

              /* if (this.steps()[index].optional && !this.state.completed.has(index)) {
                buttonProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              } */
              return (
                <Step key={index} disabled={this.getDisabled(index)}{...props}>
                  <StepButton onClick={this.handleStep(index)} completed={this.isStepComplete(index)}{...buttonProps}>
                    {step.title}
                    <Typography variant="caption"><br/>{this.getSubTitle(index)}</Typography>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </>}
      </div>;
  }
}

ProgressStepper.propTypes = {
  classes: PropTypes.object,

  series: PropTypes.object,
  model: PropTypes.object,
  color: PropTypes.object,
  offer: PropTypes.object,
  customer: PropTypes.object,
  finance: PropTypes.object,
  insurance: PropTypes.object,
  cart: PropTypes.array,
  sizes: PropTypes.array,

  showSeries: PropTypes.object,
  showModel: PropTypes.object,
  showColorIndex: PropTypes.number,

  dealerPredefined: PropTypes.bool,
  dealer: PropTypes.object,
  delivery: PropTypes.string,

  //finished: PropTypes.bool.isRequired,
  configurationValid: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,

  saveOffer: PropTypes.func.isRequired,
  submitOffer: PropTypes.func.isRequired,
  setSeries: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  updateFinancing: PropTypes.func.isRequired,
  updateInsurance: PropTypes.func.isRequired,
  fetchDealerById: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  series: state.config.series,
  model: state.config.model,
  color: state.config.color,
  offer: state.config.offer,
  cart: state.config.cart,
  sizes: state.config.sizes,
  customer: state.config.customer,
  finance: state.config.selected_finance,
  insurance: state.config.selected_insurance,
  showSeries: state.global.showSeries,
  showModel: state.global.showModel,
  showColorIndex: state.global.showColorIndex,

  firstcategory: state.articles.categories[0],

  dealerPredefined: state.config.dealerPredefined,
  dealer: state.config.dealer,
  delivery: state.config.offer && state.config.offer.delivery,
  // calculated props:
  configurationValid: state.global.configurationValid,
  //finished: state.config.status !== undefined && state.config.status < 2,
});

export default connect(
  mapStateToProps,
  {
    saveOffer,
    updateFinancing,
    updateInsurance,
    fetchDealerById,
    setSeries, setModel,
    submitOffer
  }
)(withWidth()(withStyles(styles, { withTheme: true })(ProgressStepper)));
