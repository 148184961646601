import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import CollapseIcon from "@material-ui/icons/ExpandLess";

// core components
import cardHeaderStyle from "assets/jss/material-kit-react/components/cardHeaderStyle";

function CardHeader({ ...props }) {
  const { classes, className, children, color, plain, icon, action, collapse, collapseFunction, ...rest } = props;
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[color + "CardHeader"]]: color,
    [classes.cardHeaderPlain]: plain,
    [className]: className !== undefined
  });

  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
      {icon && <IconButton onClick={action} size="small" color="inherit" style={{float:"right", marginTop: -6, fontSize:"1.0rem"}}>{icon}</IconButton>}
      {collapse !== undefined &&
        <IconButton onClick={collapseFunction} size="small" color="inherit" style={{float:"right", marginTop: -6, fontSize:"1.0rem"}}>
          {collapse ? <CollapseIcon/> : <ExpandIcon/>}
        </IconButton>}
    </div>
  );
}

CardHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(["warning", "success", "danger", "info", "primary"]),
  plain: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  action: PropTypes.func,
  collapse: PropTypes.bool,
  collapseFunction: PropTypes.func
};

export default withStyles(cardHeaderStyle)(CardHeader);
