import { createTheme } from "@material-ui/core/styles";
import ducati from "assets/jss/colors/ducati";
import porsche from "assets/jss/colors/porsche";

const defaultTheme = createTheme();

const theme = createTheme({
  props: {
    // withWidth component
    MuiWithWidth: {
      //withTheme: true,
      // Initial width property
      initialWidth: "lg" // Breakpoint being globally set
    }
  },
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: ducati,
    secondary: {
      light: "#EBEBEB",
      main: "#BBBCBC",
      dark: "#666666",
      contrastText: "#0F0F0F"
    },
    porsche: porsche,
    background: {
      //paper: ducati.featuregray,
      categoryItem: ducati.featuregray
    }
  },
  overrides: {
    MuiTableRow: {
      root: {
        //for the body
        height: 34 // smaller row height
      },
      head: {
        //for the head
        height: 34 // smaller row height
      }
    },
    MuiTableCell: {
      root: {
        //for the body
        [defaultTheme.breakpoints.up("sm")]: {
          paddingRight: "28px!important"
        },
        [defaultTheme.breakpoints.down("xs")]: {
          padding: 12
        }
      }
      /*head: {               //for the head
        paddingRight: '28px!important',   // smaller column padding
      }*/
    },
    MuiStepper: {
      root: {
        backgroundColor: "transparent",
        [defaultTheme.breakpoints.down("sm")]: {
          padding: 0
        }
      }
    },
    MuiFormControl: {
      root: {
        width: "100%",// to span the input fields over the whole width
        [defaultTheme.breakpoints.down("sm")]: {
          marginTop: 3,
          marginBottom: 3
        }
      }
    },
    MuiSelect: {
      select: {
        backgroundColor: porsche.bglight
      }
    },
    MuiInputBase: {
      root: {
        backgroundColor: porsche.bglight
      }
    },
    MuiFormGroup: {
      row: {
        backgroundColor: porsche.bglight,
        paddingLeft: 10
      }
    },
    MuiButton: {
      text: {
        [defaultTheme.breakpoints.down("md")]: {
         //padding: 0
        }
      }
    },
    MuiBadge: {
      anchorOriginTopRightRectangle: {
        top: 4,
        right: 4
      }
    }
    /*MuiButtonBase: {
      root: {
        [defaultTheme.breakpoints.down("sm")]: {
          marginLeft: 10,
          marginRight: 10
        }
      }
    }*/
  },
  porscheStyle: {
    fontSize: "25px",
    paddingLeft: "24px",
    margin: "-4px"
  },
  configPaper: {
    //padding: ...theme.spacing(2),
    backgroundColor: ducati.featuregray
  },
  /// Table Formattings
  price: {
    width: "25%",
    textAlign: "right",
    total: {
      textAlign: "right",
      fontWeight: 700
    },
    header: {
      textAlign: "right",
      fontSize: "0.875em"
    }
  },
  noline: {
    borderBottom: 0
  },
  /// Button Sizes
  buttons: {
    round: {
      [defaultTheme.breakpoints.down("sm")]: {
        width: 24
      },
      [defaultTheme.breakpoints.up("md")]: {
        width: 32
      },
      [defaultTheme.breakpoints.up("lg")]: {
        width: 42
      }
    }
  },
  appBar: {
    paddingTop: "0.6rem", // not used yet
    paddingBottom: "0.6rem", // not used yet
    height: 84
  },
  configLeft: {
    paddingRight: 20,
    backgroundColor: "blue",
    [defaultTheme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  cartBadge: {
    top: 6,
    right: 6
  },
  greeting: {
    textTransform: "initial",
    paddingBottom: "2rem",
    [defaultTheme.breakpoints.down("sm")]: {
      //paddingLeft: 10,
      //paddingRight: 10,
      marginTop: 64
    }
  },
});
export default theme;

export const verticalCentered = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)"
};
