import React from "react";
import { Link } from "react-router-dom";
import LargeIconButton from "../CustomButtons/LargeIconButton";

import FinalIcon from "@material-ui/icons/Check";
import { Button, Fab } from "@material-ui/core";
import { SkipNext, SkipPrevious } from "@material-ui/icons";

export function BlueButton(props) {
  const {title, classes, ...rest} = props;
  return <Button>
    {/*<span className={classes.actionTitle}>{title}</span>  NO TITLE LEFT*/}
      <LargeIconButton color="porsche" icon={<i className="duc-porsche"></i>} title={title} {...rest} />
    </Button>;
}

export function CompleteButton(props) {
  const {
    title,
    ...rest
  } = props;

  return (
    <Link to={"/offer"}>
      <LargeIconButton
        variant="contained"
        color="primary"
        fullWidth
        title={title}
        icon={<FinalIcon />}
        {...rest}
      />
    </Link>
  );
}

export function CategoryButton(props) {
  const { icon, title, classes, ...rest} = props;
  return <LargeIconButton title={title} icon={icon} {...rest} />;
}

export function ReverseButton(props) {
  return (
    <div>
      <Fab
        color="primary"
        aria-label="Zurück"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)"
          }}
        onClick={props.onClick}
        size="medium">
        <SkipPrevious />
      </Fab>
      <h5>NEXT</h5>
    </div>
  );
}

export function ForwardButton(props) {
  return (
    <Fab
      color="primary"
      aria-label="Nächster Schritt"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
      }}
      onClick={props.onClick}
      size="medium">
      <SkipNext />
    </Fab>
  );
}
