import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchModelsBySeriesId, fetchModelsBySeriesName, fetchSeries } from "../actions/bikesAction";
import Carousel from "react-slick";
import classNames from "classnames";

import BikeColors from "../components/Configurator/BikeColors";
import Spinner from "../components/Configurator/Spinner";
import { setModel, fetchCartFinance } from "../actions/configAction";
import { getImageUrl } from "../components/Image/Image";
import { CircularProgress, withWidth } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { showModel } from "../actions/globalAction";
import { getBikeImageWidth } from "../actions/screens";
import { carouselSettings } from "../store";
import validate from "uuid-validate";

class BikeModelsCarousel extends React.Component {
  state = {
    views: [],
    loaded: 0,
    count: 0,
    images: false,
    ready: this.props.available && this.props.available.length > 0
  };

  constructor(props) {
    super(props);
    //console.log("BikeModelsCarousel props", props);
    if(props.available && props.selectedModel) {
      carouselSettings.initialSlide = props.available.findIndex(m => m.id === props.selectedModel.id);
    } else {
      carouselSettings.initialSlide = 0; // TODO update carousel initial slide when a model is already selected.
    }

    //console.debug("BikeModelsCarousel Constructor props", this.props);
    //console.debug("BikeModelsCarousel parameter", props.match.params.series);
    var selected_series_id = props.match.params.series !== undefined ?
      props.match.params.series :
      props.series ? props.series.id : undefined;

    if(validate(selected_series_id)) {
      //console.debug("Showing models for series " + selected_series_id);
      this.props.fetchModelsBySeriesId(selected_series_id);
    } else if(selected_series_id) {
      //console.debug("Showing models for series name " + selected_series_id);
      this.props.fetchModelsBySeriesName(selected_series_id);
    } else {
      //console.error("Accessing models without series.");
      this.props.history.replace("/");
    }
    // TODO initialize with already selected data:
    if(props.selectedModel !== undefined && props.selectedColor !== undefined) {
      //console.log("BikeModelsCarousel SELECTED MODEL", props);
      props.showModel(props.selectedModel, props.selectedColor);
      //this.preselect(props.selectedModel.id);
    }
  }

  handleConfigure(model, color) {
    //console.log("handleConfigure model", model);
    //console.log("handleConfigure color", color);
    this.props.setModel(model, color);
    this.props.history.push("/config/" + model.id + "/" + color.color);
  }

  handleChange(index) {
    //console.debug("BikesModelsCarousel handleChange", index);
    const model = this.props.available[index];
    //console.debug(model);
    if(model) {
      this.props.showModel(model); //, this.props.config.color); // TODO add color
    }
  }

  // FIXME for some reason this is called 6 times per image (too much)
  imageLoaded(path) {
    //const path = e.composedPath()[0].src;
    this.setState((previousState) => {
      const images = { ...previousState.images, [path]: true };
      const ready = Object.values(images).reduce((accumulator, currentValue) => accumulator && currentValue);
      return {
        ...previousState,
        loaded: previousState.loaded + 1,
        images: images,
        ready: ready
      };
    });
  }

  preloadImage(image, width) {
    //console.log("preloadImage", image);
    const img = new Image();
    img.src = getImageUrl(image, width);
    this.setState((previousState) => { return {
        ...previousState,
        images: { ...previousState.images, [img.src]: false }
      };
    });
    img.onload = this.imageLoaded.bind(this, img.src);
  }

  preselect(modelId) {
    //console.debug("preselect Series: ", series);
    const index = this.props.available.findIndex(s => s.id === modelId);
    if (index >= 0) {
      carouselSettings.initialSlide = index;
      //this.props.showSeries(this.props.available[index]);
    }
  }

  /***
   * After all models for the selected series are loaded, the views are retrieved for each model.
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const available = this.props.available;
    //console.debug("componentDidUpdate 2 props", this.props);
    //console.debug("componentDidUpdate 2 state", this.state);
    if(available !== undefined && available !== prevProps.available && available.length > 0) {
      //console.debug("finished loading models - set the view to the first", available);
      // if a model (and color) was preselected, do not set the view to the first model)
      if(!this.props.model || (this.props.model && available.find(m => m.id === this.props.model.id))) {
        //console.log("Show model again");
        this.props.showModel(available[0], available[0].colors[0]);
      }
      // preload images of first color:
      available[0].colors[0].images.forEach(i =>
        this.preloadImage(i.filename, getBikeImageWidth(this.props.width))
      );
    }
  }

  render() {
    const { series, available, selectedColor } = this.props;
    //console.debug("BikeModelsCarousel props", this.props);
    //console.debug("BikeModelsCarousel state", this.state);
    if(available === undefined || series === undefined)
      return <Spinner />;

    const classes = this.state.ready ? "carousel show" : "carousel ";
    return (
      <React.Fragment>
        {!this.state.ready && <div className="carousel-loading">
          <CircularProgress
            className="progress"
            variant="determinate"
            value={(this.state.loaded / this.state.count) * 100}
            color="secondary"
          />
          <p>
            <Translate id="loader.models" data={{series: series.name}}/>
          </p>
        </div>}
        <div className={classes}>
          <Carousel {...carouselSettings} afterChange={this.handleChange.bind(this)} className={"models"}>
            {available && available.map((model) =>
              <div
                key={model.id}
                id={"model-" + model.id}
                className={classNames({["series-" + series.id]:true, "model":true})} >
                <BikeColors
                  model={model}
                  color={selectedColor && selectedColor.id}
                  series={series}
                  index={model.id}
                  select={this.handleConfigure.bind(this)}
                  setModel={this.props.setModel}
                  showModel={this.props.showModel}
                  fetchFinance={this.props.fetchCartFinance}
                />
              </div>
            )}
          </Carousel>
        </div>
      </React.Fragment>
    );
  }
}

BikeModelsCarousel.propTypes = {
  fetchModelsBySeriesId: PropTypes.func.isRequired,
  fetchModelsBySeriesName: PropTypes.func.isRequired,
  fetchSeries: PropTypes.func.isRequired,
  fetchCartFinance: PropTypes.func.isRequired,

  showModel: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,

  available: PropTypes.array,
  series: PropTypes.object,
  views: PropTypes.object,

  model: PropTypes.object,
  color: PropTypes.number.isRequired, // preselected color id

  selectedColor: PropTypes.object,
  selectedModel: PropTypes.object
};

const mapStateToProps = state => ({
  available: state.config.series && state.bikes.available_models[state.config.series.id],
  series: state.config.series,
  views: state.bikes.views,
  model: state.global.showModel,
  color: state.global.showColorIndex,
  selectedColor: state.config.color,
  selectedModel: state.config.model
});

export default connect(
  mapStateToProps,
  { fetchModelsBySeriesId, fetchModelsBySeriesName, setModel, fetchSeries, showModel, fetchCartFinance }
)(withWidth()(BikeModelsCarousel));
