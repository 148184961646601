import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import StandardLayout from "./views/Configurator/StandardLayout";
import EmptyLayout from "./views/Configurator/EmptyLayout";
import configuratorRoutes from "./routes/configurator";
import emptyRoutes from "./routes";
import AccessoryDetail from "./components/Articles/AccessoryDetail";
import NotFound from "./views/Configurator/NotFound";
import BikeSeries from "./views/BikeSeriesCarousel";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import globalTranslations from "assets/translations/global";
import financeTranslations from "assets/translations/finance";
import cartTranslations from "assets/translations/cart";
import { renderToStaticMarkup } from "react-dom/server";
import MobileLayout from "./views/Configurator/MobileLayout";
import { SnackbarProvider } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { isWidthDown, withStyles, withWidth } from "@material-ui/core";
import { Redirect } from "react-router";
import ScrollToTop from "./ScrollToTop";
import snackbarStyle from "./assets/jss/custom/snackbarStyles";

export function RouteWithLayout({layout, component, settings, ...rest}){
  return (
    <Route {...rest} render={(props) =>
      React.createElement( layout, {...props, ...settings}, React.createElement(component, {...props, ...settings}))
    }/>
  );
}

// https://stackoverflow.com/questions/42862028/react-router-v4-with-multiple-layouts
class App extends React.Component {

  constructor(props) {
    super(props);

    this.props.initialize({
      languages: [
        { name: "Deutch", code: "de" },
        { name: "English", code: "en" },
        { name: "Italiano", code: "it" }
      ],
      translation: globalTranslations,
      options: {
        defaultLanguage: "de",
        renderInnerHtml: true,
        renderToStaticMarkup
      }
    });
    this.props.addTranslation(financeTranslations);
    this.props.addTranslation(cartTranslations);
  }

  render() {
    const notistackRef = React.createRef();
    const mobile = isWidthDown("sm", this.props.width);
    const layout = mobile ? MobileLayout : StandardLayout;

    return (
      <Router>
        <SnackbarProvider
          autoHideDuration={1500}
          maxSnack={mobile ? 1 : 3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          hideIconVariant={mobile}
          ref={notistackRef}
          preventDuplicate
          dense={mobile} // only on mobile views
          classes={this.props.classes}
          action={ key => (<IconButton
            //className={classes.iconButton}
            key="close"
            aria-label="Close"
            color="inherit">
            <CloseIcon onClick={() => { notistackRef.current.closeSnackbar(key); }}/>
          </IconButton>)}
        >
          <ScrollToTop>
            <Switch>
              <Route exact path="/"><Redirect to="/series" query /></Route>
              <Route path="/hello" render={() => { return ( <h1 className="display-3">Hello, world!</h1> ); }} />
              <Route path="/(about|who)/" render={() => ( <div><h2>About</h2>...</div> )} />
              <Route path="/accessory/:key" component={AccessoryDetail} />

              <RouteWithLayout exact layout={layout} path="/" component={BikeSeries}/>

              {emptyRoutes.map((prop, key) => {
                return <RouteWithLayout key={key} layout={EmptyLayout} path={prop.path} component={prop.component} />;
              })}
              {configuratorRoutes.map((prop, key) => {
                return <RouteWithLayout key={key} layout={prop.layout? prop.layout : layout} path={prop.path} component={prop.component} />;
              })}

              <Route path="*" component={NotFound} />
            </Switch>
          </ScrollToTop>
        </SnackbarProvider>
      </Router>
    )
  }
}

App.propTypes = {
  bikes: PropTypes.object.isRequired,
  articles: PropTypes.object.isRequired,
  hasError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]) // TODO ERROR HANDLING
};

export default connect(
  state => ({
    articles: state.articles,
    bikes: state.bikes,
    finance: state.finance,
    hasError: state.error && state.error.fatal ? state.error.id : false
  }),
  {}
)(withLocalize(withStyles(snackbarStyle)(withWidth()(App))));
