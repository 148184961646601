import React from "react";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { selectArticle } from "../../actions/articlesAction";

import notificationsStyles from "assets/jss/material-kit-react/views/componentsSections/notificationsStyles";
import Button from "../CustomButtons/Button";
import { addToCart, removeFromCart } from "../../actions/configAction";
import { withSnackbar } from "notistack";
import cartTranslations from "assets/translations/cart";
import { Translate, withLocalize } from "react-localize-redux";
import { withStyles } from "@material-ui/core";

class AddCartIcon extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(cartTranslations);
  }

  handleAddToCart(article) {
    this.props.addToCart(article);
    this.props.enqueueSnackbar(<Translate id="cart.snackbar.add" data={{article: article.description}}/>, { variant: "success" });
    this.props.secondFunction && this.props.secondFunction();
  }

  handleRemoveFromCart(article) {
    this.props.removeFromCart(article);
    this.props.enqueueSnackbar(<Translate id="cart.snackbar.del" data={{article: article.description}}/>, { variant: "warning" });
    this.props.secondFunction && this.props.secondFunction();
  }

  render() {
    const { article, cart, disabled } = this.props;

    return disabled ?
      <Button aria-label="Nur ein Artikel der Kategorie möglich" round disabled justIcon color="secondary">
        <AddIcon />
      </Button>
        :
        (cart.find(a => a.id === article.id)) ?
        <Button aria-label="Artikel entfernen" onClick={() => this.handleRemoveFromCart(article)} color="success" round justIcon>
          <CheckIcon />
        </Button>
          :
        <Button aria-label="Artikel hinzufügen" onClick={() => this.handleAddToCart(article)} round justIcon color="secondary">
          <AddIcon />
        </Button>;
  }
}

AddCartIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,
  addToCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  selectArticle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,

  enqueueSnackbar: PropTypes.func.isRequired,
  secondFunction: PropTypes.func
};

const mapStateToProps = state => ({
  cart: state.config.cart,
  model: state.config.model
});

export default withStyles(notificationsStyles)(
  withSnackbar(
    connect(mapStateToProps,{ addToCart, removeFromCart, selectArticle })
    (withLocalize(AddCartIcon))
  )
);
