//import customInputStyle from "../material-kit-pro-react/components/customInputStyle";
//import customSelectStyle from "../material-kit-pro-react/customSelectStyle";

const formsStyle = theme => ({
  //...customInputStyle,
  //...customSelectStyle,

  overrides: {
    // Style sheet name ⚛️
    MuiSelect: {
      root: {
        backgroundColor:"red"
      },
      select: {
        color:"blue",
        backgroundColor: "yellow"
      },
      // Name of the rule
      text: {
        // Some CSS
        color: 'white',
      },
    },
  },
  backgroundEmphasize: {
      backgroundColor: "yellow"
  },
  financeInfo: {
    paddingTop: "45px"
  },
  configLeft: {
    paddingRight: 20,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  additional: {
    marginTop: theme.spacing(1)
  },
  selectFinance: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
});

export default formsStyle;