import React from "react";
import { Translate } from "react-localize-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Image } from "components/Image/Image";
import { ReactComponent as Logo } from "../../assets/img/Ducati_Shield_Rot.svg";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";

class OfferTopView extends React.Component {
  render() {
    const { model, color, customer } = this.props;

    return (
      <GridContainer>
        <GridItem md={7} sm={7}><Image src={color.images[0].filename} width={640} /></GridItem>
        <GridItem md={5} sm={5} style={{position:"relative"}}>
          <Logo height={64} width={64} id="logo" title={<Translate id="logo_title" />} />
          <h1 style={{ marginBottom: 0 }}>{customer && customer.firstname && customer.firstname+"s"} {model.name}</h1> <span style={{ color: "#BBBCBC"}}>{color.name}</span>
          <p style={{ paddingRight: "40px", margin: "0 0 48px 0" }}>
            {model.series.text}
          </p>
        </GridItem>
      </GridContainer>
    );
  }
}

OfferTopView.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  model: state.config.model,
  color: state.config.color,
  customer: state.config.customer
});

export default withRouter(
  connect(
    mapStateToProps,
    { }
  )(OfferTopView)
);
