import React from "react";
import Carousel from "react-slick";
import Button from "../CustomButtons/Button.jsx";
import PropTypes from "prop-types";
import ChangeViewIcon from "@material-ui/icons/Replay";
import classNames from "classnames";
import PropertiesTable from "./PropertiesTable";
import { ccyFormat } from "../../Utils";
import { getImageUrl, Image } from "../Image/Image";
import Spinner from "./Spinner";
import { Translate } from "react-localize-redux";
import { Grid, Button as ColorButton, withStyles, withWidth, isWidthDown } from "@material-ui/core";
import { getBikeImageWidth } from "../../actions/screens";
import { Hidden } from "@material-ui/core";
import defaultStyle from "assets/jss/custom/default";
import { database_request_url } from "../../store";
import ProductDialog from "./ProductDialog";
import LinkIcon from "@material-ui/icons/Link";
const button_size = 42;

class BikeColors extends React.Component {
  state = {
    current_color: this.props.color ? this.props.model.colors.find(c => c.id === this.props.color) : this.props.model.colors[0],
    current_view_index: 0,
    openProperties: false,
    finance: undefined
  };

  constructor(props) {
    super(props);
    this.handleColorChange = this.handleColorChange.bind(this);
    // if no vehicle finance information is provided: fetch it:
    if(props.finance_vehicle === undefined)
      this.fetchVehicleFinance();
    else
      this.state.finance = props.finance_vehicle;
  }

  getViewsForColor(color_index) {
    return this.props.model.colors[color_index].images.length;
  }

  handleToggleProperties() {
    this.setState((previousState) => { return {...this.state, openProperties: !previousState.openProperties } } );
  }

  handleColorChange(model, color) { // TODO replace .color by .id soon
    const color_index = model.colors.findIndex(c => c.color === color.color);
    this.setState({
      current_color: color,
      current_view_index: this.state.current_view_index % this.getViewsForColor(color_index)
    });
    this.props.setModel(model, color);
    this.props.showModel(model, color);
  }

  handleViewChange() {
    const { current_color, current_view_index } = this.state;
    const color_index = this.props.model.colors.findIndex(c => c.color === current_color.color);
    this.setState({
      current_view_index: (current_view_index + 1) % this.getViewsForColor(color_index)
    });
  }

  fetchVehicleFinance = () => {
    if(this.props.model === undefined)
      return;
    //console.debug("fetchVehicleFinance in BikeColors", this.props.model);
    const { id } = this.props.model; // clean a little data
    fetch(database_request_url + "/calculate",
      {
        headers: {
          'Accept': 'application/json; charset=utf-8',
          'Content-Type': 'application/json; charset=utf-8'
        },
        method: "POST",
        body: JSON.stringify({ vehicle: { id }, cart: [], config: false })
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        this.setState({
          finance: data.vehicle
        });
        //console.log("financing received: ", data);
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.model !== undefined && this.props.index !== undefined  && this['color_slider_' + this.props.index]&& this.state !== prevState) {
      this['color_slider_' + this.props.index].slickGoTo(this.state.current_view_index);
    }
  }

  render() {
    const { model, index, classes, className, width } = this.props;
    const { current_color, finance } = this.state;
    //console.log("Bike Colors props", this.props);
    //console.log("Bike Colors state", this.state);
    if(model === undefined) {
      return <Spinner/>;
    }

    const settings = {
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      speed: 300,
      fade: true,
      cssEase: "linear",
      className: "colors",
      // if props color is preset then show the selected color:
      initialSlide: this.state.current_view_index // this is often called twice.
    };

    const color_index = current_color !== undefined ? model.colors.findIndex(c => c.color === current_color.color) : 0;
    const color_views = model.colors[color_index].images;
    const images = color_views.map(i => <Image key={i.id} src={i.filename} width={getBikeImageWidth(width)}/>);
    const configureButton = this.props.select && (
      <Button
        color="primary"
        className=""
        //size={button_size}
        onClick={() => this.props.select(model, model.colors[color_index])}
      ><Translate id="buttons.models.select" />
      </Button>
    );

    const bikeActionButtons = <Grid item container xs={12} md={7} justifyContent="center" className="hide-not-center">
      {/* <Button justIcon round color="transparent"><Favorite style={{ color: "#666666" }} /></Button> */}
      {model.colors.map(col =>
        <ColorButton key={col.id} onClick={() => this.handleColorChange(model, col)}>
          <Image src={col.filename} width={button_size} className={(current_color && current_color.id === col.id) ? classes.activeColor : ""} />
        </ColorButton>
      )}
      {color_views.length > 1 &&
      <Button justIcon round color="white" disabled={color_views.length === 1} onClick={() => this.handleViewChange()} className={classes.actionButton}>
        <ChangeViewIcon className={classes.changeViewButton} />
      </Button>
      }
      <ProductDialog model={model} images={color_views} width={width} className={classes.actionButton} />
      <Button justIcon round color="white" rel="noopener noreferrer" href={"https://www.ducati.at/index.php?id=" + model.website} target="_blank" className={classes.actionButton}>
        <LinkIcon />
      </Button>
    </Grid>;

    //console.debug("BikeColors", finance);

    if(isWidthDown("sm", width))
      return (
        <div id={"colors-" + model.id} className={classNames({"modelitem":true, [classes.BikeColors]: true, [className]:true})}>
          <Grid container direction="row">
            <Grid item xs={12} md={12} className={classes.title}>
              <h1>{model.name}</h1>
              <h2>{model.colors[color_index].name}</h2>
              <p>{model.text}</p>
            </Grid>
            <Grid item xs={10} md={12} onClick={() => this.props.select(model, current_color)}>
              <Carousel
                ref={(slider) => this['color_slider_' + index] = slider} {...settings}>
                {images}
              </Carousel>
            </Grid>
            {bikeActionButtons}
            <Grid item container xs={12} justifyContent="center" className={"iconbuttons"}>
              <h1 className={classes.modelColorPrice}>{ccyFormat(model.priceincl, 0)}
                {finance !== undefined && <span className={classes.modelColorSubPrice}>
                  <Translate id="labels.monthly" data={{total: ccyFormat(finance.rate, 0)}} />
                </span>}
              </h1>
            </Grid>
          </Grid>
        </div>);
    else
      return (
        <div id={"colors-" + model.id} className={classNames({"modelitem":true, [classes.BikeColors]: true, [className]:true, [model.brand]:true})} style={{
          backgroundImage: 'url(' + getImageUrl(model.series.background, 960)+ ')',
          backgroundSize: 'cover' }}>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item md={7} className={classes.title}/*className="hide-not-center"*/>
              <h1>{model.name}</h1>
              <h2>{model.colors[color_index].name}</h2>
            </Grid>
            <Grid item md={5}>

            </Grid>
          </Grid>
          <Grid container direction="row" style={{ position: "relative", height: "calc(100%-80px)" }}>
            <Grid item md={7} className="fade-not-center" onClick={() => this.props.select(model, current_color)}>
              <Carousel ref={(slider) => this['color_slider_' + index] = slider} {...settings}>
                {images}
              </Carousel>
            </Grid>
            {!this.props.overrideContent &&
            <Grid item md={5} className="hide-not-center">
              <p className="large">{model.text}</p>
              <PropertiesTable model={model} images={color_views}/>
            </Grid>}
          </Grid>
          <Grid container direction="row">
            {bikeActionButtons}
            <Grid item md={5} style={{ position: "relative" }}>
              {this.props.overrideContent ? this.props.overrideContent :
                <Grid container className="hide-not-center">
                  <Grid item md={6}>
                    {configureButton}
                  </Grid>
                  <Grid item md={6} style={{ textAlign: "right", paddingRight: 20}}>
                    <h3 style={{marginTop: 0}}><Hidden mdDown={true}><span  style={{ fontSize: 14, paddingRight: 20 }}><Translate id="labels.price" /></span></Hidden> {ccyFormat(model.priceincl, 0)}
                      {finance !== undefined && <span style={{
                      fontSize: 11,
                      display: "block",
                      lineHeight: 0
                    }}><Translate id="labels.monthly" data={{total: ccyFormat(finance.rate, 0)}} /></span>}
                    </h3>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </div>
      );
  }
}

BikeColors.propTypes = {
  select: PropTypes.func,
  model: PropTypes.object.isRequired,
  buttonsTop: PropTypes.bool,

  // allow just a color change of an already selected model (by presetting the color
  setModel: PropTypes.func.isRequired,
  showModel: PropTypes.func.isRequired,
  color: PropTypes.string,
  views: PropTypes.array,

  finance_vehicle: PropTypes.object,

  overrideContent: PropTypes.element
};

export default withWidth()(withStyles(defaultStyle, { withTheme: true})(BikeColors));
