const snackbarStyle = theme => ({
  contentRoot: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  },
  action: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
});

export default snackbarStyle;