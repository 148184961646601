import React, { useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";

import { modifyInsuranceConfig, saveOffer, updateInsurance } from "../../actions/configAction";
import { toggleInsuranceMode } from "../../actions/globalAction";
import InsuranceInfoDetail from "./InsuranceInfoDetail";

import {
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  FormControlLabel,
  Grid,
  withWidth,
  DialogContent, RadioGroup, Radio, Dialog, DialogTitle, DialogContentText, DialogActions, Slide, withStyles, isWidthDown, IconButton
} from "@material-ui/core";
import { ccyFormat } from "../../Utils";

import formsStyle from "../../assets/jss/custom/forms";
import Button from "../CustomButtons/Button";
import CloseIcon from "@material-ui/icons/Close";

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const values = [{val: true, key: "true"}, {val: false, key: "false"}];

const QuestionYesNo = props => {
  const [info, showInfo] = useState(false);
  const { name, value, handleFunc, disabled, additional, classes } = props;
  //console.log("Insurance QuestionYesNo 1 " + name, value);
  const handleDialogSelect = e => {
    //console.log("QuestionYesNo handleDialogSelect" + name,{ [e.target.name]: e.target.checked, [e.target.value]: e.target.checked });
    showInfo(false);
    handleFunc({ target: {
      name: name,
      value: e.target.value
    }});
  };
  const stringValue = String(value);
  //console.log("Insurance QuestionYesNo 2 " + name, stringValue);
  return (
    <Grid container>
      <Grid item xs={12}>
        <h5 onClick={() => showInfo(!info)}>
          <Translate id={"idd.titles." + name} />{" "}
          <i className="fas fa-info-circle grey" onClick={() => showInfo(!info)}></i>
          {/*value === undefined && <FormHelperText><Translate id={"idd.errorhint"}/></FormHelperText>*/}
        </h5>
      </Grid>
      <Grid item xs={12}>
        <Translate id={"idd.questions." + name} />
        <Dialog
          open={info}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => showInfo(false)}
          maxWidth={"md"}
        >
          <DialogTitle id={"info-dialog-" + name}><Translate id={"idd.titles." + name} />
            <IconButton aria-label="close" className={props.classes.closeButton} onClick={() => showInfo(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id={"info-description-" + name}>
              <Translate id={"idd.help." + name + ".info"} />
              <br/>
              {stringValue !== "" && <strong><Translate id={"idd.help." + name + "." + stringValue} /></strong>}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {values.map(v =>
              <FormControlLabel
                key={v.key}
                value={v.key}
                checked={stringValue === v.key}
                control={<Radio color="primary" />}
                label={<Translate id={"idd.answers." + v.key} />}
                onChange={handleDialogSelect} />
            )}
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid item xs={12}>
        <FormControl error={!disabled && value === undefined} component="fieldset">
          <RadioGroup row aria-label={name} value={value === undefined ? "" : stringValue} name={name} /*defaultValue="top"*/ onChange={handleFunc} disabled={disabled}>
            {values.map(v =>
              <FormControlLabel
                key={v.key}
                value={v.key}
                control={<Radio color="primary" />}
                label={<Translate id={"idd.answers." + v.key} />}
               />
            )}

          </RadioGroup>
        </FormControl>
      </Grid>
      {additional &&
        <Grid item xs={12} className={classes.additional}>
          {additional}
        </Grid>
      }
    </Grid>
  );
};

const AlertInfo = props => <Dialog
  open={props.open}
  onClose={props.handleClose}
  aria-labelledby={props.id}
  aria-describedby={props.id}
>
  <DialogTitle id={props.id}>{props.title}</DialogTitle>
  <DialogContent>
    <DialogContentText id={props.id}>
      {props.children}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={props.handleClose} size={props.size} color="porsche" autoFocus>
      {props.ok}
    </Button>
  </DialogActions>
</Dialog>;

class InsuranceConfig extends React.Component {
  state = {
    haftpflicht: this.props.selected_insurance && this.props.selected_insurance.idd && this.props.selected_insurance.idd.haftpflicht, //undefined,
    vollkasko: this.props.selected_insurance && this.props.selected_insurance.idd && this.props.selected_insurance.idd.vollkasko,
    //gap: undefined,
    zub: this.props.selected_insurance && this.props.selected_insurance.idd && this.props.selected_insurance.idd.zub,
    //abwicklung: undefined,
    hinterlegung: this.props.selected_insurance && this.props.selected_insurance.idd && this.props.selected_insurance.idd.hinterlegung,
    kaskoOptions: this.props.selected_insurance && this.props.kaskoOptions && this.props.kaskoOptions.filter(value => value.includes("VOLLKASKO")).map(value => Number(value.replace("VOLLKASKO", "").trim())),
    selbstbehalt: (this.props.selected_insurance && this.props.selected_insurance.idd) ? this.props.selected_insurance.idd.selbstbehalt : (this.props.kaskoOptions && this.props.kaskoOptions.find(value => value === this.props.config.kasko) ?
        Number(this.props.kaskoOptions.find(value => value === this.props.config.kasko).replace("VOLLKASKO", "")) : undefined),
    showInfoDialog: this.props.selected_insurance === undefined || this.props.selected_insurance.idd === undefined
  };

  constructor(props) {
    super(props);
    //console.log("InsuranceConfig constructor", props);
    if (props.selected_insurance === undefined && props.match.params.model) { // redirect
      props.history.push("/config/" + props.match.params.model + "/" + props.match.params.color);
    }
    if(props.selected_insurance === undefined && props.match.params.model === undefined) {
      props.history.push("/");
    }
  }

  /*handleChangeMode = (event, mode) => {
    console.log("Insurance handleChangeMode props", this.props);
    console.log("Insurance handleChangeMode mode", mode);
    this.props.toggleInsuranceMode(mode);
    // switch mode: 0 ... without, 1 ... with
    this.props.modifyInsuranceConfig("hp", mode === 1);
  };*/

  /*handleCheckBoxChange = name => e => {
    console.log("Insurance handleCheckBoxChange", e);
    this.props.modifyInsuranceConfig(name, e.target.checked);
  };*/

  handleSelectQuestion = e => {
    //console.log("Insurance handleSelectQuestion", { ...this.state, [e.target.name]: values.find(v => v.key === e.target.value).val });
    this.setState({ ...this.state, [e.target.name]: values.find(v => v.key === e.target.value).val });
  };

  handleSelect = e => {
    //console.log("Insurance handleSelectQuestion", { ...this.state, [e.target.name]: values.find(v => v.key === e.target.value).val });
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  handleChange = e => {
    //console.log("Insurance handleChange", e);
    this.props.modifyInsuranceConfig(e.target.name, e.target.value);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.state.selbstbehalt && prevState.selbstbehalt !== this.state.selbstbehalt) {
      this.props.modifyInsuranceConfig("kasko", "VOLLKASKO " + this.state.selbstbehalt);
    }
    if(this.state.vollkasko !== undefined && prevState.vollkasko !== this.state.vollkasko) {
      this.props.modifyInsuranceConfig("kasko", this.state.vollkasko ? "VOLLKASKO " + this.state.selbstbehalt : "KEINE KASKO");
      this.props.modifyInsuranceConfig("vollkasko", this.state.vollkasko);
    }
    if(this.state.haftpflicht !== undefined && prevState.haftpflicht !== this.state.haftpflicht) {
      this.props.modifyInsuranceConfig("hp", this.state.haftpflicht);
    }
    if(this.state.zub !== undefined && prevState.zub !== this.state.zub) {
      this.props.modifyInsuranceConfig("zub", this.state.zub);
    }
    if(this.state.hinterlegung !== undefined && prevState.hinterlegung !== this.state.hinterlegung) {
      this.props.modifyInsuranceConfig("hinterlegung", this.state.hinterlegung);
    }
    // Verify all questions
    if(this.state !== prevState &&
      this.state.haftpflicht !== undefined
      && this.state.vollkasko !== undefined
      && this.state.zub !== undefined
      && this.state.hinterlegung !== undefined) {
      this.props.updateInsurance(this.state)
    }
  }
  /**
   * Save all modifications
   */
  componentWillUnmount() {
      this.props.saveOffer();
  }

  render() {
    //console.log("Insurance props", this.props);
    //console.log("Insurance state", this.state);
    const { width, classes } = this.props;
    return (
      <Grid container className="config">
        <AlertInfo
          open={this.state.showInfoDialog}
          id="no-articles"
          title={<Translate id={"alert.insuranceInfo.title"} />}
          ok={<Translate id={"alert.insuranceInfo.ok"} />}
          handleClose={() => this.setState({ showInfoDialog: false })}
          size={isWidthDown("xs", width) ? "sm" : undefined}
        >
          <Translate id={"alert.insuranceInfo.text"} />
        </AlertInfo>

        <Grid item xs={12} className={classes.configLeft}>
          <h2><Translate id="idd.title"/></h2>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className={classes.configLeft}>
          <QuestionYesNo name="haftpflicht" value={this.state.haftpflicht} handleFunc={this.handleSelectQuestion} classes={classes}/>
          <QuestionYesNo name="vollkasko" value={this.state.vollkasko} handleFunc={this.handleSelectQuestion} classes={classes} additional={
            <FormControl>
              <Select
                //labelId="label-selbstbehalt"
                //id="select-selbstbehalt"
                value={this.state.selbstbehalt || ''}
                onChange={this.handleSelect}
                fullWidth
                name="selbstbehalt"
                disabled={!this.state.vollkasko}
              >
                {this.state.kaskoOptions && this.state.kaskoOptions.map((value, i) => <MenuItem key={i} value={value}>{ccyFormat(value)}</MenuItem>)}
              </Select>
              <FormHelperText><Translate id="hints.sb"/></FormHelperText>
            </FormControl>
          } />
          {/*<QuestionYesNo name="gap" value={this.state.gap} handleFunc={this.handleChange} disabled={!this.state.vollkasko}/>*/}
          <QuestionYesNo name="zub" value={this.state.zub} handleFunc={this.handleSelectQuestion} disabled={!this.state.vollkasko} classes={classes} />
          {/*<QuestionYesNo name="abwicklung" value={this.state.abwicklung} handleFunc={this.handleChange} disabled={!this.state.vollkasko}/>*/}
          <QuestionYesNo name="hinterlegung" value={this.state.hinterlegung} handleFunc={this.handleSelectQuestion} disabled={!this.state.haftpflicht} classes={classes} /*className={classes.iddSub}*//>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <InsuranceInfoDetail />
        </Grid>
      </Grid>
    );
  }
}

InsuranceConfig.propTypes = {
  classes: PropTypes.object.isRequired,
  mode: PropTypes.number.isRequired,
  config: PropTypes.object,
  cart: PropTypes.array,
  insurance: PropTypes.object,
  kaskoOptions: PropTypes.array,

  modifyInsuranceConfig: PropTypes.func.isRequired,
  updateInsurance: PropTypes.func.isRequired,
  toggleInsuranceMode: PropTypes.func.isRequired,
  saveOffer: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  insurance: state.config.insurance,
  kaskoOptions: state.config.insurance && state.config.insurance.kasko,
  config: state.config.insurance_config,
  selected_insurance: state.config.selected_insurance,
  cart: state.config.cart,
  mode: state.global.insuranceMode // state.config.insurance_config.hp
});

export default withWidth()(withStyles(formsStyle)(withLocalize(
  connect(
    mapStateToProps,
    { modifyInsuranceConfig, toggleInsuranceMode, updateInsurance, saveOffer }
  )(InsuranceConfig)))
);
