import React from "react";
import ImageGallery from "react-image-gallery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import style from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import { CardHeader, isWidthDown, List, ListItem } from "@material-ui/core";
import { getImageUrl } from "../../Image/Image";
import withStyles from "@material-ui/core/styles/withStyles";
import { ccyFormat, timeFormat } from "../../../Utils";
import { Translate } from "react-localize-redux";
import { DetailIcons } from "./ArticleDetailIcons";
import AddCartIcon from "../../Cart/AddCartIcon";
import Badge from "../../Badge/Badge";
import { database_request_url } from "../../../store";
import { DialogActions } from "../../Dialog/DialogSimple";

/*const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});*/

class ArticleDialog extends React.Component {
  state = {
    open: false,
    article: undefined,
    additional: undefined,
    disabled: false
  };

  handleClose() {
    this.setState({open: false});
  }

  show(article, disabled) {
    this.setState({
      open: true,
      article: article,
      disabled: disabled
    });
    // fetch additional aricles:
    this.fetchAdditionalArticle(article);
  }

  fetchAdditionalArticle = article => {
    if(article === undefined)
      return;
    fetch(database_request_url + "/articlesadd/" + article.id,
      {
        headers: {
          'Accept': 'application/json; charset=utf-8',
          'Content-Type': 'application/json; charset=utf-8'
        },
        method: "GET",
      })
      .then(response => {
        if(response.ok) {
          return response.json();
        }
        throw new Error('no additional items found.');
      })
      .then(data => {
        this.setState({
          additional: data
        });
      })
      .catch(function(error) {
        console.log('There has been a problem with your fetch operation: ', error.message);
      });
  };

  render() {
    const { classes, width } = this.props;
    const { open, article, additional, disabled } = this.state;

    if(!article) return "";

    const imageData = article.images && article.images.map(image => {
      return {
        original: getImageUrl(image.filename, 640),
        thumbnail: getImageUrl(image.filename, 160)
      }
    });

    return (
      <Dialog
        onClose={this.handleClose.bind(this)}
        aria-labelledby={article.sku}
        open={open}
        fullWidth
      >
        <DialogTitle id={article.sku} onClose={this.handleClose.bind(this)}>
          {article.description}
        </DialogTitle>
        <DialogContent>

          <GridContainer>
            <GridItem xs={12} sm={6} lg={5} className={classes.mlAuto}>
              <CardHeader
                avatar={<AddCartIcon article={article} secondFunction={this.handleClose.bind(this)} disabled={disabled} />}
                title={isWidthDown("xs", width) ? article.sku : ("Art. Nr.: " + article.sku)}
                subheader={ccyFormat(article.price)}
              />
              <p>{article.descriptionadd}</p>
              {article.labor > 0 && <p><strong><Translate id={"montage"} />:</strong> {timeFormat(article.labor)}</p>}
              {/*article.sizes.length > 0 && <p><strong>Größen:</strong> {article.sizes.map(s => <Badge key={s.sizecode}>{s.size}</Badge>)}</p>*/}
              {article.B03_GrößenVonBis && <p><strong>Größen:</strong> <Badge>{article.B03_GrößenVonBis}</Badge></p>}
              {additional && additional.length > 0 && <>
                <h4>Zugehörige Artikel</h4>
                <List>
                  {additional.map(add => <ListItem key={add.id}>
                    {add.description}
                    <br/>
                    {add.sku} {ccyFormat(add.price)}
                  </ListItem>)}
                </List>
              </>}

              <DetailIcons info={article.info} />
            </GridItem>
            <GridItem xs={12} sm={6} lg={5} className={classes.mrAuto}>
              <ImageGallery
                showFullscreenButton={false}
                showPlayButton={false}
                startIndex={0}
                autoPlay={true}
                items={imageData}
                showThumbnails={imageData.length > 1}
                renderLeftNav={(onClick, disabled) => {
                  return (
                    <button
                      className='image-gallery-left-nav'
                      disabled={disabled}
                      onClick={onClick}
                    />
                  );
                }}
                renderRightNav={(onClick, disabled) => {
                  return (
                    <button
                      className='image-gallery-right-nav'
                      disabled={disabled}
                      onClick={onClick}
                    />
                  );
                }}
              />
            </GridItem>
          </GridContainer>

        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose.bind(this)} size={"sm"} color="primary">Schließen</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(style)(ArticleDialog);
