import {
  UPDATE_FINANCING,
  UPDATE_INSURANCE,
  ADD_ARTICLE,
  FETCH_CART_FINANCE,
  FETCH_OFFER,
  REMOVE_ARTICLE,
  RESET_MODEL,
  RESET_SERIES,
  SAVE_OFFER,
  SELECT_SIZE,
  SET_COLOR,
  SET_MODE,
  SET_MODEL,
  SET_SERIES,
  MODIFY_FINANCING,
  FETCH_DEALERS,
  SET_DEALER,
  SET_CUSTOMER,
  SET_DELIVERY,
  MODIFY_INSURANCE,
  ERROR,
  SET_NEWSLETTER
} from "./types";

import { database_request_url } from "../store";

export const CASH = "OHNE FINANZIERUNG";

const prepareConfig = (finance_config, insurance_config) => {
  return finance_config !== undefined ?
    {
      ...finance_config,
      ...insurance_config
    }
    : false;
}

export const fetchCartFinance = () => (dispatch, getState) => {
  const state = getState();
  //console.log("fetchCartFinance state", state);
  const { id, price } = state.config.model; // clean a little data
  const cart = state.config.cart.map(item => ({id: item.id}));
  const vehicle = { id, price };
  const config = prepareConfig(state.config.finance_config, state.config.insurance_config);
  //console.log("fetchCartFinance configuration", { vehicle, cart, config });
  fetch(database_request_url + "/calculate",
    {
      headers: {
        'Accept': 'application/json; charset=utf-8',
        'Content-Type': 'application/json; charset=utf-8'
      },
      method: "POST",
      body: JSON.stringify({ vehicle, cart, config })
  })
  .then(response => {
    return response.json()
  })
  .then(data => {
    //console.log("fetchCartFinance data", data);
    dispatch({
      type: FETCH_CART_FINANCE,
      payload: data
    });
  })
  .catch(err => {
    console.error("caught it!", err);
  });
};
/**
 * When changing the financing config.
 * @param property
 * @param value
 * @returns {(function(*): void)|*}
 */
export const modifyFinancingConfig = (property, value) => dispatch => {
  //console.log("modifyFinancingConfig Action");
  dispatch({
    type: MODIFY_FINANCING,
    property: property,
    value: value
  });
  dispatch(fetchCartFinance());
};
/**
 * saves the financing configuration
 * @returns {(function(*): void)|*}
 */
export const updateFinancing = () => dispatch => {
  //console.log("updateFinancing Action");
  dispatch({
    type: UPDATE_FINANCING
  });
  /*dispatch({
    type: TOGGLE_FINANCING_MODE,
    mode: config.type === CASH ? 0 : 1
  });*/
  dispatch(saveOffer());
};
export const modifyInsuranceConfig = (property, value) => dispatch => {
  dispatch({
    type: MODIFY_INSURANCE,
    property: property,
    value: value
  });
  dispatch(fetchCartFinance());
};
/**
 * Saves the insurance configuration.
 * @returns {(function(*): void)|*}
 */
export const updateInsurance = idd => (dispatch, getState) => {
  dispatch({
    type: UPDATE_INSURANCE,
    idd: getState().idd && !idd ? getState().idd : idd
  });
};
export const setMode = (key, value) => dispatch => {
  dispatch({
    type: SET_MODE,
    key: key,
    value: value
  });
  dispatch(fetchCartFinance());
};

export const selectArticleSize = (article, size) => dispatch => {
  dispatch({
    type: SELECT_SIZE,
    id: article.id,
    size: size
  });
  dispatch(saveOffer());
};
export const addToCart = article => dispatch => {
  dispatch({
    type: ADD_ARTICLE,
    article: article
  });
  dispatch(fetchCartFinance());
  dispatch(saveOffer());
};
export const removeFromCart = article => dispatch => {
  dispatch({
    type: REMOVE_ARTICLE,
    article: article
  });
  dispatch(saveOffer());
};
export const setNewsletter = value => dispatch => {
  dispatch({
    type: SET_NEWSLETTER,
    value: value
  });
};

export const prepareOffer = config => {
  const { model, cart, color, sizes, offer, dealer, finance, selected_finance, selected_insurance, customer, dealerPredefined, newsletter } = config;
  //let config = (state.cart !== undefined && state.cart.config !== undefined) ? state.cart.config : false;
  if(model === undefined)
    return;

  if(offer !== undefined && offer.status !== "SAVED" && offer.status !== "CREATED") {
    console.error("ERROR - Do not update an already submitted offer.", offer);
    return;
  }

  return {
    //version: state.config.status !== undefined ? state.config.status : 1,
    id: offer && offer.id,
    cart: {
      vehicle: {
        id: model.id,
        sku: model.sku,
        price: model.price
      },
      items: cart.map(item => ({
        id: item.id,
        price: item.price,
        sku: item.sku,
        size: sizes[item.id]
      })),
      color: {
        id: color.id,
        name: color.name
      }
    },
    dealer: dealer && { id: dealer.id }, // !== undefined ? dealer.id : null,
    delivery: offer && offer.delivery,
    data: {
      config: prepareConfig(selected_finance, selected_insurance),
      financing: {
        ...selected_finance,
        tariff: finance.vehicle.tarifgruppe,
        interests: finance.result.interests,
        effective: finance.result.effective_zins,
        effective_kasko: finance.result.effective_kasko,
        provision: finance.result.provision,
        monthly: finance.result.monthly,
        deposit_perc: finance.result.deposit_perc,
        deposit_euro: finance.result.deposit_euro,
        remainder_perc: finance.result.remainder_perc,
        remainder_euro: finance.result.remainder_euro
      },
      insurance: {
        ...selected_insurance
      },
      idd: selected_insurance ? selected_insurance.idd : null,
      predefinedDealer: dealerPredefined,
      newsletter: newsletter
    },
    customer: customer !== undefined ? customer : null
  };
};
export const saveOffer = () => (dispatch, getState) => {
  const state = getState();
  console.debug("saveOffer content", state);
  const requestBody = prepareOffer(state.config);
  console.debug("saveOffer requestBody", requestBody);
  fetch(database_request_url + "/offer",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody)
    })
    .then(response =>  {
      if(response.status === 200){
        return response.json();
      } else {
        throw new Error("error.offer");
      }})
    .then(data => {
      console.debug("saveOffer response", data);
      dispatch({
        type: SAVE_OFFER,
        payload: data
      });
    })
    .catch(err => {
      console.error("caught it!", err);
    });
};
export const fetchOffer = code => dispatch => {
  fetch(database_request_url + "/offer/" + code)
    .then(response => {
      console.log("fetchOffer fetched: ", response);
        if(response.status === 200){
          return response.json();
        } else {
          throw new Error("error.offer");
        }})
    .then(data => {
      console.debug("Offer Data", data);
      const config = data.data.config;
      dispatch({
        type: FETCH_OFFER,
        offer: {
          id: data.id,
          number: data.number,
          status: data.status,
          delivery: data.delivery,
          updated: data.updated,
          created: data.created,
          code: data.ordernumber,
          received: data.received
        },
        articles: data.articles,
        //cart: data.cart, // contains the calculated values
        //code: code === data.code,
        finance_config: {
          deposit: config.deposit,
          mode: config.mode,
          payments: config.payments,
          remainder: config.remainder,
          type: config.type
        },
        insurance_config: {
          hp: config.hp,
          kasko: config.kasko,
          accessories: config.zub,
          idd: data.data.idd
        },
        predefinedDealer: data.data.predefinedDealer,
        newsletter: data.data.newsletter
      });
       // if (data.articles)
        //  dispatch(importCart(data.articles, data.sizes));
        //if(code === data.code) { // also assign private data, if the full code was supplied
      dispatch({
        type: SET_MODEL,
        model: data.model
      });
      data.dealer !== null && data.dealer !== undefined &&
        dispatch({
          type: SET_DEALER,
          dealer: data.dealer,
          predefined: data.data.predefinedDealer
        });
      dispatch({
        type: SET_COLOR,
        color: data.color
      });
      data.customer !== null && data.customer !== undefined &&
        dispatch({
          type: SET_CUSTOMER,
          customer: data.customer
        });
      data.sizes.forEach(({article_id, ...size}) =>
        dispatch({
          type: SELECT_SIZE,
          id: article_id,
          size: size
        }));
      dispatch(fetchCartFinance());
        /*if (data.financing)
          dispatch(updateFinancing(data.financing));
        if (data.insurance)
          dispatch(updateInsurance(data.insurance));
          */
        //}
        // setTimeout(() => dispatch(fetchCartFinance()), 500); // FIXME delayed execution (because it requires the model and the finance config)
      /*} else {
        dispatch({type: ERROR, id: ERROR_FETCHING_OFFER});
      }*/
    })
    .catch(error => {
      console.error("SOMETHING WENT WRONG", error);
      dispatch({
        type: ERROR,
        id: "ERROR_FETCHING_OFFER"
      });
    });
};
export const submitOffer = () => (dispatch, getState) => {
  const state = getState();
  console.debug("submitOffer content", state);
  const requestBody = prepareOffer(state.config);
  console.debug("saveOffer requestBody", requestBody);
  fetch(database_request_url + "/offer",
  {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestBody)
  })
  .then(response => response.json())
  .then(data => {
    console.debug("submitOffer response", data);
    dispatch({
      type: SAVE_OFFER,
      payload: data
    });
  })
  .catch(err => {
    console.error("caught it!", err);
  });
  //console.log("submitOffer");
  //dispatch(createPDF());
  //dispatch(saveOffer());
  //dispatch(sendOfferMail());
};

export const setSeries = series => dispatch => {
  dispatch({
    type: SET_SERIES,
    payload: series
  });
};
export const setModel = (model, color) => dispatch => {
  dispatch({
    type: SET_MODEL,
    model: model
  });
  dispatch({
    type: SET_COLOR,
    color: color
  });
};
export const resetSeries = () => dispatch => {
  dispatch({
    type: RESET_SERIES
  });
};
export const resetModel = () => dispatch => {
  dispatch({
    type: RESET_MODEL
  });
};

export const fetchDealers = () => dispatch => {
  fetch(database_request_url + "/dealers")
    .then(response => {
      return response.json();
    })
    .then(data => {
      dispatch({
        type: FETCH_DEALERS,
        data: data
      });
    })
    .catch(err => {
      console.error("caught it!", err);
    });
};
export const fetchDealerById = dealer => dispatch => {
  fetch(database_request_url + "/dealer/" + dealer)
    .then(response => {
      return response.json();
    })
    .then(data =>
      dispatch({
        type: SET_DEALER,
        dealer: data,
        predefined: true
      })
    )
    .catch(err => {
      console.error("caught it!", err);
    });
};
export const setDealer = (dealer, predefined = false) => dispatch => {
  console.log("setDealer", dealer);
  dispatch({
    type: SET_DEALER,
    dealer: dealer,
    predefined: predefined
  });
  dispatch(saveOffer());
};
export const setDelivery = delivery => dispatch => {
  dispatch({
    type: SET_DELIVERY,
    delivery: delivery
  });
  dispatch(saveOffer());
};
export const setCustomer = (customer, valid) => dispatch => {
  dispatch({
    type: SET_CUSTOMER,
    customer: customer
  });
  if(valid) dispatch(saveOffer());
};
