import {
  TOGGLE_FINANCING_MODE,
  TOGGLE_FINANCING_VIEW, TOGGLE_INSURANCE_MODE, TOGGLE_INSURANCE_VIEW, TOGGLE_ARTICLES_VIEW,
  SHOW_SERIES, SHOW_MODEL
} from "../actions/types";

const initialState = {
  financingView: false,
  insuranceView: false,
  configurationValid: false, // should be true, if the configuration was accepted once (NEXT STEP)
  articlesView: false,
  financingMode: 1,
  insuranceMode: 1,
  iddNext: undefined,
  showSeries: undefined,
  showModel: undefined,
  showColorIndex: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FINANCING_VIEW:
      return {
        ...state,
        financingView: !state.financingView,
        insuranceView: false, // close if it was open
        configurationValid: true
      };

    case TOGGLE_INSURANCE_VIEW:
      return {
        ...state,
        insuranceView: !state.insuranceView,
        financingView: false // close if it was open
      };

    case TOGGLE_ARTICLES_VIEW:
      return {
        ...state,
        articlesView: !state.articlesView
      };

    case TOGGLE_FINANCING_MODE:
      return {
        ...state,
        financingMode: action.mode === undefined? (state.financingMode === 1 ? 0 : 1) : action.mode
      };

    case TOGGLE_INSURANCE_MODE:
      return {
        ...state,
        insuranceMode: state.insuranceMode === 1 ? 0 : 1
      };

    case SHOW_SERIES:
      return {
        ...state,
        showSeries: action.series
      };

    case SHOW_MODEL:
      return {
        ...state,
        showModel: action.model,
        showColorIndex: action.color
      };

    default:
      return state;
  }
}
