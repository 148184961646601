import React from 'react';
import PropTypes from 'prop-types';
import ArticleCard from "./Items/ArticleCard";

import { Grid } from '@material-ui/core';

class ArticleCardsGrid extends React.Component {
  render() {
    //console.debug("ArticleCardsGrid articles", this.props.articles);
    return (
      <Grid container spacing={2} justifyContent="flex-start">
        {this.props.articles.map((a, i) => (
          <Grid key={i} item xs={12} sm={6} md={6} lg={4}>
            <ArticleCard article={a} />
          </Grid>
        ))}
      </Grid>
    );
  }
}

ArticleCardsGrid.propTypes = {
  articles: PropTypes.array.isRequired,
  selected: PropTypes.object
};

export default ArticleCardsGrid;
