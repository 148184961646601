import React from "react";
import { Translate } from "react-localize-redux";
import PropTypes from "prop-types";
import countryList from "react-select-country-list";
import { connect } from "react-redux";
import { saveOffer, setCustomer } from "../../actions/configAction";
import EditIcon from "@material-ui/icons/Edit";

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  MenuItem,
  Select, FormHelperText, Button, withStyles
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Newsletter from "./Newsletter";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import _ from "lodash";
import { defaultBoxShadow } from "../../assets/jss/material-kit-react";
import tooltip from "../../assets/jss/material-kit-react/tooltipsStyle";
import { FinalHeader } from "./FinalHeader";
import Spinner from "./Spinner";
import { DialogSimple } from "../Dialog/DialogSimple";

const styles = theme => ({
  root: {
    //   width: '100%',
    //   marginTop: theme.spacing(3),
    //   overflowX: 'auto',
    //flexGrow: 1,
  },
  /*grow: {
    flexGrow: 1,
  },*/
  /*menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },*/
  /*iconButton: {
    marginLeft: 30,
  },*/
  // TODO add margin to all buttons!
  table: {
    minWidth: 700,
    maxWidth: 1150,
    margin: "0 auto",
  },

  finalConditions: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    "& p": {
      fontSize: "0.75rem!important"
    }
  },
  spacingContianer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  ...tooltip,
  greeting: {
    textTransform: "initial",
    paddingBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      //paddingLeft: 10,
      //paddingRight: 10,
      marginTop: 64
    }
  }
});

class PersonalDataWide extends React.Component {
  state = {
    data: {
      //gender: this.props.customer !== null ? this.props.customer.gender : undefined,
      //firstname: this.props.customer !== undefined && this.props.customer !== null && !_.isEmpty(this.props.customer.firstname),
      //lastname: this.props.customer !== undefined && this.props.customer !== null && !_.isEmpty(this.props.customer.lastname),
      //street: true,
      //number: true,
      //zip: true,
      //city: true,
      //country: true,
      //phone: this.props.customer.home !== null ? this.props.customer.phone : "",
      //email: this.props.customer !== undefined && this.props.customer !== null && !_.isEmpty(this.props.customer.email),
      ...this.props.customer,
      country: this.props.customer ? this.props.customer.country : "AT",
      phone: this.props.customer && this.props.customer.phone !== null ? this.props.customer.phone : ""
    },
    valid: {
      gender: this.props.customer !== undefined && this.props.customer !== null && !_.isEmpty(this.props.customer.gender),
      firstname: this.props.customer !== undefined && this.props.customer !== null && !_.isEmpty(this.props.customer.firstname),
      lastname: this.props.customer !== undefined && this.props.customer !== null && !_.isEmpty(this.props.customer.lastname),
      street: this.props.customer !== undefined && this.props.customer !== null && !_.isEmpty(this.props.customer.street),
      number: this.props.customer !== undefined && this.props.customer !== null && !_.isEmpty(this.props.customer.number),
      zip: this.props.customer !== undefined && this.props.customer !== null && !_.isEmpty(this.props.customer.zip),
      city: this.props.customer !== undefined && this.props.customer !== null && !_.isEmpty(this.props.customer.city),
      country: true,
      phone: true,
      email: this.props.customer !== undefined && this.props.customer !== null && !_.isEmpty(this.props.customer.email)
    },
    edit: true, //this.props.edit || this.props.customer === undefined
    modified: false
  };

  constructor(props) {
    super(props);
    this.countries = countryList().getData();

    if (props.offer === undefined && props.match.params.code) {
        props.history.push("/offer/" + props.match.params.code);
    }
  };

  handleChange = event => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value
      },
      valid: {
        ...this.state.valid,
        [event.target.name]: this.validateField(event.target.name, event.target.value)
      },
      modified: true
    });
  };

  validate = props => {
    return {
      gender: props.customer !== undefined && props.customer !== null && !_.isEmpty(props.customer.gender),
      firstname: props.customer !== undefined && props.customer !== null && !_.isEmpty(props.customer.firstname),
      lastname: props.customer !== undefined && props.customer !== null && !_.isEmpty(props.customer.lastname),
      street: props.customer !== undefined && props.customer !== null && !_.isEmpty(props.customer.street),
      number: props.customer !== undefined && props.customer !== null && !_.isEmpty(props.customer.number),
      zip: props.customer !== undefined && props.customer !== null && !_.isEmpty(props.customer.zip),
      city: props.customer !== undefined && props.customer !== null && !_.isEmpty(props.customer.city),
      country: true,
      phone: true,
      email: props.customer !== undefined && props.customer !== null && !_.isEmpty(props.customer.email)
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.customer !== this.props.customer && this.props.customer) {
      this.setState({
        data: {
        ...this.props.customer,
          country: this.props.customer ? this.props.customer.country : "AT"
        },
        valid: this.validate(this.props)
      });
    }
  }

  componentWillUnmount() {
    if(this.state.modified && this.props.offer)
      this.props.saveOffer();
  }

  handleUpdate = () => {
    const values = Object.values(this.state.valid);
    this.props.setCustomer(this.state.data, values.reduce((prev,curr) => prev && curr, true));
    //this.setState({ edit: false });
  };

  handleEdit = () => {
    this.setState({ edit: true });
  };

  validateField(fieldName, value) {
    switch(fieldName) {
      case "gender": return value !== undefined;
      case "firstname":
      case "lastname": return value !== undefined && value !== "";
      case "email": return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
      case "street":
      case "zip":
      case "city":
      case "country":
      case "phone":
      default:
        return true;
    }
  }

  render() {
    const { classes, model, offer } = this.props;
    const { data, valid, edit } = this.state;
    //const country = this.countries.find(e => e.value === data.country);
    if(offer === undefined)
      return <Spinner />;

    //console.log("PersonalData state", this.state);
    //console.log("PersonalData props", this.props);
    // FIXME with spacing it did not work.
    return (
      <Grid container direction="row" justifyContent="center" spacing={2} className={classes.spacingContianer}>
        <Grid item xs={12} md={12} className={classes.greeting}>
          <h2>
            <Translate id="final.title" />
          </h2>
          <h4>
            <Translate id="final.header" data={{model: model.name, brand: model.series.brand}}/> <Translate id="final.headerCustomer"/>
          </h4>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <FormControl disabled={!edit} component="fieldset" className={classes.formControl} error={!valid.gender}>
                <RadioGroup
                  row
                  aria-label={<Translate id="personal.gender" />}
                  name="gender"
                  className={classes.group}
                  //value={data.gender && ""}
                  //checked={stringValue === v.key}
                  onChange={this.handleChange}
                  onBlur={this.handleUpdate}
                >
                  <FormControlLabel value="male" control={<Radio color="primary"  checked={data.gender === "male"} />} label={<Translate id="personal.male"/>} />
                  <FormControlLabel value="female" control={<Radio color="primary" checked={data.gender === "female"}/>} label={<Translate id="personal.female"/>} />
                </RadioGroup>
                <FormHelperText component="label"><Translate id="personal.gender"/></FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <TextField
                id="firstname"
                name="firstname"
                onChange={this.handleChange}
                onBlur={this.handleUpdate}
                //label={<Translate id="personal.firstname"/>}
                error={!valid.firstname}
                value={data.firstname}
                disabled={!edit}
                helperText={<Translate id="personal.firstname"/>}
                fullWidth
                margin="normal"
                //InputLabelProps={{
                //  shrink: true,
                //}}
              />
            </Grid>
            <Grid item xs={12} sm={7} md={8}>
              <TextField
                id="lastname"
                name="lastname"
                onChange={this.handleChange}
                onBlur={this.handleUpdate}
                error={!valid.lastname}
                //label={<Translate id="personal.lastname"/>}
                value={data.lastname}
                disabled={!edit}
                //style={{ margin: 8 }}
                //placeholder="Placeholder"
                helperText={<Translate id="personal.lastname"/>}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="street"
                name="street"
                onChange={this.handleChange}
                onBlur={this.handleUpdate}
                error={!valid.street}
                //label={<Translate id="personal.street"/>}
                value={data.street}
                margin="normal"
                disabled={!edit}
                helperText={<Translate id="personal.street"/>}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="number"
                name="number"
                onChange={this.handleChange}
                onBlur={this.handleUpdate}
                error={!valid.number}
                //label={<Translate id="personal.number"/>}
                value={data.number}
                margin="normal"
                disabled={!edit}
                helperText={<Translate id="personal.number"/>}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="zip"
                name="zip"
                onChange={this.handleChange}
                onBlur={this.handleUpdate}
                error={!valid.zip}
                //label={<Translate id="personal.zip"/>}
                value={data.zip}
                margin="normal"
                disabled={!edit}
                helperText={<Translate id="personal.zip"/>}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="city"
                name="city"
                onChange={this.handleChange}
                onBlur={this.handleUpdate}
                error={!valid.city}
                //label={<Translate id="personal.city"/>}
                value={data.city}
                fullWidth
                margin="normal"
                disabled={!edit}
                helperText={<Translate id="personal.city"/>}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} disabled={!edit}>
                <Select
                  style={{ marginTop: 16 }}
                  value={data.country}
                  onChange={this.handleChange}
                  onBlur={this.handleUpdate}
                  inputProps={{
                    name: 'country',
                    id: 'country',
                  }}
                >
                  {this.countries.map(e =>
                    <MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>
                  )}
                </Select>
                <FormHelperText><Translate id="personal.country"/></FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={6} xs={12}>
          <Grid item xs={12}>
            <TextField
              id="phone"
              name="phone"
              onChange={this.handleChange}
              onBlur={this.handleUpdate}
              error={!valid.phone}
              //label={<Translate id="personal.phone"/>}
              value={data.phone}
              fullWidth
              margin="normal"
              disabled={!edit}
              helperText={<Translate id="personal.phone"/>}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              name="email"
              onChange={this.handleChange}
              onBlur={this.handleUpdate}
              error={!valid.email}
              //label={<Translate id="personal.email"/>}
              value={data.email}
              fullWidth
              margin="normal"
              disabled={!edit}
              helperText={<Translate id="personal.email"/>}
            />
          </Grid>
          <Newsletter />
        </Grid>
      </Grid>
    );
  }
}

PersonalDataWide.propTypes = {
  classes: PropTypes.object.isRequired,
  customer: PropTypes.object,
  model: PropTypes.object,
  offer: PropTypes.object,
  editable: PropTypes.bool,
  setCustomer: PropTypes.func.isRequired,
  saveOffer: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  customer: state.config.customer,
  model: state.config.model,
  offer: state.config.offer
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, { setCustomer, saveOffer })(PersonalDataWide));
