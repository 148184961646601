import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { ccyFormat, dateFormat, getPaymentUnitText, numFormat, percFormat } from "../../Utils";
import Spinner from "../Configurator/Spinner";
import { CASH } from "../../actions/configAction";
import FinanceAccordion from "./FinanceAccordion";
import { Translate } from "react-localize-redux";
import { Box, isWidthDown, Table, TableBody, TableCell, TableRow, withStyles, withWidth } from "@material-ui/core";
import { ReactComponent as Logo } from "assets/img/porsche_bank_insurance_white.svg";
import { defaultBoxShadow } from "../../assets/jss/material-kit-react";
import DialogFinance from "./DialogFinance";
import { connect } from "react-redux";
import { toggleFinancingView } from "../../actions/globalAction";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { datasheet_base_url } from "../../store";

const styles = theme => ({
  porscheHeader: {
    background: "linear-gradient(60deg, #094261, #004665)",
      ...defaultBoxShadow,
      padding: "1rem",
      color: "#fff",
      textTransform: "uppercase",
      fontWeight: 400,
      fontSize: "1.15rem",
  },
  price: theme.price
});

const FinanceInsuranceCardFinal = props => {
  const { classes, finance, insurance, style, editable, model, width } = props;
  console.log("FinanceInsuranceCardFinal", props);
  if(finance.result === undefined)
    return <Spinner/>;
  const sum_items = finance.items.reduce((a, b) => a + (b.price || 0), 0);

  const data =  <FinanceAccordion
    //active={0}
    classes={classes}
    collapses={[
      {
        title: finance.result.name,
        right: ccyFormat(finance.result.monthly),
        active: finance.result.name,
        content:
          (finance.result.name !== CASH &&
            <Grid container>
              <Grid item xs={12}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>{finance.result.name}, Sollzinssatz</TableCell>
                      <TableCell className={classes.price}>{percFormat(finance.result.zins)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Laufzeit</TableCell>
                      <TableCell className={classes.price}>{finance.result.n_payments} {getPaymentUnitText(finance.result.ppy)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Kaufpreis</TableCell>
                      <TableCell className={classes.price}>{ccyFormat(model.price + sum_items)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>- {percFormat(finance.result.deposit_perc)} Anzahlung</TableCell>
                      <TableCell className={classes.price}>{ccyFormat(finance.result.deposit_euro)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>= Finanzierungsbetrag</TableCell>
                      <TableCell className={classes.price}>{ccyFormat(finance.result.totals)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{percFormat(finance.result.remainder_perc)} Restwert</TableCell>
                      <TableCell className={classes.price}>{ccyFormat(finance.result.remainder_euro)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <p>
                  <Translate id="credits.infotext" data={{
                    fees: ccyFormat(finance.result.provision),
                    interests: percFormat(finance.result.zins),
                    effective: percFormat(finance.result.effective_zins),
                    total: ccyFormat(finance.result.total_costs),
                    effective_kasko: percFormat(finance.result.effective_kasko),
                    date: dateFormat(new Date()),
                    fixed: finance.result.fixed ? "fix" : "variabel"
                  }}
                  />
                  <Translate id="credits.disclaimer"/>
                </p>

              </Grid>
              {editable && <Grid item xs={4}>
                <Button color={"secondary"} variant={"outlined"} onClick={props.toggleFinancingView.bind(this)}>Ändern</Button>
                <DialogFinance/>
              </Grid>
              }
            </Grid>
          )
      },
      {
        title: <Translate id="insurance.hpv"/>,
        right: ccyFormat(insurance.hpv),
        active: insurance.hp,
        content:
          <Grid container>
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Pauschalversicherungssumme</TableCell>
                    <TableCell className={classes.price}>15 Mio.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Hinterlegungsverzichtsrabatt</TableCell>
                    <TableCell className={classes.price}>{percFormat(40)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Berechnungsgrundlage (Motorleistung)</TableCell>
                    <TableCell className={classes.price}>{numFormat(finance.vehicle.kw)} kW</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Datenblatt Haftpflicht</TableCell>
                    <TableCell className={classes.price}><a rel="noopener noreferrer" href={"https://ducati-configurator.s3.eu-central-1.amazonaws.com/templates/offer/C30_Generali_ProduktinfoHaftpflicht.pdf"} target={"_blank"}>PDF</a></TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </Grid>
            {/*<Grid  item xs={4}>
                  <Button variant={"outlined"} href={datasheet_base_url + "haftpflicht.pdf"}>Datenblatt Haftpflicht</Button>
                </Grid>*/}
          </Grid>
      },
      {
        title: <Translate id={"insurance.tax"}/>,
        right: ccyFormat(insurance.mvv),
        active: insurance.hp,
        content:
          <Grid container>
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Berechnungsgrundlage 1 (Hubraum)</TableCell>
                    <TableCell className={classes.price}>{numFormat(model.data.displacement, 0)} ccm</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Berechnungsgrundlage 2 (CO2)</TableCell>
                    <TableCell className={classes.price}>{numFormat(model.data.co2,0)} g/km</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Datenblatt {model.name}</TableCell>
                    <TableCell className={classes.price}><a href={datasheet_base_url + model.sku + ".pdf"} rel="noopener noreferrer" target={"_blank"}>PDF</a></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            {/*<Grid  item xs={4}>
                  <Button variant={"outlined"} href={datasheet_base_url + "haftpflicht.pdf"}>Datenblatt Haftpflicht</Button>
                </Grid> */}
          </Grid>
      },
      {
        title: <Translate id={"kaskotype." + insurance.kt}/>,
        right: ccyFormat(insurance.kav + insurance.kbv),
        active: insurance.kt !== "KEINE KASKO",
        content:
          <Grid container>
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Vollkasko Fahrzeug (LP {ccyFormat(model.baseprice)})</TableCell>
                    <TableCell className={classes.price}>{ccyFormat(insurance.kbv)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Vollkasko Zubehör (LP {ccyFormat(sum_items)})</TableCell>
                    <TableCell className={classes.price}>{ccyFormat(insurance.kav)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Seltstbehalt pro Schadensfall</TableCell>
                    <TableCell className={classes.price}>{ccyFormat(Number(insurance.kt.replace("VOLLKASKO", "").trim()))}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Hinterlegungsverzichtsrabatt</TableCell>
                    <TableCell className={classes.price}>{percFormat(35)}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Datenblatt Kasko</TableCell>
                    <TableCell className={classes.price}><a rel="noopener noreferrer" href={"https://ducati-configurator.s3.eu-central-1.amazonaws.com/templates/offer/C31_PBV_ProduktinfoKasko.pdf"} target={"_blank"}>PDF</a></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            {/*<Grid item xs={4}>
                  <Button  variant={"outlined"} href={datasheet_base_url + "kasko.pdf"}>Datenblatt Kasko</Button>
                </Grid>*/}
          </Grid>
      }/*,
          {
            title: <Translate id={"credits.totals"}/>,
            right: ccyFormat(insurance.kav + insurance.kbv + insurance.hpv + insurance.mvv + finance.result.monthly),
            content: <FinanceCredits result={finance.result}/>
          }*/
    ]}
    /*header={{
      title: <Logo height={28} style={{ margin: "-5px 34px" }} />,
      right: <Translate id="finance.price"/>
    }}*/
    summary={{
      title: "Gesamt monatlich", //<Translate id={"credits.totals"}/>,
      content: ccyFormat(insurance.kav + insurance.kbv + insurance.hpv + insurance.mvv + finance.result.monthly)
    }}
  />;

  if(isWidthDown("sm", width))
    return <Box>
      <Box className={classes.porscheHeader}><Logo height={36} /></Box>
      <Box>{data}</Box>
    </Box>
  else
    return <Card style={style}>
      <CardHeader className={classes.porscheHeader}> <Logo height={28} style={{ margin: "-5px 34px" }} />{/*<Hidden smDown>Finanzierung und Versicherung</Hidden>*/}</CardHeader>
      <CardBody>
        {data}
      </CardBody>
    </Card>;
};


FinanceInsuranceCardFinal.propTypes = {
  classes: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
  finance: PropTypes.object.isRequired,
  insurance: PropTypes.object.isRequired,
  editable: PropTypes.bool
};

const mapStateToProps = state => ({
  finance: state.config.finance,
  insurance: state.config.insurance,
  model: state.config.model,
  cart: state.config.cart,
});

export default withWidth()(withStyles(styles)(connect(mapStateToProps, { toggleFinancingView })(FinanceInsuranceCardFinal)));
