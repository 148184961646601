import React from "react";
import PropTypes from "prop-types";
import AddCartIcon from "components/Cart/AddCartIcon";

import { connect } from "react-redux";
import { selectArticle } from "../../../actions/articlesAction";
import { ccyFormat } from "../../../Utils";
import { addToCart, removeFromCart } from "../../../actions/configAction";
import { getImageUrl } from "../../Image/Image";

import { ListItem, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction } from '@material-ui/core';

class Article extends React.Component {
  handleShowDetails(article, topcat) {
    this.props.selectArticle(article, topcat);
    this.props.history.push("/articles/");
  }

  render() {
    const { article, topcat, classes } = this.props;

    return (
      <ListItem alignItems="flex-start" onClick={this.handleShowDetails.bind(this, article, topcat)} classes={classes.article}>
        {article.images.length > 0 &&
          <ListItemAvatar>
              <Avatar
                style={{ borderRadius: 0 }}
                imgProps={{ style: { objectFit: "contain" } }}
                size="medium"
                alt={article.sku}
                src={getImageUrl(article.images[0].filename, 36)}/>
          </ListItemAvatar>
        }
        <ListItemText
          primary={article.description}
          secondary={
            <React.Fragment>
              {ccyFormat(article.priceincl)}{(article.labor > 0)? " zzgl. Montagekosten": ""}
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <AddCartIcon article={article} />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

Article.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired,
  topcat: PropTypes.object.isRequired,
  selectArticle: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  cart: state.config.cart
});

export default connect(
  mapStateToProps,
  { addToCart, removeFromCart, selectArticle }
)(Article);
