import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { removeFromCart } from "../../actions/configAction";
import ArticleCart from "./ArticleCart";
import Spinner from "../Configurator/Spinner";
import { ccyFormat, getPaymentText, timeFormat } from "../../Utils";
import ModelFinance from "./ModelFinance";
import { Translate } from "react-localize-redux";
import { withStyles, Table, TableBody, TableCell, TableHead, TableRow, Box, Grid, isWidthDown, withWidth } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  price: {
    ...theme.price,
    fontSize: "0.875em"
  },
  header: theme.price.header,
  total: theme.price.total,
  disabled: {
    color: theme.palette.secondary.main
  },
  productImage: {
    textAlign: "right"
  },
  /*articleColumn: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    }
  },*/
  separator: {
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: "1.7rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.1rem"
    }
  },
  separatorPadding: {
    paddingTop: 28
  },
  modelTitle: {
    [theme.breakpoints.down("md")]: {
      fontSize: "1.1rem"
    }
  }
});

const SeparatorRow = props =>
  <TableRow>
    <TableCell colSpan={2} className={props.classes.separatorPadding}>
      <Box display="flex" justifyContent="flex-end" alignContent="flex-end">
        <Box flexGrow={1}><Typography className={props.classes.separator}>{props.title}</Typography></Box>
        <Box alignContent="flex-end" className={props.classes.price}>{props.right}</Box>
      </Box>
    </TableCell>
  </TableRow>;

const TextRow = props =>
  <TableRow>
    <TableCell>
      <Grid container>
        <Grid item xs={12} sm={4} md={5}>{" "}</Grid>
        <Grid item xs={12} sm={8} md={7}>{props.title}</Grid>
      </Grid>
    </TableCell>
    <TableCell className={props.classes.price}>{props.right}</TableCell>
  </TableRow>;

const TotalRow = props =>
  <TableRow>
    <TableCell>
      <Grid container>
        <Grid item xs={12} sm={4} md={5}>{" "}</Grid>
        <Grid item xs={12} sm={8} md={7}>{props.title}</Grid>
      </Grid>
    </TableCell>
    <TableCell className={props.classes.total}>{props.right}</TableCell>
  </TableRow>;

class CartDetail extends React.Component {
  render() {
    const { classes, cart, model, color, finance, history, editable, embedded, categories } = this.props;
    //console.log("CartDetail state", this.state);
    //console.log("CartDetail props", this.props);
    //console.log("CartDetail classes", classes);
    if(model === undefined || color === undefined || finance === undefined)
      return <Spinner/>;

    //const summary_finance = getSummaryFinance(finance.items);
    //const summary_cart = getSummaryCart(cart, finance.items);
    //console.log("CartDetail invoice", finance);
    //console.log("CartDetail summary_finance", summary_finance);

    // sort by category
    //const articlesByCat = categories.map(cat => finance.result.mode[cat.key] && cart.filter(article => article.top === cat.id))
    //console.log("CartDetail articlesByCat", articlesByCat);
    const fin_items = categories.map(cat => finance.result.mode[cat.key] && cart.filter(article => article.top === cat.id)).reduce((accumulator, items) => (items) ? accumulator.concat(items) : accumulator, []);
    const cash_items = categories.map(cat => !finance.result.mode[cat.key] && cart.filter(article => article.top === cat.id)).reduce((accumulator, items) => (items) ? accumulator.concat(items) : accumulator, []);

    const showSeparator = fin_items.length > 0 && cash_items.length > 0;
    const fin_subtotal = finance.items.reduce((acc, item) => acc + item.rate, 0) +
      (finance.result.mode.ac ? finance.result.laborTimeRate : 0.0) +
      (finance.result.mode.bk ? finance.vehicle.rate : 0.0);
    const cash_subtotal = cash_items.reduce((acc, item) => acc + item.price, 0);

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} className={classNames(classes.price, classes.header)}>
              {finance.result.mode.bk ? getPaymentText(finance.result.ppy) : <Translate id="cart.price"/>}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ModelFinance model={model} color={color} finance={finance.vehicle} isFinanced={finance.result.mode.bk} classes={classes}/>

          {showSeparator && <SeparatorRow title={<Translate id="finance.selectcash.false"/>} right={getPaymentText(finance.result.ppy)} classes={classes}/>}
          {fin_items.map((article, i) =>
            <ArticleCart key={i} article={article} item={finance.items.find(e => e.id === article.id)} cart={cart} final={!editable} cash={false} />
          )}
          {finance.result.mode.ac && finance.result.laborTime > 0 &&
            <TextRow
              classes={classes}
              title={<><Translate id="cart.montagecosts"/> {timeFormat(finance.result.laborTime)}</>}
              right={ccyFormat(finance.result.laborTimeRate)} />
          }
          {cart.length > 0 && fin_items.length > 0 &&
            <TotalRow
              classes={classes}
              title={<Translate id="cart.subtotal.fin"/>}
              right={ccyFormat(fin_subtotal)} />
          }

          {showSeparator && <SeparatorRow title={<Translate id="finance.selectcash.true"/>} right={<Translate id="cart.price"/>} classes={classes}/>}
          {cash_items.map((article, i) =>
            <ArticleCart key={i} article={article} item={finance.items.find(e => e.id === article.id)} cart={cart} final={!editable} cash={true} />
          )}
          {!finance.result.mode.ac && finance.result.laborTime > 0 &&
            <TextRow
              classes={classes}
              title={<><Translate id="cart.montagecosts"/> {timeFormat(finance.result.laborTime)}</>}
              right={ccyFormat(finance.result.laborTimeEuro)} />
          }
          {cart.length > 0 && cash_items.length > 0 &&
            <TextRow
              classes={classes}
              title={<Translate id="cart.subtotal.cash"/>}
              right={ccyFormat(cash_subtotal)} />
          }

          {finance.result.deposit_euro > 0 &&
            <TextRow
              classes={classes}
              title={<Translate id="hints.deposit"/>}
              right={ccyFormat(finance.result.deposit_euro)} />
          }
          {finance.result.provision > 0 &&
            <TextRow
              classes={classes}
              title={<Translate id="infos.provision"/>}
              right={ccyFormat(finance.result.provision)} />
          }

          <TableRow>
            <TableCell>
              <Box className={classes.total} style={{textAlign: "right"}}>
                 <Translate id="cart.total.cash"/>
              </Box>
            </TableCell>
            {/*<TableCell className={classes.total}>{finance.result.mode.bk && ccyFormat(summary_finance.rate + finance.vehicle.rate + finance.result.laborTimeRate)}</TableCell>*/}
            <TableCell className={classes.total}><strong>{ccyFormat( (finance.result.mode.bk ? (finance.result.provision + finance.result.deposit_euro) : model.price) + cash_items.reduce((acc, item) => acc + item.price, 0))}</strong></TableCell>
          </TableRow>
          {finance.result.mode.bk &&
          <TableRow>
            <TableCell className={classes.total} style={{textAlign: "right"}}>
              {getPaymentText(finance.result.ppy)}
            </TableCell>
            {/*<TableCell className={classes.total}>{finance.result.mode.bk && ccyFormat(summary_finance.rate + finance.vehicle.rate + finance.result.laborTimeRate)}</TableCell>*/}
            <TableCell className={classes.total}><strong>{ccyFormat(fin_subtotal)}</strong></TableCell>
          </TableRow>}
          <TableRow>
            {isWidthDown("sm", this.props.width) ?
              <TableCell colSpan={2} className={classes.total}>{this.props.submit && <Button color="primary" size="small" variant="contained" onClick={() => this.props.submit()}>JETZT Angebot anfragen</Button>}</TableCell>
              :
              <>
              <TableCell>
                {editable && !embedded && <Button size="small" variant="contained" onClick={() => history.replace("/config/" + model.id + "/" + color.color )}><Translate id="buttons.configmore"/></Button>}
              </TableCell>
              <TableCell className={classes.total}>{this.props.submit && <Button color="primary" size="small" variant="contained" onClick={() => this.props.submit()}>JETZT Angebot anfragen</Button>}</TableCell>
              </>
            }
          </TableRow>
          {/*<TableRow>
            <TableCell className={classes.total} style={{textAlign: "right"}}><Translate id="cart.total"/> bei Barzahlung</TableCell>
            <TableCell></TableCell>
            <TableCell className={classes.total}><strong>{ccyFormat(cart.reduce((acc, item) => acc + item.price, 0) + finance.vehicle.baseprice)}</strong></TableCell>
          </TableRow>*/}
        </TableBody>
      </Table>
    );
  }
}

CartDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
  color: PropTypes.object.isRequired,
  finance: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  editable: PropTypes.bool,
  embedded: PropTypes.bool,
  removeFromCart: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  finance: state.config.finance,
  model: state.config.model,
  series: state.config.series,
  color: state.config.color,
  cart: state.config.cart,
  categories: state.articles.categories
});

export default withStyles(styles, { withTheme: true })(withWidth()(connect(mapStateToProps, { removeFromCart })(CartDetail)));
