import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchArticlesByModelAndTopCat } from "../../../actions/articlesAction";

import ArticlesList from "../ArticlesList";
import Spinner from "../../Configurator/Spinner";

export const TOP_CATEGORY_KEY = "sk";

class PackagesList extends React.Component {
  constructor(props) {
    super(props);
    this.props.fetchArticles(this.props.model.id, this.props.category.id);
  }

  render() {
    console.log("PackagesList", this.props);
    if(this.props.articles === undefined || this.props.category.sub === undefined) {
      return <Spinner/>;
    } else {
      if(this.props.articles.length === 0) {
        return <div style={{textAlign:"center"}}>Keine Artikel für dieses Modell vorhanden.</div>;
      }
      else {
        console.log("PackagesList", this.props);
        return (
          <div>
            <ArticlesList categories={this.props.category.sub} articles={this.props.articles}
                          history={this.props.history} topcat={this.props.category}/>
          </div>
        );
      }
    }
  }
}

PackagesList.propTypes = {
  fetchArticles: PropTypes.func.isRequired,
  articles: PropTypes.array,
  categories: PropTypes.array,
  model: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const topcat = state.articles.categories.find(e => e.key === TOP_CATEGORY_KEY);
  return (
    { articles: state.articles.articles[topcat.id],
      model: state.config.model,
      category: topcat
    });
}

export default connect(
  mapStateToProps,
  { fetchArticles: fetchArticlesByModelAndTopCat }
)(PackagesList);
