export const getBackgroundWidth = width => {
  switch (width) {
    case "xs":
    case "sm":
      return 640;

    default:
      return 960;
  }
};

export const getBikeImageWidth = width => {
  switch (width) {
    case "xs":
    case "sm":
      return 520;

    default:
      return 640;
  }
};
