import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { withStyles, Hidden, withWidth } from "@material-ui/core";

import { syncdate } from "../../syncdate";
import FooterLinks from "./FooterLinks";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle";
import { gdpr_url, impress_url } from "../../store";

const links = [
  { link: "/", text: "Start" },
  { link: "https://www.ducati.at", text: "Über Ducati", blank:true},
  { link: impress_url, text: "Impressum", blank: true},
  { link: gdpr_url, text: "Datenschutz", blank: true}
];

function Footer(props) {
  const { classes } = props;

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classNames([classes.left, "noPrint"])}>
          <FooterLinks classes={classes} links={links} />
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()} <Hidden smDown>by{" "}</Hidden>Ducati Austria<Hidden smDown> - Version {syncdate}</Hidden>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};

export default withStyles(footerStyle)(withWidth()(Footer));
