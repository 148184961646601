import React from 'react';
import PropTypes from 'prop-types';
import { Image } from "../Image/Image";
import { ccyFormat, numFormat, percFormat } from "../../Utils";
import { Grid, TableCell, TableRow } from "@material-ui/core";
import PropertiesTable from "../Configurator/PropertiesTable";
import { DialogSimple } from "../Dialog/DialogSimple";

class ModelFinance extends React.Component {
  render() {
    const { finance, model, color, isFinanced, classes } = this.props;
    return (
      <React.Fragment>
      <TableRow>
        <TableCell>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={5} className={classes.productImage}>
            <Image src={color.images[0].filename} width={240} title={model.name} alt={model.name}/>
            </Grid>
            <Grid item xs={12} sm={8} md={7}>
              <h2 className={classes.modelTitle}>{model.name}  {/*<span className="modelcolor">{color.name}</span>*/}</h2>
              <h4>{model.data.displacement} ccm, {numFormat(model.data.power, 1)} kW</h4>
              <span className="articlenumber">{model.sku} - {color.name}<br/>
              {ccyFormat(model.baseprice)} inkl. {percFormat(model.nova, 0)} NOVA, inkl. {percFormat(model.vat, 0)} MwSt.</span>
              {/*<Button onClick={() => toggle("dialog-modeldetails")}>Info</Button>*/}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={classes.price}>{ccyFormat(isFinanced ? finance.rate : model.price)}</TableCell>
      </TableRow>
      <DialogSimple id="dialog-modeldetails" title={model.name}>
        <PropertiesTable model={model} color={color} final />
      </DialogSimple>
      </React.Fragment>
    );
  }
}

ModelFinance.propTypes = {
  classes: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  color: PropTypes.object.isRequired,
  includeImage: PropTypes.bool,
  finance: PropTypes.object,
  isFinanced: PropTypes.bool
};

export default ModelFinance;
