import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { connect } from "react-redux";
import { ccyFormat, numFormat } from "../../Utils";

import Spinner from "../Configurator/Spinner";
import { styles } from "./FinanceInfoDetail";
import { Translate, withLocalize } from "react-localize-redux";

import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions, Button, Typography, Hidden
} from "@material-ui/core";
import { ReactComponent as Logo } from "../../assets/img/porsche_insurance.svg";
import { ReactComponent as LogoWhite } from "../../assets/img/porsche_insurance_white.svg";
import { Transition } from "./InsuranceConfig";
import { BootstrapTooltip } from "../../views/Configurator";

const SayNo = props => {
  const [info, showInfo] = useState(false);
  return props.tooltip ?
    <BootstrapTooltip placement="left" title={
        <React.Fragment>
          <Typography color="inherit"><Translate id={props.title} /></Typography>
          <Translate id={props.message} />
        </React.Fragment>
      }>
      <div><Translate id={"insurance.no"}/> <i className="fas fa-info-circle grey" /></div>
    </BootstrapTooltip> :
    (<>
    <span onClick={() => showInfo(true)}>
      <Translate id={"insurance.no"}/> <i className="fas fa-info-circle grey" />
    </span>
      <Dialog
        open={info}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => showInfo(false)}
        maxWidth={"md"}
      >
        <DialogTitle id={"info-dialog-" + props.name}><Translate id={props.title} /></DialogTitle>
        <DialogContent>
          <DialogContentText id={"info-description-" + props.name}>
            <Translate id={props.message} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => showInfo(false)} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
    </>);
}

class InsuranceInfoDetail extends React.Component {
  render() {
    const { classes, model, config, cart } = this.props;
    //console.log("InsuranceInfoDetail props", this.props);

    if (model === undefined || config === undefined || cart === undefined) {
      return <Spinner />;
    }
    //const finance_summary = getSummaryFinance(finance.items);
    const insurance = config.insurance;

    const total = insurance.hpv + insurance.mvv + insurance.kbv + insurance.kav;

    const count = cart.length;
    const noKasko = insurance.kt === "KEINE KASKO";

    return (
        <Table className={classes.financeTable}>
          <TableHead>
            <Hidden smUp>
              <TableRow className={classes.porscheHeader}>
                <TableCell colSpan={2}><LogoWhite height={36} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} className={classes.priceColumn}><Translate id="insurance.periodText"/></TableCell>
              </TableRow>
            </Hidden>
            <Hidden smDown>
              <TableRow>
                <TableCell><Logo height={36} /></TableCell>
                <TableCell className={classes.priceColumn}><Translate id="insurance.periodText"/></TableCell>
              </TableRow>
            </Hidden>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.noLine}><Translate id="insurance.hpv"/></TableCell>
              <TableCell className={classNames(classes.noLine, classes.priceColumn)}>
                {config.insurance.hp ? ccyFormat(insurance.hpv) : <SayNo message={"idd.help.haftpflicht.false"} name={"hp"} title={"insurance.hpv"}/>}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Translate id="insurance.tax"/><br/><span className={classes.sub}> <Translate id="insurance.tax_detail" data={{power: numFormat(model.data.power, 1), ccm: model.data.displacement, co2: model.data.co2}}/></span>
              </TableCell>
              <TableCell className={classes.priceColumn}>{ccyFormat(insurance.mvv)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.summaryColumn}></TableCell>
              <TableCell className={classNames(classes.priceColumn, classes.summaryColumn)}>{ccyFormat(insurance.hpv + insurance.mvv)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.noLine}><Translate id={noKasko ? "insurance.kasko" : "kaskotype." + insurance.kt}/></TableCell>
              <TableCell className={classNames(classes.noLine, classes.priceColumn)}>{noKasko ? <SayNo message={"idd.help.vollkasko.false"} name={"kasko"} title={"insurance.kasko"}/> : ccyFormat(insurance.kbv)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><Translate id="insurance.kasko_zub"/><br/>{count > 0 && <span className={classes.sub}><Translate id="articles" data={{count}}/></span>}</TableCell>
              <TableCell className={classes.priceColumn}>{insurance.accessories ? ccyFormat(insurance.kav) : <SayNo message={"idd.help.zub.false"} name={"zub"} title={"insurance.kasko_zub"}/>}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.summaryColumn}><Translate id="insurance.total"/> {insurance.hinterlegung && <Translate id="insurance.kasko_hinterlegung"/>}</TableCell>
              <TableCell className={classNames(classes.priceColumn, classes.summaryColumn)}>{ccyFormat(total)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
    );
  }
}

InsuranceInfoDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  model: PropTypes.object,
  config: PropTypes.object,
  cart: PropTypes.array
};

const mapStateToProps = state => ({
  model: state.config.model,
  config: state.config,
  cart: state.config.cart
});

export default withLocalize(withStyles(styles/*, { withTheme: true }*/)(
    connect(
      mapStateToProps,
      {  }
    )(InsuranceInfoDetail))
);
