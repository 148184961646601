import React from "react";
import componentsStyle from "assets/jss/material-kit-react/views/components";
import Header from "../../components/Header/Header";

import Footer from "../../components/Footer/Footer";
import HeaderLinks from "../../components/Header/HeaderLinks";
import FooterContainer from "../../components/Footer/FooterContainer";
import { ReactComponent as Logo } from "assets/img/Ducati_Shield_Rot.svg";
import { withStyles, withWidth } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { Image } from "../../components/Image/Image";

const contentStyle = theme => ({
  componentsStyle,
  bg: {
    marginTop: -(theme.appBar.height + 10),// + theme.appBar.paddingTop),
    backgroundSize: "cover",
    backgroundPosition: "top center"
  }
});

class StandardLayout extends React.Component {
  render() {
    const { classes, dealer, ...rest } = this.props;
    return (
      <div>
        <Header
          brand={
            <Logo height={64} id="logo" title={<Translate id="logo_title" />} onClick={() => this.props.history.replace("/")} />
          }
          leftLinks={dealer &&
          <a href={"https://" + dealer.B14_Internet} target={"_blank"} rel="noopener noreferrer">
            <Image src={dealer.H03_Filename_Logo_4C} width={160} fixedWidth={160} alt={dealer.name} />
          </a>}
          rightLinks={<HeaderLinks history={this.props.history} />}
          //fixed
          color="transparent"
          changeColorOnScroll={{
            height: 80,
            //color: "white"
          }}
          {...rest}
        />
        <div className={classes.bg}>
          {this.props.children}
        </div>

        <FooterContainer>
          {/*!this.props.location.pathname.startsWith("/offer/") &&
            <Stepper history={this.props.history}/>
          */}
          <Footer />
        </FooterContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dealer: state.config.dealer
});

export default connect(mapStateToProps, {})(withWidth()(withStyles(contentStyle, { withTheme: true })(StandardLayout)));
