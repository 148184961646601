import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";

import { connect } from "react-redux";
import { DialogTitle, DialogActions, styles as dialogStyles } from "../Dialog/Dialog";

import FinanceConfig from "./FinanceConfig";
import { updateFinancing } from "../../actions/configAction";
import { toggleFinancingView } from "../../actions/globalAction";
import { ReactComponent as Logo } from "assets/img/porsche_white.svg";

import { Dialog, DialogContent, withStyles, Button } from '@material-ui/core';
import { DialogSimple, toggle } from "../Dialog/DialogSimple";
import { FinanceCredits, FinanceIntroduction } from "./FinanceCredits";

class DialogFinance extends React.Component {
  handleAccept = () => {
    this.props.updateFinancing(this.props.config);
    this.props.toggleFinancingView();
  };

  render() {
    const { mode, open, modified, toggleFinancingView, finance } = this.props;
    const { buttonPrimary, buttonAccept, ...classes } = this.props.classes;
    return (
      <Dialog onClose={toggleFinancingView} aria-labelledby="dialog-financing" open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle id="dialog-financing" onClose={toggleFinancingView}>
          <Logo height={28} style={{ margin: "-5px 34px" }} /><Translate id="finance.title" />
        </DialogTitle>
        <DialogContent>
          <FinanceConfig open={true} close={toggleFinancingView} classes={classes} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggle("dialog-conditions")}>Info</Button>
          <Button
            onClick={this.handleAccept}
            className={mode === 0 ? buttonPrimary : buttonAccept}
          >
            {mode === 0 ?
              <Translate id="buttons.finance.accept.cash" /> :
              modified ?
                <Translate id="buttons.finance.accept.update" />
                :
                <Translate id="buttons.finance.accept.finance" />
            }
          </Button>
        </DialogActions>
        <DialogSimple id="dialog-conditions" title={"Finanzierungsbedingungen"}>
          <FinanceIntroduction/>
          <FinanceCredits result={finance.result} />
        </DialogSimple>
      </Dialog>
    );
  }
}

DialogFinance.propTypes = {
  config: PropTypes.object,
  open: PropTypes.bool.isRequired,
  mode: PropTypes.number.isRequired,
  modified: PropTypes.bool.isRequired,
  finance: PropTypes.object,
  classes: PropTypes.shape({
    buttonPrimary: PropTypes.string,
    buttonAccept: PropTypes.string
  }),

  toggleFinancingView: PropTypes.func.isRequired,
  updateFinancing: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  config: state.config.finance_config,
  open: state.global.financingView,
  mode: state.global.financingMode,
  finance: state.config.finance,
  modified: state.config.selected_finance !== undefined && state.config.selected_finance.modified !== undefined && state.config.selected_finance.modified
});

export default connect(mapStateToProps, { toggleFinancingView, updateFinancing })(withStyles(dialogStyles)(DialogFinance));
