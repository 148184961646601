import {
  ERROR,
  ERROR_CLEAR, FATAL_ERROR
} from "../actions/types";

const initialState = {
  id: undefined
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ERROR:
      console.log("ERROR dispatched", action);
      return {
        ...state,
        id: action.id,
      };

    case FATAL_ERROR:
      console.log("FATAL_ERROR dispatched", action);
      return {
        ...state,
        id: action.id,
        fatal: true
      };

    case ERROR_CLEAR:
      return {
        ...state,
        id: undefined
      };

    default:
      return state;
  }
}
