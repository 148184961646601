import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DialogActions, DialogContent, styles } from "../Dialog/Dialog";

import CartIcon from "./CartIcon";
import CloseIcon from "@material-ui/icons/Close";
import CartDetail from "./CartDetail";
import Spinner from "../Configurator/Spinner";

import { Translate, withLocalize } from "react-localize-redux";

import {
  withStyles,
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  Typography,
  IconButton, isWidthDown
} from "@material-ui/core";

export const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing( 2),
    backgroundColor: theme.palette.primary.lightgray
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1)
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class DialogCart extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    console.log("open cart");
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, editable, width } = this.props;

    if(this.props.finance === undefined)
      return <Spinner />;

    return (
      <React.Fragment>
        <CartIcon classes={classes} onClick={this.handleClickOpen} />
        <Dialog
          fullScreen={isWidthDown("sm", width)}
          onClose={this.handleClose}
          aria-labelledby="dialog-cart"
          open={this.state.open}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle id="dialog-cart" onClose={this.handleClose}>
            <Translate id="cart.title"/>
          </DialogTitle>
          <DialogContent>
            <CartDetail editable={editable} embedded={true}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary"><Translate id="cart.close"/></Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

DialogCart.propTypes = {
  classes: PropTypes.object.isRequired,
  config: PropTypes.object,
  finance: PropTypes.object.isRequired,
  editable: PropTypes.bool
};

const mapStateToProps = state => ({
  config: state.config.config,
  finance: state.config,
  model: state.config.model,
  series: state.config.series,
  color: state.config.color,
  cart: state.config.cart,
  views: state.bikes.views
});

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    null
  )(withLocalize(DialogCart))
);
