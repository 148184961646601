import React from "react";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage";

import { Translate } from "react-localize-redux";
import { database_request_url } from "../../store";
import { getImageUrl } from "components/Image/Image";
import { connect } from "react-redux";
import { fetchOffer } from "../../actions/configAction";
import { clearError } from "../../actions/globalAction";
import PropTypes from "prop-types";

import { withStyles, InputAdornment, Icon, TextField } from "@material-ui/core";
import { fetchCategories } from "../../actions/articlesAction";

class OfferForm extends React.Component {
  state = {
    bgimage: undefined,
    data: {
      code: undefined,
      email: undefined
    },
    valid: {
      code: true,
      email: true,
    }
  };
  constructor(props) {
    super(props);
    fetch(database_request_url + "/randombackground")
      .then(response => response.text())
      .then(data => {
        this.setState({ bgimage: data });
      });
    this.props.fetchCategories();
  };

  validateField(fieldName, value) {
    switch(fieldName) {
      case "code":
        return value !== undefined;
      case "email":
        return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
      default:
        return true;
    }
  }

  handleChange = event => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.id]: event.target.value
      },
      valid: {
        ...this.state.valid,
        [event.target.id]: this.validateField(event.target.id, event.target.value)
      }
    });
  };

  handleLoadOffer = () => {
    this.props.clearError();
    console.log("event", this.state);
    if(this.state.data.code !== undefined /*&& this.state.data.email !== undefined */ && this.state.valid.code && this.state.valid.email)
      this.props.fetchOffer(this.state.data.code);
    else
      console.log("do not load, data insufficient");
  };

  showError() {
    const { error } = this.props;
    let message = undefined;
    console.log("showError", error);
    if(error) {
      message = <p style={{color: "red"}}><Translate id={"errors." + this.props.error}/></p>;
    }
    return message;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.offer !== undefined) {
      this.props.history.replace("/offer/" + this.props.offer.id);
    }
  }

  render() {
    const { classes } = this.props;
      const bgimage = this.state.bgimage !== undefined ?
      getImageUrl(this.state.bgimage, 960) :
      ""; // TODO add default image

    return (
      <div>
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: 'url(' + bgimage + ')',
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <form className={classes.form}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h3><Translate id="offer.headers.code"/></h3>
                    </CardHeader>
                    <CardBody>
                      <p className={classes.divider}><Translate id="offer.form.info"/></p>
                      {this.showError()}
                      <TextField
                        label={<Translate id="offer.input.code"/>}
                        onBlur={this.handleChange}
                        id="code"
                        //placeholder="Placeholder"
                        margin="normal"
                        error={!this.state.valid.code}
                        //value={this.state.data.code}
                        InputProps={{
                          type: "text",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          )
                        }}
                      />
                      <TextField
                        label={<Translate id="offer.input.email"/>}
                        onBlur={this.handleChange}
                        id="email"
                        //placeholder="Placeholder"
                        margin="normal"
                        error={!this.state.valid.email}
                        //value={this.state.data.email}
                        InputProps={{
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button color="primary" size="lg" onClick={this.handleLoadOffer.bind(this)}>
                        <Translate id="offer.form.button"/>
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </div>
    );
  }
}


OfferForm.propTypes = {
  fetchOffer: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  error: state.error.id,
  offer: state.config.offer
});


export default withStyles(loginPageStyle)(
  connect(
    mapStateToProps,
    { fetchOffer, clearError, fetchCategories }
  )(OfferForm));
