import {
  UPDATE_FINANCING,
  UPDATE_INSURANCE,
  ADD_ARTICLE,
  FETCH_CART_FINANCE,
  FETCH_DEALERS,
  FETCH_OFFER,
  REMOVE_ARTICLE,
  SAVE_OFFER,
  SELECT_SIZE,
  SET_COLOR,
  SET_DEALER,
  SET_MODE,
  SET_MODEL,
  SET_SERIES,
  SET_DELIVERY,
  MODIFY_FINANCING,
  TOGGLE_FINANCING_VIEW, TOGGLE_INSURANCE_VIEW, MODIFY_INSURANCE, SET_CUSTOMER,
  SET_STATUS, FINISH_IDD,
  SET_NEWSLETTER
} from "../actions/types";

const initialState = {
  series: undefined,
  model: undefined,
  color: undefined,
  sizes: [],
  cart: [],
  finance: undefined,
  insurance: undefined,
  config: undefined,
  finance_config: undefined,
  selected_finance: undefined,
  insurance_config: undefined,
  selected_insurance: undefined,
  offer: undefined,
  dealers: [],
  dealer: undefined,
  dealerPredefined: false,
  customer: undefined,
  delivery: undefined,
  newsletter: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    /** Series Selection **/
    case SET_SERIES:
      return {
        ...state,
        series: action.payload,
        model: undefined // and reset the selected model (on series change)
      };

    /** Model Selection **/
    case SET_MODEL:
      return {
        ...state,
        model: action.model,
        series: action.model.series
      };
    case SET_COLOR:
      return {
        ...state,
        color: action.color
      };
    /** Cart Actions **/
    case ADD_ARTICLE:
      return {
        ...state,
        cart: [...state.cart, action.article]
      };
    case REMOVE_ARTICLE:
      let removeSizes = {...state.sizes};
      delete removeSizes[action.article.id];
      return {
        ...state,
        cart: state.cart.filter(element => element !== action.article),
        sizes: removeSizes
      };
    case SET_DELIVERY:
      return {
        ...state,
        offer: {
          ...state.offer,
          delivery: action.delivery
        }
      };

    /** Finance Actions **/
    case FETCH_CART_FINANCE:
      let fin_config = {
        deposit: action.payload.fin.deposit_perc,
        remainder: action.payload.fin.remainder_perc,
        type: action.payload.fin.name,
        payments: action.payload.fin.n_payments,
        mode: action.payload.fin.mode
      };
      let ins_config = {
        kasko: action.payload.ins.kt,
        hp: action.payload.ins.hp,
        accessories: action.payload.ins.dt
      };
      return {
        ...state,
        finance: {
          vehicle: action.payload.vehicle,
          items: action.payload.items,
          result: action.payload.fin,
        },
        finance_config: {
          ...state.finance_config,
          ...fin_config
        },
        insurance: action.payload.ins,
        insurance_config: {
          ...state.insurance_config,
          ...ins_config
        },
        // Default values are automatically selected, the first time:
        selected_finance: state.selected_finance === undefined ? fin_config : state.selected_finance,
        selected_insurance: state.selected_insurance === undefined ? ins_config : state.selected_insurance
      };

    case MODIFY_FINANCING:
     return {
        ...state,
        finance_config: {
            ...state.finance_config,
            [action.property]: action.value
        }
      };
    case UPDATE_FINANCING:
      return {
        ...state,
        selected_finance: { ...state.finance_config, modified: true }
      };
    case TOGGLE_FINANCING_VIEW:
      return {
        ...state,
        finance_config: state.selected_finance
      };

    case MODIFY_INSURANCE:
      return {
        ...state,
        insurance_config: {
          ...state.insurance_config,
          [action.property]: action.value
        }
      };
    case TOGGLE_INSURANCE_VIEW:
      return {
        ...state,
        insurance_config: state.selected_insurance,
      };
    case UPDATE_INSURANCE:
      return {
        ...state,
        selected_insurance: {
          ...state.insurance_config,
          modified: true,
          idd: action.idd
        }
      };

    case SET_MODE:
      return {
        ...state,
        finance_config: {
          ...state.finance_config,
          mode: {
            ...state.finance_config.mode,
            [action.key]: action.value
          }
        },
        // also update selected finance!
        selected_finance: {
          ...state.selected_finance,
          mode: {
            ...state.selected_finance.mode,
            [action.key]: action.value
          }
        }
      };
    case SELECT_SIZE:
      let newSizes = {...state.sizes};
      newSizes[action.id] = action.size;
      return {
        ...state,
        sizes: newSizes
      };

    /** Final Steps **/
    case SAVE_OFFER:
      return {
        ...state,
        offer: {
          id: action.payload.id,
          status: action.payload.status,
          customer: action.payload.customer,
          number: action.payload.number,
          delivery: action.payload.delivery
        }
      };
    case FETCH_OFFER:
      return {
        ...state,
        offer: action.offer,
        cart: action.articles,
        finance_config: action.finance_config,
        selected_finance: {
          ...action.finance_config,
          modified: true
        },
        insurance_config: action.insurance_config,
        selected_insurance: {
          ...action.insurance_config,
          modified: true
        }
      };
    case FETCH_DEALERS:
      return {
        ...state,
        dealers: action.data
      };
    case SET_DEALER:
      return {
        ...state,
        dealer: action.dealer,
        dealerPredefined: action.predefined === true
      };
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.customer
      };

    case SET_STATUS:
      return {
        ...state,
        status: action.status
      };

    case FINISH_IDD:
      return {
        ...state,
        iddView: !state.iddView,
        idd: action.payload
      };

    case SET_NEWSLETTER:
      return {
        ...state,
        newsletter: action.value
      };

    default:
      return state;
  }
};
